import { Stack, Box, Typography } from '@mui/material'
import styled from 'styled-components'
import { FieldForm } from '../field-form'

export const LayoutDrawerWrapper = styled(Stack)`
  width: 400px;
  height: 100%;
`
export const LayoutDrawerTopBar = styled(Box)`
  padding: 20px 20px 15px;
  text-align: center;
`
export const LayoutDrawerTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.primary.mainBlue};
`
export const DrawerFormWrapper = styled(Stack)`
  overflow-x: hidden;
  flex-grow: 1;
  padding: 20px;
  display: grid;
  grid-template-rows: calc(100% - 80px) 80px;

  & > .MuiStack-root {
    overflow-y: auto;
    margin-right: -8px;
    padding-right: 8px;
  }
  & .MuiButton-root.Mui-disabled {
    background: ${(props) => props.theme.primary.disabled};
    color: white;
  }
  & .MuiFormHelperText-root {
    position: absolute;
    right: 0;
    bottom: -14px;
    width: 100%;
    margin: 0;
    font-size: 12px;
    line-height: 1;
    color: ${(props) => props.theme.secondary.error};
  }
  & .MuiAutocomplete-root {
    & .MuiOutlinedInput-root {
      padding-top: 13px;
      padding-bottom: 13px;

      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(92, 110, 140, 0.3);
      }
      &.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.secondary.error};
      }
    }
    & .MuiInputLabel-root {
      transform: translate(18px, 13px) scale(1);
    }
  }
`
export const DrawerAddFieldWrapper = styled(Stack)`
  display: grid;
  grid-template-columns: 1fr 180px;
  gap: 20px;
  align-items: flex-start;

  &:not(.fullwidth) .MuiSelect-select {
    height: 30px;
    padding: 5px 8px;
    box-sizing: border-box;
  }
  &:not(.fullwidth) .select-field {
    & .MuiInputLabel-root {
      transform: translate(8px, 5px) scale(1);
    }
  }

  &.fullwidth {
    grid-template-columns: 1fr;
    gap: 6px;

    & .MuiSelect-select {
      height: 48px;
      padding: 16px;
    }

    & .select-field .MuiInputLabel-root {
      transform: translate(16px, 15px) scale(1);
    }
  }
`
export const DrawerAddFieldLabel = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-top: 5px;

  & .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: ${(props) => props.theme.secondary.text};
  }
`
export const DrawerAddStyledField = styled(FieldForm)`
  & .MuiInputBase-root {
    padding: 0;
    border-radius: 6px;
    background-color: ${(props) => props.theme.primary.blueShades};
    border: 1px solid rgba(${(props) => props.theme.primary.textDark}, 0.12);

    border: ${(props) =>
      props.error && `1px solid ${props.theme.secondary.error}`};
  }

  & .MuiInputBase-input {
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1;
    text-overflow: ellipsis;
    border-radius: 6px;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: ${(props) => props.theme.primary.placeholder};
      opacity: 1;
    }
  }
  & .MuiInputBase-input:not(.MuiInputBase-multiline) {
    height: 48px;
    padding: 16px;
    box-sizing: border-box;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & .MuiFormHelperText-root {
    text-align: right;
  }
`
