import {
  StyledTab,
  StyledHeader,
  StyledIcon,
  StyledTitle,
  StyledRow,
  StyledSubTitle,
  StyledDescription,
} from "./integrations-tab.styles";
import { ReactComponent as TrustDocIcon } from "../../../../components/icons/imported-icons/trust-doc.svg";
import {
  useGetTrustDocConfigQuery,
  useUpdateTrustDocConfigMutation,
  useResetTrustDocConfigMutation,
} from "../../../../api/companies";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { Progress } from "../../../../components/progress";
import { useTranslation } from "react-i18next";
import { TrustDocStatus } from "./components/trust-doc-status";
import { useForm } from "../../../../hooks/use-form";
import { validationTrustDoc } from "./validation";
import { FormikProvider } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TrustDocForm } from "./components/trust-doc-form";
import { FormikValues } from "formik/dist/types";
import { TrustDocStatus as TrustDocStatusType } from "../../../../api/companies/types";

export const IntegrationsTab = () => {
  const { currentCompanyId } = useAppContext();
  const { t } = useTranslation("administration");

  const [status, setStatus] = useState<TrustDocStatusType | null>(null);

  const { data, isLoading, isFetching } = useGetTrustDocConfigQuery(
    { companyID: currentCompanyId },
    { skip: !currentCompanyId }
  );
  const [updateTrustDocConfig, updateTrustDocConfigResult] =
    useUpdateTrustDocConfigMutation();
  const [resetTrustDocConfig, resetTrustDocConfigResult] =
    useResetTrustDocConfigMutation();

  const isMutationLoading =
    updateTrustDocConfigResult.isLoading || resetTrustDocConfigResult.isLoading;

  useEffect(() => {
    if (data?.status) setStatus(data.status);
  }, [data]);

  const initialValues: FormikValues = {
    login: data?.account?.login || "",
    password: "",
  };

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      if (!currentCompanyId) return;

      updateTrustDocConfig({
        companyID: currentCompanyId,
        body: {
          account: {
            ...(values.login && { login: values.login }),
            ...(values.password && { password: values.password }),
          },
        },
      });
    },
    [currentCompanyId]
  );

  const { formik } = useForm({
    validationSchema: validationTrustDoc,
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
  });
  const { values, dirty } = formik;

  const isReady = useMemo(() => {
    return Object.values(values).some((value) => value) && dirty;
  }, [values, status, dirty]);

  const handleReset = useCallback(() => {
    setStatus(null);
    resetTrustDocConfig({ companyID: currentCompanyId });
  }, [currentCompanyId]);

  const mainContent = (
    <>
      <StyledRow $minHeight={100}>
        <StyledSubTitle>{t("trustDoc.connectionStatus")}</StyledSubTitle>
        {isLoading || isFetching ? (
          <Progress />
        ) : (
          <TrustDocStatus
            status={status}
            account={data?.account}
            onButtonReconnectClick={handleReset}
          />
        )}
      </StyledRow>

      <StyledRow $minHeight={114}>
        <StyledSubTitle>
          {t("trustDoc.accountData")}
          <StyledDescription>
            {t("trustDoc.provideCredentials")}
          </StyledDescription>
        </StyledSubTitle>

        <FormikProvider value={formik}>
          <TrustDocForm isReady={isReady} isLoading={isMutationLoading} />
        </FormikProvider>
      </StyledRow>
    </>
  );

  return (
    <StyledTab>
      <StyledHeader>
        <StyledIcon>
          <TrustDocIcon />
        </StyledIcon>

        <StyledTitle>TrustDoc</StyledTitle>
      </StyledHeader>

      {isLoading ? <Progress /> : mainContent}
    </StyledTab>
  );
};
