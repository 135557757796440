import React, { FC } from "react";
import { ListItem, Stack, Typography } from "@mui/material";
import { ModuleStatuses, ModuleType } from "../../api/companies";
import { Icon } from "../icons";
import {
  BACKGROUND_DISABLED,
  ICONS,
  ModuleStatusesColors,
} from "../../utils/constants";
import { StatusIndicator } from "../status-indicator";

type ModuleCardProps = {
  name: string;
  background?: string;
  title?: string;
  moduleType: ModuleType;
  status?: ModuleStatuses;
};

export const ModuleCard: FC<ModuleCardProps> = ({
  name,
  background,
  moduleType,
  title,
  status,
}) => {
  return (
    <ListItem
      sx={{
        padding: 0,
        borderRadius: 2,
        background: background,
        position: "relative",
      }}
    >
      <Stack
        position="absolute"
        left={0}
        right={0}
        top={-9}
        alignItems="center"
      >
        <StatusIndicator
          status={status}
          backgroundColor={
            ModuleStatusesColors[status as keyof typeof ModuleStatusesColors] ||
            BACKGROUND_DISABLED
          }
        />
      </Stack>
      <Stack
        textAlign="center"
        alignItems="center"
        justifyContent="space-around"
        width="160px"
        height="220px"
        p="10px"
      >
        <Icon name={moduleType as keyof typeof ICONS} />
        <Stack flex={1}>
          <Typography
            sx={{
              fontWeight: 800,
            }}
            variant="body2"
            mb="6px"
            mt="6px"
          >
            {name}
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
            }}
            variant="body2"
          >
            {title}
          </Typography>
        </Stack>
      </Stack>
    </ListItem>
  );
};
