import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
  StyledCell,
  StyledCellText,
  StyledErrorMsg
} from "./title-cell.styles";
import { Box, Tooltip, Typography } from "@mui/material";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type TitleCellProps = {
  title: string,
  isActual: boolean
}

export const TitleCell: FC<ICellRendererParams<unknown, TitleCellProps>> = ({
  value,
}) => {
  const { t } = useTranslation("xml", {
    keyPrefix: 'template-table',
  });
  const [searchParams] = useSearchParams();
  const showNotActual = searchParams.get("showNotActual") === 'true';

  return (
    <StyledCell>
      <Tooltip title={value?.title && value.title.length > 60 ? <Box sx={{overflowY: 'auto', height: '100%'}}>{value.title}</Box> : undefined} arrow>
        <StyledCellText>{value?.title || ''}</StyledCellText>
      </Tooltip>
      {value?.isActual === false && showNotActual && <StyledErrorMsg 
        direction="row" 
        justifyContent="center" 
        alignItems="center"
        columnGap={0.5}>
        <DoDisturbIcon color="error" sx={{fontSize: 16}}/>
        <Typography variant="body1" color="error">{t('not-actual')}</Typography>
      </StyledErrorMsg>}
    </StyledCell>
  );
};
