import styled from "styled-components";

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;
