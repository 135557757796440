import { Button, FormHelperText, Stack } from '@mui/material'
import styled from 'styled-components'

export const AttachFileBtn = styled(Button)`
  display: flex;
  justify-content: center;
  gap: 8px;
  height: 36px;
  color: ${props => props.theme.primary.mainBlue};
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  background-color: ${props => props.theme.primary.blueShades};

  & .MuiCircularProgress-root {
    width: 20px!important;
    height: 20px!important;
  }
`
export const AttachedFile = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  padding: 4px 7px 4px 2px;

  & .MuiTypography-root {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    overflow-wrap: anywhere;

    svg {
      margin-top: 2px;
      font-size: 16px;
      color: ${props => props.theme.secondary.textAdditional};
    }
    &.error {
      color: ${props => props.theme.secondary.error};

      svg {
        color: ${props => props.theme.secondary.error};
      }
    }
  }
  & .MuiButtonBase-root {
    width: 20px;
    min-width: 0;
    height: 20px;

    svg {
      font-size: 16px;
      color: ${props => props.theme.secondary.textAdditional};
    }
  }
`
export const FileError = styled(FormHelperText)`
  position: static !important;
  margin: -4px 0 !important;
  font-weight: 400;
`