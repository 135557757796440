import { DetailsCellProps } from "./details-cell.types";
import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { StyledCell, StyledRow } from "./details-cell.styles";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

export const DetailsCell: FC<ICellRendererParams<DetailsCellProps>> = ({
  value,
}) => {
  const login = value?.login;
  const email = value?.email;
  const phone = value?.phone;

  const formattedPhone = phone
    ?.slice(2)
    ?.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 ($1) $2-$3-$4");

  return (
    <StyledCell>
      <StyledRow>
        <PersonIcon className="icon" />
        {login || "–"}
      </StyledRow>

      <StyledRow>
        <EmailIcon className="icon" />
        {email || "–"}
      </StyledRow>

      <StyledRow>
        <PhoneAndroidIcon className="icon" />
        {formattedPhone || "—"}
      </StyledRow>
    </StyledCell>
  );
};
