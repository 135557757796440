import { HeaderProps } from "./header.types";
import React, { FC, useMemo } from "react";
import { Pagination } from "../../../../components/pagination";
import { StyledHeader } from "./header.styles";
import { FilterSelectMultiple } from "../../../../components/filter-select-multiple";
import { useTranslation } from "react-i18next";
import FilterAltSharpIcon from "@mui/icons-material/FilterAltSharp";

export const Header: FC<HeaderProps> = ({
  page,
  pages,
  limit,
  isLoading,
  companies,
  filtersState,
  onFiltersChange,
  onChangePage,
  onChangeLimit,
}) => {
  const { t } = useTranslation("administration");

  const companiesOptions = useMemo(
    () =>
      companies.map((company) => ({
        value: company.id,
        title: `${company.name} - ${company.filteredUserCount}`,
      })),
    [companies]
  );

  return (
    <StyledHeader>
      {!isLoading && (
        <>
          <FilterSelectMultiple
            items={companiesOptions}
            value={filtersState.companies}
            onChange={(value) => onFiltersChange(value, "companies")}
            defaultValue={t("allCompanies")}
            startIcon={<FilterAltSharpIcon />}
          />

          <Pagination
            page={page}
            limit={limit}
            count={pages}
            onChangePage={onChangePage}
            onChangeLimit={onChangeLimit}
          />
        </>
      )}
    </StyledHeader>
  );
};
