import { FC, useCallback, useMemo, useState } from "react";
import { StyledPage } from "./administration.styles";
import { Tabs } from "../../components/tabs";
import { Tab, FiltersState } from "./administration.types";
import { UsersTable } from "./components/users-table";
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from "../../utils/constants";
import { useSearchParams } from "react-router-dom";
import { Header } from "./components/header";
import {
  useGetSuperAdminUsersQuery,
  useGetAdminUsersFiltersQuery,
} from "../../api/superAdmin";
import { Progress } from "../../components/progress";
import { encode } from "js-base64";
import { XMLFolders } from "./components/xml-folders";

export const Administration: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const limitParam = searchParams.get("limit");
  const pageParam = searchParams.get("page");
  const search = searchParams.get("search") || "";
  const tab = searchParams.get("tab") || "";
  const limitValue = useMemo(
    () => Number(limitParam) || NUMBER_OF_ROWS_PER_USERS_PAGE[0].value,
    [limitParam]
  );
  const page = useMemo(() => Number(pageParam) || 1, [pageParam]);
  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page]);

  const [currentTab, setCurrentTab] = useState<Tab>(tab as Tab || "users");
  const [filtersState, setFiltersState] = useState<FiltersState>(
    new FiltersState(searchParams)
  );

  const {
    data: users,
    isLoading,
    isFetching,
  } = useGetSuperAdminUsersQuery({
    limit: limitValue,
    offset: offset,
    search,
    ...(filtersState.companies.length && {
      companyID: filtersState.companies,
    }),
  });

  const companies = useGetAdminUsersFiltersQuery({});

  const pages = useMemo(() => {
    if (!users?.total) return 1;

    return Math.ceil(users.total / Number(limitValue)) || 1;
  }, [users, limitValue]);

  const handleChangePage = useCallback(
    (page: number) => {
      searchParams.set("page", page.toString());
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const handleChangeLimit = useCallback(
    (limit) => {
      searchParams.set("limit", String(limit));
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const handleFiltersChange = useCallback(
    (value: string[], key: keyof FiltersState) => {
      setFiltersState((prev) => ({ ...prev, [key]: value }));
      searchParams.set(key, encode(value.join(",")));
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  return (
    <StyledPage>
      <Tabs
        values={[
          { value: "users", label: "Пользователи" },
          { value: "xml", label: "XML-конструктор"},
        ]}
        currentValue={currentTab}
        onChange={(value) => {
          searchParams.set('tab', value);
          setSearchParams(searchParams);
          setCurrentTab(value as Tab);
        }}
      />
      {currentTab === 'users' && <Header
        page={page}
        pages={pages}
        limit={limitValue}
        companies={companies?.data?.companies || []}
        filtersState={filtersState}
        onFiltersChange={handleFiltersChange}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        isLoading={isLoading || isFetching}
      />}
      {isLoading || isFetching ? (
        <Progress />
      ) : currentTab === 'users' ? (
        <UsersTable users={users?.data || []} /> 
      ) : (
        <XMLFolders/>
      )}
    </StyledPage>
  );
};
