import { XmlTemplateListItem } from "../../../../api-xml-constructor/templates";
import { TableTemplate } from "./templates-table.types";
import { isDate, format as FnsFormat } from "date-fns";

// порядок объектов с разными typeId остаётся неизменным, но объекты с одинаковым typeId отсортированы по version
export function groupTableDataByVersion(data: XmlTemplateListItem[]): TableTemplate[] {
  const grouped = data.reduce<Record<string, XmlTemplateListItem[]>>((acc, item) => {
    const key = item.template.typeId;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  // Сортировка каждой группы по version и объединение обратно в один массив
  const sorted = Object.values(grouped).reduce<TableTemplate[]>((acc, group) => {
    const sortedGroup = group.sort((a, b) => a.template.version - b.template.version);
    const modifiedGroup = sortedGroup.map((item, index) => ({
      ...item,
      hasGroup: group.length > 1 ? (index === 0 ? true : false) : undefined// Устанавливаем hasGroup в true только для первого элемента в группе, если элементов больше одного
    }));
    return acc.concat(modifiedGroup);
  }, []);

  return sorted;
}

// принимает Date с коротким или полным годом
// возвращает Date с полным годом
export function formatDateInInput(_: any, originalValue: any) {
  if (originalValue === null) return null;

  // Check if originalValue is a Date object
  if (!(originalValue instanceof Date)) return undefined;

  try {
    const firstFormattedDate = FnsFormat(originalValue, 'MM/dd/yyyy')
    const receivedYear = firstFormattedDate.split('/')[2]
    const finalYear =
      receivedYear.length === 2 ? `20${receivedYear}` : receivedYear
    const secondFormattedDate = [
      ...firstFormattedDate.split('/').slice(0, 2),
      finalYear,
    ]

    const formattedDate = new Date(secondFormattedDate.join('/'))
    const parsedDate = isDate(formattedDate) ? formattedDate : null

    return parsedDate
    
  } catch {
    return null
  }
}