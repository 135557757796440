import { FC, useCallback } from "react";
import { DatePickerProps } from "./date-picker.types";
import { DatePicker as MuiDatepicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useField } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import { ReactComponent as CalendarIcon } from "../../../../../../components/icons/imported-icons/calendar.svg";
import { StyledWrapper, StyledTextField } from "./date-picker.styles";
import dayjs, { Dayjs } from "dayjs";
import { ruRU } from '@mui/x-date-pickers/locales';
import { TextFieldProps } from "@mui/material";
import { DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers";

const ruLocale = ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

const TextField = (fieldProps: React.PropsWithChildren<TextFieldProps>) => (
  <StyledTextField
    {...fieldProps}
  />
);

export const DatePicker: FC<DatePickerProps> = ({
  name,
  placeholder,
  icon,
  valueIntlOptions,
  hasButtonClear,
  popperSx,
  readOnly = true,
  ...props
}) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField({
    name,
  });

  const handleChange = useCallback((value: Dayjs | null) => {
    if (!value) return setValue(null, true);

    setValue(dayjs(value).toDate());
    setTouched(true, true);
  }, []);

  return (
    <StyledWrapper className="date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru" localeText={ruLocale}>
        <MuiDatepicker
          name={name}
          value={dayjs(value)}
          onChange={handleChange}
          slotProps={{
            popper: { placement: "bottom-end", sx: popperSx },
            field: { clearable: !!(hasButtonClear && value)},
            textField: {
              placeholder: placeholder,
              error: !!error && touched,
              helperText: error,
              onBlur: (e) => {
                setTouched(true, true)
                if (value === null && e.target.value !== 'ДД.ММ.ГГГГ') {
                  setValue(dayjs(e.target.value).toDate(), true);
                }
              }
            }
          }}
          format="DD.MM.YYYY"
          slots={{
            textField: TextField,
            openPickerIcon: icon || CalendarIcon,
          }}
          {...props}
        />
      </LocalizationProvider>
    </StyledWrapper>
  );
};
