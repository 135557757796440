import { Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

export const StyledCard = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background: ${({ theme }) => theme.primary.blueShades};
  color: inherit;
  text-decoration: none
`;
export const StyledTitle = styled(Typography)`
  height: 46px;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.primary.mainBlue};
  overflow-wrap: anywhere;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledTooltip = styled(Tooltip)`
  background-color: ${({ theme }) => theme.secondary.textAdditional};
    & .MuiTooltip-tooltip { 
      background-color: ${({ theme }) => theme.secondary.textAdditional};
    }
`;