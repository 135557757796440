import { TextField } from "@mui/material";
import { styled } from "styled-components";

export const XmlFormTextField = styled(TextField)`
  & .MuiInputBase-root {
    padding: 0;
    border-radius: 6px;
    background-color: ${(props) => props.theme.primary.blueShades};
    border: 1px solid ${(props) => props.theme.primary.textDark}1F;
    border: ${(props) =>
      props.error && `1px solid ${props.theme.secondary.error}`};
  }

  & .MuiInputBase-input {
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1;
    text-overflow: ellipsis;
    border-radius: 6px;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: ${(props) => props.theme.primary.placeholder};
      opacity: 1;
    }
  }
  & .MuiInputBase-input:not(.MuiInputBase-multiline) {
    height: 48px;
    padding: 16px;
    box-sizing: border-box;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & .MuiFormHelperText-root {
    font-weight: 400;
  }
`