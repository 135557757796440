import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { FC, ReactElement } from 'react'
import { ReactComponent as EmptyIcon } from "../icons/imported-icons/empty-page.svg";

import { EmptyPageProps, EmptyPageWrapper } from '.'

export const EmptyPage: FC<EmptyPageProps> = ({
  data,
  fullPage = false,
}): ReactElement | null => {
  const { text, buttons, textMaxWidth } = data
  const theme = useTheme()

  return (
    <EmptyPageWrapper
      sx={{ justifyContent: fullPage ? 'center' : 'flex-start' }}
    >
      <Stack spacing={2} alignItems="center" pb={7.5}>
        <Typography fontSize={18} maxWidth={textMaxWidth} sx={{color: theme.palette.info.main}}>
          {text}
        </Typography>
        {buttons && buttons.length > 0 && (
          <Stack direction="row" spacing={1.5}>
            {buttons.map((button) => (
              <Button
                key={button.text}
                startIcon={button.icon && <button.icon />}
                onClick={button.onClick}
                disabled={button.disabled || false}
                color="info"
                variant="contained"
                sx={{height: 40, boxShadow: 'none'}}
              >
                {button.text}
              </Button>
            ))}
          </Stack>
        )}
      </Stack>
      <Box>
        <EmptyIcon />
      </Box>
    </EmptyPageWrapper>
  )
}
