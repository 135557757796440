import { Box } from "@mui/material";
import styled from "styled-components";

export const ManagementMenuPopperLegend = styled(Box)`
  width: 280px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 14px 2px #10182814, 0px 8px 10px 1px #1018280F, 0px 5px 5px -3px #10182814;

  & .MuiButtonBase-root {
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    height: 36px;
    padding: 8px 16px;
    text-transform: none;
    font-weight: 400;
    font-size: 14px;

    &.MuiButton-textSecondary {
      color: ${props => props.theme.secondary.textAdditional}
    }
  }
  & .MuiButton-startIcon {
    margin-right: 16px !important;
  }
  & .MuiButton-startIcon svg {
    font-size: 20px;
  }
`
