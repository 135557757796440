import styled from "styled-components";

import { Form } from "formik";
import { Button, IconButton } from "@mui/material";
export const StyledForm = styled(Form)`
  display: grid;
  grid-row-gap: 10px;

  .field-form-label {
    .title {
      font-size: 12px;
      line-height: 14px;
      color: ${({ theme }) => theme.secondary.textAdditional};
      width: max-content;
      padding-bottom: 2px;
      position: relative;

      &::before {
        content: "*";
        position: absolute;
        right: -9px;
        color: ${({ theme }) => theme.secondary.error};
      }
    }

    .MuiFilledInput-input {
      padding: 6.5px 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      border: none;
      border-radius: 6px;
      background: ${({ theme }) => theme.primary.blueShades};

      &::placeholder {
        color: ${({ theme }) => theme.primary.actionDisabled};
        opacity: 1;
      }
    }

    .MuiFormControl-root {
      border: 1px solid ${({ theme }) => `${theme.primary.textDark}1F`};
      border-radius: 6px;
    }

    .MuiInputBase-root {
      padding-right: 0;
      border-radius: 6px;
      background: ${({ theme }) => theme.primary.blueShades};
    }
  }
`;

interface ButtonHideProps {
  $fill: "gray" | "blue";
}

export const StyledButtonHide = styled(IconButton)<ButtonHideProps>`
  min-width: unset;
  background: ${({ theme }) => theme.primary.blueShades};
  border: none;
  border-radius: 0;
  padding: 0 8px;

  .MuiSvgIcon-root {
    width: 20px;
    fill: ${({ theme, $fill }) =>
      $fill === "blue" ? theme.primary.mainBlue : theme.primary.disabled};
  }
`;

export const StyledButtonConnect = styled(Button)`
  padding: 12px 16px;
  width: max-content;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 30px;
`;
