import React, { FC, useCallback } from "react";
import { useField, useFormikContext } from "formik";
import { FieldHookConfig } from "formik/dist/Field";
import { TextField, TextFieldProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { XmlFormTextField } from "./field-form.styles";

export type FormFieldProps = {
  version?: string;
  title?: string;
  name: string;
  maxLength?: number;
  labelClassName?: string;
  fieldProps?: Partial<FieldHookConfig<string>>;
} & Omit<TextFieldProps, "name">;

export const FieldForm: FC<FormFieldProps> = ({
  version,
  title,
  maxLength,
  labelClassName,
  fieldProps,
  onBlur,
  ...props
}) => {
  const { t } = useTranslation();
  const [{ onBlur: onFieldBlur, ...field }, { touched, error }] = useField({
    name: props.name,
    ...fieldProps,
  });
  const { setFieldError, setFieldValue, setFieldTouched } = useFormikContext()

  const handleBlur = useCallback(
    (e) => {
      onFieldBlur?.(e);
      onBlur?.(e);
    },
    [onBlur, onFieldBlur]
  );

  const isError = touched && !!error;

  if (version === "profile") {
    return (
      <label className={`field-form-label ${labelClassName || ""}`}>
        <Typography pb={0.5} color="secondary" pl={2} className="title">
          {title}
        </Typography>
        <TextField
          {...field}
          InputProps={{ disableUnderline: true }}
          sx={{
            "& div": {
              borderRadius: "8px",
            },
            "& .MuiFilledInput-input": {
              padding: "10px 16px",
              borderRadius: "8px",
              textOverflow: "ellipsis",
            },
          }}
          fullWidth
          variant="filled"
          size="small"
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          {...props}
        />
      </label>
    );
  } else if (version === "xml") {
    return (
      <XmlFormTextField
        {...field}
        {...props}
        fullWidth
        helperText={isError ? t(error as string) : undefined}
        onBlur={handleBlur}
        error={isError}
        autoComplete="off"
        variant="filled"
        InputProps={{ ...props.InputProps, disableUnderline: true }}
        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          let inputValue = e.target.value

          if (typeof inputValue === 'string' && inputValue) {
            inputValue = inputValue.trimStart()
            inputValue = inputValue.replace(/(\s)\s+$/, '$1')
          }
          if (maxLength) {
            if (inputValue.length <= maxLength) {
              field.onChange({
                ...e,
                target: { ...e.target, value: inputValue },
              })
            } else {
              setFieldValue(props.name, inputValue.slice(0, maxLength), true);
              setFieldError(props.name, t('common:errors.max', { maxLength: maxLength }));
            }
          } else {
            setFieldValue(props.name, inputValue, true);
          }
        }}
      />
    );
  }

  return (
    <TextField
      {...field}
      onBlur={handleBlur}
      error={isError}
      helperText={isError ? t(error as string) : undefined}
      {...props}
    />
  );
};
