import React, { FC } from "react";
import { CheckFieldForm } from "../check-field-form";
import { RolesInModules } from "./components/roles-in-modules";
import { ModulesAccessProps } from "./modules-access.types";
import { useTranslation } from "react-i18next";
import {
  StyledAdminWrapper,
  StyledAdminLabel,
  StyledAdminTitle,
} from "./modules-access.styles";

export const ModulesAccess: FC<ModulesAccessProps> = ({
  changeRole,
  hasDivision,
  hasTrustDoc,
  modules,
  customCheckboxes,
}) => {
  const { t } = useTranslation("user");

  return (
    <>
      {customCheckboxes || (
        <>
          <StyledAdminWrapper $marginBottom={16}>
            <StyledAdminTitle>{t("companyAdmin")}</StyledAdminTitle>
            <CheckFieldForm
              label={
                <StyledAdminLabel className="label">
                  {t("admin")}
                </StyledAdminLabel>
              }
              name="admin"
              color="primary"
            />
          </StyledAdminWrapper>

          {hasTrustDoc && (
            <StyledAdminWrapper $marginBottom={48}>
              <CheckFieldForm
                label={
                  <StyledAdminLabel className="label" $isLowercased={false}>
                    TrustDoc
                  </StyledAdminLabel>
                }
                name="trustDoc"
                color="primary"
              />
            </StyledAdminWrapper>
          )}

          {hasDivision && (
            <StyledAdminWrapper>
              <StyledAdminTitle>{t("headDivision")}</StyledAdminTitle>
              <CheckFieldForm
                label={<StyledAdminLabel>{t("appointed")}</StyledAdminLabel>}
                name="divisionHead"
                color="primary"
              />
            </StyledAdminWrapper>
          )}
        </>
      )}

      {modules
        .filter((module) => module !== "analytics")
        .map((nameModule) => {
          return (
            <RolesInModules
              key={nameModule}
              name={nameModule}
              title={t(`allModules.${nameModule}`)}
              handleChangeRole={changeRole}
            />
          );
        })}
    </>
  );
};
