import {
  StyledListWrapper,
  StyledEmptyPlaceholder,
  StyledPlaceholderText,
  StyledHeader,
  StyledList,
  StyledListItem,
  StyledCompanyName,
  StyledRows,
} from "./companies-list.styles";
import { CompaniesListProps } from "./companies-list.types";
import { FC, useState } from "react";
import { ReactComponent as EmptyListIcon } from "../../../../components/icons/imported-icons/maps-home-work-filled.svg";
import { useTranslation } from "react-i18next";
import { CustomRow } from "../../../../components/custom-row";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { formatDate, formatTime } from "../../../../utils/commonUtils";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Progress } from "../../../../components/progress";

export const CompaniesList: FC<CompaniesListProps> = ({
  companies,
  activeCompanyId,
  onActiveCompanyChange,
  isLoading,
}) => {
  const { t } = useTranslation("administration");

  const mainContent = (
    <>
      <StyledHeader>{`${t("companiesList")}:`}</StyledHeader>

      {companies.length ? (
        <StyledList>
          {companies.map((company) => {
            const createdAtDate = formatDate(company.createdAt) || "–";
            const createdAtTime = formatTime(company.createdAt) || "–";
            const lastActivityDate = formatDate(company.lastActivity) || "–";
            const lastActivityTime = formatTime(company.lastActivity) || "–";

            return (
              <StyledListItem
                key={company.companyID}
                $isActive={activeCompanyId === company.companyID}
                onClick={() => onActiveCompanyChange(company.companyID)}
              >
                <StyledCompanyName>{company.companyName}</StyledCompanyName>
                <StyledRows>
                  <CustomRow
                    icon={<EventNoteIcon className="icon" />}
                    title={`${t("dateCreation")}:`}
                    value={`${createdAtDate} ${createdAtTime}`}
                  />
                  <CustomRow
                    icon={<AccessTimeIcon className="icon" />}
                    title={`${t("lastActivity")}:`}
                    value={`${lastActivityDate} ${lastActivityTime}`}
                  />
                </StyledRows>
              </StyledListItem>
            );
          })}
        </StyledList>
      ) : (
        <StyledEmptyPlaceholder>
          <EmptyListIcon className="icon" />
          <StyledPlaceholderText>
            {t("noBindedCompanies")}
          </StyledPlaceholderText>
        </StyledEmptyPlaceholder>
      )}
    </>
  );

  return (
    <StyledListWrapper $isEmpty={!companies.length && !isLoading}>
      {isLoading ? <Progress /> : mainContent}
    </StyledListWrapper>
  );
};
