import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, List, Stack, Typography } from "@mui/material";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";
import { useAppContext } from "../../layouts/app-layout/context";
import {
  ModulesResponse,
  ModuleStatuses,
  ModuleType,
  SORTED_ACTIVE_STATUSES,
  SORTED_NOT_ACTIVE_STATUSES,
  useGetUrlAuthInModuleMutation,
} from "../../api/companies";
import { useSnackbar } from "notistack";
import { getGroupModulesByStatus } from "../../utils/getGroupModulesByStatus";
import { useHorizontalScroll } from "../../utils/useHorizontalScroll";
import { Progress } from "../../components/progress";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import {
  ACCESS_TO_ROLES,
  BACKGROUND_DISABLED,
  MODULES,
  PARTNERS,
} from "../../utils/constants";
import { detectBrowser } from "../../utils/browserUtils";
import { ModuleCard } from "../../components/module-card";
import { ModuleCardHorizontal } from "../../components/module-card-horizontal";
import { PartnerCard } from "../../components/partner-card";
import { getTrustDocModuleData } from "./modules.service";

const scrollStep = 608;

export const Modules: FC = () => {
  const { t } = useTranslation("modules");
  const { enqueueSnackbar } = useSnackbar();
  const {
    modules: modulesWithStatuses,
    currentCompanyId,
    profileByCompany,
  } = useAppContext();
  const { access } = profileByCompany;

  const { statuses, services, trustDoc } =
    modulesWithStatuses || ({} as ModulesResponse);

  const [getUrlAuthInModule, getUrlAuthInModuleResponse] =
    useGetUrlAuthInModuleMutation();

  const groupModulesByStatus = useMemo(
    () => getGroupModulesByStatus(statuses),
    [statuses]
  );

  const browser = useMemo(() => detectBrowser(), []);

  const handleClick = useCallback(
    (authModule) => {
      getUrlAuthInModule({ companyId: currentCompanyId, module: authModule });
    },
    [currentCompanyId, getUrlAuthInModule]
  );

  const handleUnpaidClick = useCallback(
    (url: string) => {
      window.open(url, browser === "Apple Safari" ? "_self" : "_blank");
    },
    [browser]
  );

  const { ref, scrollHorizontal } = useHorizontalScroll();

  //const [isScrollElement, setIsScrollElement] = useState(false);

  const modulesList = useMemo(
    () =>
      SORTED_ACTIVE_STATUSES.reduce((accumulator, status) => {
        const modules = groupModulesByStatus[status];
        if (Array.isArray(modules)) return [...accumulator, ...modules];

        return [...accumulator];
      }, [] as ModuleType[]),
    [SORTED_ACTIVE_STATUSES, modulesWithStatuses]
  );

  /*useEffect(() => {
    if (ref?.current?.scrollWidth !== ref?.current?.offsetWidth) {
      setIsScrollElement(true);
    }
  }, [ref]);*/
  //TODO refactor change isScrollElement = true
  const isShowScrollButtons = true;

  const handleScrollPrevCard = useCallback(() => {
    scrollHorizontal(-scrollStep);
  }, [scrollHorizontal]);
  const handleScrollNextCard = useCallback(() => {
    scrollHorizontal(scrollStep);
  }, [scrollHorizontal]);

  useMutationHandlers(
    getUrlAuthInModuleResponse,
    (data) => {
      window.open(
        `${data?.url}`,
        browser === "Apple Safari" ? "_self" : "_blank"
      );
    },
    (error) => {
      enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
    }
  );

  const isShowActiveStatusesModules =
    groupModulesByStatus?.[ModuleStatuses.available]?.length ||
    groupModulesByStatus?.[ModuleStatuses.offline]?.length ||
    groupModulesByStatus?.[ModuleStatuses.online]?.length;

  const isShowUnpaidStatusesModules =
    groupModulesByStatus?.[ModuleStatuses.unpaid]?.length;

  return (
    <List sx={{ padding: 1.25, flex: 1 }}>
      {!modulesWithStatuses ? (
        <Progress />
      ) : (
        <>
          {isShowActiveStatusesModules && (
            <Stack>
              <Typography mx={3.75} my={2} variant="h6" color="#5C6E8C">
                {t(`groupTitle.modulesYourCompany`)}
              </Typography>

              <Stack mb={3} direction="row" flexWrap="wrap" flex={1}>
                <Stack>
                  <Stack direction="row" flexWrap="wrap">
                    {modulesList?.map((module) => {
                      if (!MODULES?.[module]) return <></>;

                      const statusModule =
                        access[module] === ACCESS_TO_ROLES[0]
                          ? ModuleStatuses.online
                          : statuses[module];
                      const { background, name } = MODULES?.[module];
                      const isDisabled =
                        statusModule === ModuleStatuses.offline ||
                        statusModule === ModuleStatuses.online;

                      return (
                        <Stack
                          key={module}
                          onClick={
                            !isDisabled
                              ? () => {
                                  handleClick(module);
                                }
                              : undefined
                          }
                          sx={{
                            margin: "10px",
                            cursor: !isDisabled ? "pointer" : undefined,
                            borderRadius: "8px",
                            transition:
                              "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            "&:hover": {
                              boxShadow: !isDisabled
                                ? "0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)"
                                : undefined,
                            },
                          }}
                        >
                          <ModuleCard
                            moduleType={module as ModuleType}
                            name={name}
                            title={t(`title.${module}`)}
                            background={
                              !isDisabled ? background : BACKGROUND_DISABLED
                            }
                            status={statusModule}
                          />
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}

          <Stack
            sx={{
              "*::-webkit-scrollbar": {
                height: "0px",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography mx={3.75} mb={0} mt={2} variant="h6" color="#5C6E8C">
                {t(`groupTitle.partners`)}
              </Typography>
            </Stack>
            <Stack
              pt={2}
              style={{
                minHeight: 200,
                maxWidth: "calc(100vw - 460px)",
                overflowX: "auto",
                paddingBottom: 48,
                scrollBehavior: "smooth",
              }}
            >
              <Stack flexDirection="row" flexWrap="wrap">
                {PARTNERS.map(({ name, description, icon, url }) => {
                  const isTrustDoc = name === "TrustDoc";
                  const trustDocCardData = isTrustDoc
                    ? getTrustDocModuleData(trustDoc)
                    : null;

                  return (
                    <Stack
                      key={name}
                      sx={{
                        margin: "10px",
                        borderRadius: "8px",
                        transition:
                          "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "&:hover": {
                          boxShadow:
                            "0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
                        },
                      }}
                    >
                      <PartnerCard
                        name={name}
                        description={description}
                        status={trustDocCardData?.status || null}
                        statusName={trustDocCardData?.name || ""}
                        statusColor={trustDocCardData?.color || ""}
                        Icon={icon}
                        url={isTrustDoc ? trustDoc.url : url}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>

          {isShowUnpaidStatusesModules && (
            <Stack
              sx={{
                "*::-webkit-scrollbar": {
                  height: "0px",
                },
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography mx={3.75} mt={2} variant="h6" color="#5C6E8C">
                  {t(`groupTitle.availableForConnection`)}
                </Typography>
                {isShowScrollButtons && (
                  <Stack spacing={1} direction="row" alignItems="center">
                    <IconButton onClick={handleScrollPrevCard}>
                      <ChevronLeftSharpIcon
                        color="secondary"
                        fontSize="small"
                      />
                    </IconButton>

                    <IconButton onClick={handleScrollNextCard}>
                      <ChevronRightSharpIcon
                        color="secondary"
                        fontSize="small"
                      />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              <Stack
                direction="row"
                ref={ref}
                pt={2}
                style={{
                  minHeight: 200,
                  maxWidth: "calc(100vw - 460px)",
                  overflowX: "auto",
                  paddingBottom: 48,
                  scrollBehavior: "smooth",
                }}
              >
                {groupModulesByStatus[ModuleStatuses.unpaid].map((module) => {
                  const statusModule = statuses[module as ModuleType];
                  const landingUrl = services.find(
                    (service) => service.type === module
                  )?.landingUrl;
                  const { background, name, description } =
                    MODULES?.[module as ModuleType];
                  return (
                    <Stack
                      width={588}
                      m={1.25}
                      onClick={
                        landingUrl
                          ? () => {
                              handleUnpaidClick(landingUrl);
                            }
                          : undefined
                      }
                      sx={{
                        margin: "10px",
                        cursor: landingUrl ? "pointer" : undefined,
                        borderRadius: "20px",
                        transition:
                          "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        "&:hover": {
                          boxShadow: landingUrl
                            ? "0px 15px 13px -6px rgba(0, 0, 0, 0.2)"
                            : undefined,
                        },
                      }}
                      key={module}
                    >
                      <ModuleCardHorizontal
                        moduleType={module as ModuleType}
                        name={name}
                        title={t(`title.${module}`)}
                        description={description}
                        background={background || BACKGROUND_DISABLED}
                        status={statusModule}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          )}

          <Stack>
            <Typography mx={3.75} my={2} variant="h6" color="#5C6E8C">
              {t(`groupTitle.allPragmaSolutions`)}
            </Typography>
            <Stack direction="row" flexWrap="wrap">
              {SORTED_NOT_ACTIVE_STATUSES.map((status) => (
                <Stack key={status} direction="row" flexWrap="wrap">
                  {groupModulesByStatus[status]?.map((module) => {
                    if (!MODULES?.[module]) return <></>;

                    const { name, description } =
                      MODULES?.[module as keyof typeof MODULES];
                    return (
                      <Stack width={710} m={1.25} key={module}>
                        <ModuleCardHorizontal
                          moduleType={module as ModuleType}
                          name={name}
                          title={t(`title.${module}`)}
                          description={description}
                          background={BACKGROUND_DISABLED}
                          status={status}
                        />
                      </Stack>
                    );
                  })}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </>
      )}
    </List>
  );
};
