import { xmlApi } from "../api";
import {
  LoginCore,
  LoginResponse,
} from "./types";

export const xmlAuthApi = xmlApi.injectEndpoints({
  endpoints: (build) => ({
    loginCore: build.mutation<LoginResponse, LoginCore>({
      query: (body) => ({
        url: `/users/core-login`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["Profile"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginCoreMutation,
} = xmlAuthApi;
