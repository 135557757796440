import { Stack, Switch, Tooltip } from "@mui/material";
import { styled } from "styled-components";

export const StyledPage = styled(Stack)`
  row-gap: 10px; 
  height: 100%;
  margin: 20px;
  padding: 10px 20px 20px;
  border-radius: 8px;
  background: #fff;
`;
export const StyledSwitch = styled(Switch)`
  width: 46px;
  height: 26px;
  padding: 6px;

  & .MuiSwitch-track {
    background-color: ${(props) => props.theme.background.gray};
    opacity: 1;
  }
  & .MuiSwitch-thumb {
    background-color: ${(props) => props.theme.primary.disabled};
  }
  & .MuiSwitch-switchBase {
    padding: 3px;
  }
  & .MuiSwitch-switchBase.Mui-checked {
    & .MuiSwitch-thumb {
      background-color: ${(props) => props.theme.primary.mainBlue};
    }
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${(props) => props.theme.legends.gray};
    opacity: 1;
  }
`