import styled from "styled-components";

export const StyledCell = styled.div`
  width: 100%;
  display: grid;
  grid-auto-rows: 20px;
  grid-row-gap: 10px;
  align-items: center;
`;

export const StyledRow = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  .icon {
    width: 20px;
    fill: ${({ theme }) => theme.secondary.backgroundDivider};
  }
`;
