import { FC, useCallback, useRef, useState } from "react";
import { DatePickerProps } from "./date-picker.types";
import { DatePicker as MuiDatepicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useField } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import { fromDateToDateString } from "./date-picker.service";
import { ReactComponent as CalendarIcon } from "../icons/imported-icons/calendar.svg";
import { StyledWrapper, StyledTextField } from "./date-picker.styles";
import dayjs, { Dayjs } from "dayjs";

export const DatePicker: FC<DatePickerProps> = ({
  name,
  placeholder,
  icon,
  valueIntlOptions,
  hasButtonClear,
  popperSx,
  ...props
}) => {
  const [{ value }, , { setValue }] = useField({
    name,
  });
  const wrapperRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const handleChange = useCallback((value: Dayjs | null) => {
    if (!value) return setValue(null);

    setValue(value);
  }, []);

  return (
    <StyledWrapper ref={wrapperRef} className="date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <MuiDatepicker
          name={name}
          value={dayjs(value)}
          onChange={handleChange}
          slotProps={{
            popper: { placement: "bottom", anchorEl: wrapperRef.current, sx: popperSx },
            field: { clearable: hasButtonClear && value },
          }}
          onClose={() => setOpen(false)}
          open={open}
          slots={{
            textField: (props) => (
              <StyledTextField
                {...props}
                placeholder={placeholder}
                inputProps={{ readOnly: true }}
                value={
                  value
                    ? fromDateToDateString(new Date(value), valueIntlOptions)
                    : null
                }
                onClick={() => setOpen(true)}
              />
            ),
            openPickerIcon: icon || CalendarIcon,
          }}
          {...props}
        />
      </LocalizationProvider>
    </StyledWrapper>
  );
};
