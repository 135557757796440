import { api } from "../api";
import {
  GetSuperAdminUsersResponse,
  GetSuperAdminUsersParams,
  SuperAdminCompanyResponseList,
  GetUserParams,
  SuperAdminUserInfo,
  SuperAdminUserCompanyProfile,
  UpdateUserProfileParams,
  GetAdminUsersFiltersParams,
  SuperAdminUserFilterResponse,
  GetAccessParams,
} from "./types";
import { GetCompaniesAccessResponse } from "../counterparty/types";

export const superAdminApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSuperAdminUsers: build.query<
      GetSuperAdminUsersResponse,
      GetSuperAdminUsersParams
    >({
      query: ({ ...params }) => ({
        url: "/superadmin/users",
        params,
      }),
      providesTags: ["SuperAdmin"],
    }),

    getCompaniesList: build.query<SuperAdminCompanyResponseList, unknown>({
      query: () => ({
        url: "/superadmin/companies",
      }),
      providesTags: ["SuperAdmin"],
    }),

    getUser: build.query<SuperAdminUserInfo, GetUserParams>({
      query: ({ userID }) => ({
        url: `/superadmin/users/${userID}`,
      }),
      providesTags: ["SuperAdmin"],
    }),

    updateAdminProfile: build.mutation<
      SuperAdminUserCompanyProfile,
      UpdateUserProfileParams
    >({
      query: ({ userID, body, ...params }) => {
        return {
          url: `/superadmin/users/${userID}/update-profile`,
          body,
          params,
          method: "POST",
        };
      },
      invalidatesTags: ["SuperAdmin"],
    }),

    removeUserFromCompany: build.mutation<
      SuperAdminUserCompanyProfile,
      Omit<UpdateUserProfileParams, "body">
    >({
      query: ({ userID, ...params }) => {
        return {
          url: `/superadmin/users/${userID}/remove-from-company`,
          params,
          method: "DELETE",
        };
      },
      invalidatesTags: ["SuperAdmin"],
    }),

    getAdminUsersFilters: build.query<
      SuperAdminUserFilterResponse,
      GetAdminUsersFiltersParams
    >({
      query: ({ ...params }) => {
        return {
          url: `/superadmin/users/filters`,
          params,
          method: "GET",
        };
      },
      providesTags: ["SuperAdmin"],
    }),

    getAdminCompaniesAccess: build.query<
      GetCompaniesAccessResponse,
      GetAccessParams
    >({
      query: ({ companyID, userID }) => {
        return {
          url: `/superadmin/users/${userID}/company/${companyID}/counterparty/get-access`,
        };
      },
      providesTags: ["SuperAdmin"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSuperAdminUsersQuery,
  useGetCompaniesListQuery,
  useGetUserQuery,
  useUpdateAdminProfileMutation,
  useRemoveUserFromCompanyMutation,
  useGetAdminUsersFiltersQuery,
  useGetAdminCompaniesAccessQuery,
} = superAdminApi;
