import { CompaniesCellProps } from "./companies-cell.types";
import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
  StyledCell,
  StyledButton,
  StyledTextWrapper,
} from "./companies.styles";
import { SuperAdminUserCompanyProfile } from "../../../../api/superAdmin/types";
import { ReactComponent as MainIcon } from "../../../../components/icons/imported-icons/main-checked.svg";
import { useNavigate } from "react-router-dom";

const MAX_CENTRED_COMPANIES = 7;

export const CompaniesCell: FC<ICellRendererParams<CompaniesCellProps>> = ({
  value,
}) => {
  const companies = value?.profiles || [];
  const userId = value?.userId;
  const mainCompany = value?.mainProfile;
  const navigate = useNavigate();

  const generateUrl = (userId: number, companyId: number) => {
    if (!userId) return "";

    return `/administration/users/${userId}?company=${companyId}`;
  };

  return (
    <StyledCell $isCentered={companies.length < MAX_CENTRED_COMPANIES}>
      {companies.map((company: SuperAdminUserCompanyProfile) => {
        const url = generateUrl(userId, company.companyID);
        const isMain = mainCompany?.companyID === company.companyID;

        return (
          <StyledButton
            key={company.companyID}
            onClick={() => navigate(url)}
            variant="contained"
            disableElevation
            disableRipple
            startIcon={isMain ? <MainIcon /> : null}
            className="link-button"
          >
            <StyledTextWrapper>{company.companyName || ""}</StyledTextWrapper>
          </StyledButton>
        );
      })}
    </StyledCell>
  );
};
