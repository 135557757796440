import styled from "styled-components";

export const StyledTableWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ag-root-wrapper {
    border-radius: 8px;
    background-color: ${({ theme }) => `${theme.primary.blueShades}`};
  }
  .ag-header-viewport,
  .ag-header-container {
    background-color: ${({ theme }) => `${theme.secondary.text}`};
  }
  .ag-header {
    border-color: #6C85AA;
  }
  .ag-header-cell {
    padding: 0 8px;
    text-align: center;
   }
  .ag-header-cell-text,
  .ag-header-group-text {
    color: #fff;
  }
  .ag-header-cell .ag-header-cell-label, 
  .ag-header-cell .ag-header-group-cell, 
  .ag-header-cell .ag-header-cell-comp-wrapper,
  .ag-header-group-cell-with-group {
    justify-content: center;
  }
  .ag-header-cell .ag-header-cell-comp-wrapper:hover {
    background-color: ${({ theme }) => `${theme.secondary.text}`};
  }
  .ag-row {
    background: ${({ theme }) => `${theme.primary.blueShades}`};

    &.ag-row-white {
      background: #fff;
    }
  }
`;
