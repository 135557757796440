import { StyledPage } from "./user-by-admin.styles";
import { CompaniesList } from "./components/companies-list";
import {
  useGetUserQuery,
  useUpdateAdminProfileMutation,
  useRemoveUserFromCompanyMutation,
} from "../../api/superAdmin";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UserForm } from "./components/user-form";
import { UserAdminContext } from "./context";
import { FormikValues } from "formik/dist/types";
import { useForm } from "../../hooks/use-form";
import { validationUser } from "./validation";
import { FormikProvider } from "formik";
import { useGetAdminCompaniesAccessQuery } from "../../api/superAdmin";
import { useGetUserFiltersQuery } from "../../api/company-admin";
import { DEFAULT_DISPLAY_PASSWORD_VALUE } from "../../utils/constants";
import { fromValuesToReqBody } from "./user-by-admin.service";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import { mapFieldErrorByError } from "../../utils/mapFieldErrorByError";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useAppContext } from "../../layouts/app-layout/context";

export const UserByAdmin = () => {
  const userMatch = useMatch("/administration/users/:id");
  const [searchParams] = useSearchParams();
  const companyFromParams = searchParams.get("company");
  const id = userMatch?.params?.id ? Number(userMatch?.params?.id) : 0;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t: tUser } = useTranslation("user");
  const { t: adminT } = useTranslation("administration");
  const { t: commonT } = useTranslation("common");
  const { profile } = useAppContext();

  const [activeCompanyId, setActiveCompanyId] = useState<number | null>(
    Number(companyFromParams) || null
  );

  const { data: user, isLoading: userIsLoading } = useGetUserQuery(
    { userID: id },
    { skip: !id }
  );
  const [updateProfile, updateProfileResponse] =
    useUpdateAdminProfileMutation();
  const [removeUser, removeUserResponse] = useRemoveUserFromCompanyMutation();

  useEffect(() => {
    if (typeof activeCompanyId !== "number" && user?.profiles?.[0]) {
      setActiveCompanyId(user.profiles[0].companyID);
    }
  }, [user?.profiles]);

  const profileById = useMemo(() => {
    return user?.profiles?.find(
      (profile) => profile.companyID === activeCompanyId
    );
  }, [activeCompanyId, user]);

  const initialValues: FormikValues = useMemo(() => {
    return {
      lastName: profileById?.full?.lastName || "",
      firstName: profileById?.full?.firstName || "",
      middleName: profileById?.full?.middleName || "",
      companyName: profileById?.full?.companyName || "",
      divisionName: profileById?.full?.division || "",
      divisionHead: profileById?.full?.divisionHead || false,
      position: profileById?.full?.position || "",
      phone: profileById?.full?.phone || "",
      email: profileById?.full?.email || "",
      login: profileById?.full?.login || "",
      all: false,
      password: DEFAULT_DISPLAY_PASSWORD_VALUE,
      avatar: profileById?.avatar || null || "",
      admin: profileById?.full?.access?.admin || false,
      trustDoc: profileById?.full?.access?.trustDoc || false,
      superAdmin: user?.superAdmin || false,
      dashboard: profileById?.full?.access?.dashboard || "none",
      design: profileById?.full?.access?.design || "none",
      quality_control: profileById?.full?.access?.quality_control || "none",
      finance: profileById?.full?.access?.finance || "none",
      procurement: profileById?.full?.access?.procurement || "none",
      offer: profileById?.full?.access?.offer || "none",
      resource_managment:
        profileById?.full?.access?.resource_managment || "none",
      maintenance: profileById?.full?.access?.maintenance || "none",
      asbuilt_doc: profileById?.full?.access?.asbuilt_doc || "none",
    };
  }, [user, profileById]);

  useMutationHandlers(
    updateProfileResponse,
    (data) => {
      navigate(-1);
      enqueueSnackbar(adminT("successUpdated"), { variant: "success" });
    },
    (error) => {
      const errorData = mapFieldErrorByError(error);
      if (errorData) {
        const { field, text } = errorData;
        setFieldError(field, tUser(text));
      }
    }
  );

  useMutationHandlers(
    removeUserResponse,
    (data) => {
      navigate(-1);
      enqueueSnackbar(adminT("successUpdated"), { variant: "success" });
    },
    (error) => {
      const errorData = mapFieldErrorByError(error);
      if (errorData) {
        enqueueSnackbar(commonT("errors.error"), { variant: "error" });
      }
    }
  );

  const counterparties = useGetAdminCompaniesAccessQuery(
    {
      companyID: activeCompanyId ?? 0,
      userID: id,
    },
    { skip: activeCompanyId === null }
  );
  const counterpartiesList = counterparties.data?.data || [];

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      if (!id || !activeCompanyId) return;

      const counterpartyID =
        counterparties?.data?.mainCompany?.name === values.companyName
          ? null
          : (counterparties?.data
              ? [counterparties.data.mainCompany, ...counterparties.data.data]
              : []
            ).find((counterparty) => counterparty.name === values.companyName)
              ?.id;

      updateProfile({
        userID: id,
        companyID: activeCompanyId,
        body: fromValuesToReqBody(values, counterpartyID || null),
      });
    },
    [activeCompanyId, id, user, counterparties?.data]
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationUser,
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
  });

  const { values, handleReset, setFieldValue, setFieldError } = formik;

  const currentCounterpartyId = useMemo(() => {
    const counterparty = counterpartiesList.find(
      (counterparty) => counterparty?.name === values.companyName
    )?.id;

    if (!counterparty && user?.mainProfile?.companyName === values.companyName)
      return user?.mainProfile?.companyID;
    return counterparty;
  }, [counterpartiesList, values.companyName, user?.mainProfile]);

  const { data: userFilters } = useGetUserFiltersQuery(
    {
      companyId: activeCompanyId ?? 0,
      ...(currentCounterpartyId !== activeCompanyId &&
        currentCounterpartyId && {
          counterpartyIDs: [currentCounterpartyId],
        }),
      ...(currentCounterpartyId === activeCompanyId && { ownCompany: true }),
    },
    { skip: activeCompanyId === null }
  );

  const isAdminCheckboxDisabled = !!(
    user?.superAdmin && user?.id === profile?.id
  );

  const handleRemoveCompanyFromUser = useCallback(() => {
    if (!id || !activeCompanyId) return;

    removeUser({ userID: Number(id), companyID: activeCompanyId });
  }, [id, activeCompanyId]);

  return (
    <StyledPage>
      <CompaniesList
        companies={user?.profiles || []}
        activeCompanyId={activeCompanyId}
        onActiveCompanyChange={setActiveCompanyId}
        isLoading={userIsLoading}
      />

      <UserAdminContext.Provider
        value={{
          userValues: values,
          isLoading: userIsLoading,
          initialValues,
          currentProfile: profileById,
          profiles: user?.profiles || [],
        }}
      >
        <FormikProvider value={formik}>
          <UserForm
            companyID={activeCompanyId ?? 0}
            counterpartiesList={[
              ...(counterparties?.data?.mainCompany
                ? [counterparties?.data?.mainCompany]
                : []),
              ...counterpartiesList,
            ]}
            divisionsList={userFilters?.divisions || []}
            setFieldValue={setFieldValue}
            handleReset={handleReset}
            isSubmitDisabled={isSubmitDisabled}
            isUpdateLoading={updateProfileResponse.isLoading}
            onDelete={handleRemoveCompanyFromUser}
            isAdminCheckboxDisabled={isAdminCheckboxDisabled}
            superAdmin={!!user?.superAdmin}
          />
        </FormikProvider>
      </UserAdminContext.Provider>
    </StyledPage>
  );
};
