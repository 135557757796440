import React, {
    BaseSyntheticEvent,
    ChangeEventHandler,
    FC,
    useCallback,
    useMemo,
    useRef,
    useState,
  } from "react";
  import { useField } from "formik";
  import { Button, Stack, Typography } from "@mui/material";
  import AttachFileIcon from '@mui/icons-material/AttachFile';
  import DeleteIcon from '@mui/icons-material/Delete';
  import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
  import { FileFieldProps } from "./file-upload.types";
  import { AttachedFile, AttachFileBtn, FileError } from "./file-upload.styles";
  import { Progress } from "../../../../../../components/progress";
  import JSZip from "jszip";
  import { createExtractorFromData } from 'node-unrar-js';
  import { useTranslation } from "react-i18next";
  import { v4 } from "uuid";
  import wasmUrl from './unrar.wasm';

  export const UploadFile: FC<FileFieldProps> = ({  
    placeholder,
    fieldProps,
    ...props }) => {
    const { t } = useTranslation("xml", {
      keyPrefix: 'template-form',
    });
    const [{value: filesList}, { error, touched } , { setValue, setError, setTouched }] = useField({
      name: 'files',
      ...fieldProps,
    });
    const fileInput = useRef<HTMLInputElement|null>(null);
    const [isLoading, setLoading] = useState(false);
    const xslFileCount = useMemo(() => filesList?.filter((file => file.title.endsWith('xsl'))).length || 0, [filesList]);

    const handleSetValue = useCallback(
      (file: File) => {
        setLoading(true);
        let fileExtension = file.name.split('.').pop()?.toLowerCase();

        const setResult = (files: { file: File; title: string; id: string; }[], xsdCount: number, xslCount: number) => {
          if (files.length > 0) {
            setValue(files.sort((a, b) => {
              const aIsXsl = a.title.endsWith('.xsl');
              const bIsXsl = b.title.endsWith('.xsl');
              const aIsXsd = a.title.endsWith('.xsd');
              const bIsXsd = b.title.endsWith('.xsd');

              // Сортируем сначала .xsl, потом .xsd
              if (aIsXsl && !bIsXsl) {
                return -1;
              } else if (!aIsXsl && bIsXsl) {
                return 1;
              } else if (aIsXsd && !bIsXsd) {
                return -1;
              } else if (!aIsXsd && bIsXsd) {
                return 1;
              } else {
                // Если типы файлов совпадают, сортируем по алфавиту
                return a.title.localeCompare(b.title, 'ru');
              }
            }), touched ? true : false);
            if (xsdCount) {
              if (xslCount) {
                if (xslCount > 1) {
                  setError(t('errors.xsl-error'));
                  setTouched(true, false);
                } else {
                  // если нет ошибок валидируем поле
                  setTouched(true, true);
                }
              } else {
                setError(t('errors.no-xsl'));
                setTouched(true, false);
              }
            } else {
              setError(t('errors.no-xsd'));
              setTouched(true, false);
            }
          } else {
            setError(t('errors.no-files'));
            setTouched(true, false);
          }
          setLoading(false);
        }
        const handleZip = async (file: File) => {
          const zip = new JSZip();
          const extracted = await zip.loadAsync(file, { 
            decodeFileName: function (bytes) {
              const decoder = new TextDecoder('cp866');
              if (bytes instanceof Uint8Array) {
                return decoder.decode(bytes);
              } else {
                throw new Error("Некорректный тип данных: ожидался Uint8Array, ArrayBuffer или Buffer.");
              }
            } 
          });
          console.log(extracted)
          try {
            const files: { file: File; title: string; id: string; }[] = [];
            let xsdCount = 0;
            let xslCount = 0;
            for (let zipEntry of Object.values(extracted.files)) {
              const fileName = zipEntry.name.split('/').pop() || '';  // Получаем только имя файла
              if (fileName.endsWith('.xsl') || fileName.endsWith('.xsd')) {
                if (fileName.endsWith('.xsl')) {
                  xslCount++;
                } else {
                  xsdCount++;
                }
                const blob = await zipEntry.async('blob');
                files.push({ title: fileName, file: new File([blob], fileName), id: v4() });
              }
            }
            setResult(files, xsdCount, xslCount)
          } catch (err) {
            setLoading(false);
            setTouched(true, false);
            setError(t('errors.archive-error'));
          }
        };
        const handleRar = async (file: File) => {
          try {
            const buffer = await file.arrayBuffer();
            const wasmResponse = await fetch(wasmUrl, { credentials: 'same-origin' });
            const wasmBinary = await wasmResponse.arrayBuffer();
            const extractor = await createExtractorFromData({ wasmBinary, data: buffer });
            const extracted = Array.from(extractor.extract().files);
            const files = await Promise.all(extracted.filter(file => file.extraction && (file.fileHeader.name.endsWith('.xsl') || file.fileHeader.name.endsWith('.xsd')))
              .map(async file => {
                const blob = new Blob([file.extraction as Uint8Array]);
                const fileName = file.fileHeader.name.split('/').pop() || '';
                return { title: fileName, file: new File([blob], fileName), id: v4() };
              }));
            const xsdCount = extracted.filter(file => file.extraction && (file.fileHeader.name.endsWith('.xsd'))).length;
            const xslCount = extracted.filter(file => file.extraction && (file.fileHeader.name.endsWith('.xsl'))).length;
            setResult(files, xsdCount, xslCount);
          } catch (err) {
            setLoading(false);
            setValue([], false);
            setTouched(true, false);
            setError(t('errors.archive-error'));
          }
        };
        switch (fileExtension) {
          case 'zip':
            handleZip(file);
            break;
          case 'rar':
            handleRar(file);
            break;
          default:
            setLoading(false);
            setTouched(true, false);
            setError(t('errors.format-error'));
        }
      },
      [setValue, setTouched, setError, setLoading, touched]
    );

    const handleChangeFile: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        const file = e?.target?.files?.[0] || null;
        if (file) {
          handleSetValue(file);
        }
      },
      [handleSetValue]
    );

    const handleDeleteFile = (id: string) => {
      setValue(filesList.filter((el) => el.id !== id), true);
    };

    return (
      <>
        {touched && error && <FileError>{error}</FileError>}
        {(filesList && filesList.length > 0) && <Stack mt='-6px'>
          {filesList.map((file) => <AttachedFile key={file.title} >
            <Typography 
              variant="body1"
              className={xslFileCount > 1 && file.title.endsWith('xsl') ? "error" : undefined}>
              <InsertDriveFileIcon sx={{color: 'currentColor'}}/>
              {file.title}
            </Typography>
            <Button 
              variant="text"
              onClick={() => handleDeleteFile(file.id)}>
              <DeleteIcon/>
            </Button>
          </AttachedFile>)}
        </Stack>}
        <label
          htmlFor="upload-file"
        >
          <input
            style={{ display: "none" }}
            accept=".zip,.rar"
            id="upload-file"
            type="file"
            onChange={handleChangeFile}
            onClick={(e: BaseSyntheticEvent) => (e.target.value = "")}
            ref={fileInput}
          />
          <AttachFileBtn
            color="secondary"
            fullWidth
            onClick={()=> fileInput.current && fileInput.current.click()}
          >
            {isLoading 
              ? <Progress/> 
              : <>
                <AttachFileIcon fontSize="small"/>
                {placeholder}
              </>
            }
          </AttachFileBtn>
        </label>
      </>
    );
  };
  