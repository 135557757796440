import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, useTheme } from '@mui/material'
import { ArrowCellProps } from "./arrow-cell.types";

export const ArrowCell: FC<
ICellRendererParams<unknown, ArrowCellProps>
> = ({ value }) => { 
  const theme = useTheme();

  return (
    <>
      {value?.arrow 
        ? <Button
          variant="outlined"
          color="secondary"
          sx={{ 
            width: 20,
            height: 20,
            minWidth: 20,
            border: 'none!important',
            color: theme.palette.text.secondary,
          }}
          onClick={() => value?.onArrowClick(value.typeId, value.id)}
        >
          {value.open 
            ? <KeyboardArrowUpIcon sx={{ fontSize: 20 }} />
            : <KeyboardArrowDownIcon sx={{ fontSize: 20 }} />
          }
        </Button>
        : null
        }
    </>
  )
}
