import styled from "styled-components";

interface CheckboxWrapperProps {
  $margin: string;
}

export const StyledCheckboxWrapper = styled.div<CheckboxWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiFormControlLabel-root {
    margin: 0;
    border: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
    border-radius: 8px;
    min-width: 220px;
  }

  .MuiButtonBase-root {
    padding: 9px;
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }

  margin: ${({ $margin }) => $margin};
`;

export const StyledLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
