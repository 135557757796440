import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ColorDivider } from "../../components/color-divider";
import { useTranslation } from "react-i18next";
import { Progress } from "../../components/progress";
import { useGlobalSearch } from "../../utils/useGlobalSearch";
import { StyledPage, StyledSwitch } from "./xml-constructor.styles";
import { EmptyPage } from "../../components/empty-page";
import { TemplatesTable } from "./components/templates-table";
import { useBreadcrumbsContext } from "../../layouts/layout/context";
import { TemplateFormDrawer } from "./components/temlate-form-drawer";
import { useGetTemplatesByFolderIdQuery } from "../../api-xml-constructor/templates";
import { useGetFolderByIdQuery } from "../../api-xml-constructor/folders";
import { TemplateViewModal } from "./components/template-view-modal";
import { XMLConstructorLayout } from "../../layouts/xml-constuctor-layout";
import { groupTableDataByVersion } from "./components/templates-table/templates-table.service";

export const XMLConstructor = () => {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("xml", {
    keyPrefix: 'template-table',
  });
  const theme = useTheme();
  const { id: folderId } = useParams();
  const [searchParam] = useGlobalSearch();
  const [searchParams, setSearchParams] = useSearchParams();
  const showNotActual = searchParams.get("showNotActual") === 'true';
  const editTemplateId = searchParams.get("edit");
  const newVersionId = searchParams.get("newVersion");
  const viewTemplateId = searchParams.get("view");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [showAddDrawer, setShowAddDrawer] = useState(false);

  const { data: folderData } = useGetFolderByIdQuery(folderId || '', { skip: folderId === 'all' });
  const { data: templatesList, isLoading: templatesListLoading } = useGetTemplatesByFolderIdQuery({folderId: folderId || '', ...(searchParam && { search: searchParam })});
  const sortedTemplatesList = useMemo(() => templatesList ? groupTableDataByVersion([...templatesList]) : [], [templatesList, groupTableDataByVersion]);

  const handleButtonBack = useCallback(() => {
    navigate(`/administration?tab=xml`);
  }, [navigate]);

  const handleChangeShowActual = (value: boolean) => {
    setSearchParams((_params) => {
      const params = new URLSearchParams(window.location.search);
      params.set('showNotActual', value ? 'true' : 'false');
      return params
    });
  };

  const handleAddClick = () => {
    setShowAddDrawer(true);
  };

  const handleCloseFormDrawer = () => {
    setSearchParams((_params) => {
      const params = new URLSearchParams(window.location.search);
      if (params.has('edit')) {
        params.delete('edit');
      }
      if (params.has('newVersion')) {
        params.delete('newVersion');
      }
      return params
    });
    setShowAddDrawer(false);
  };

  const handleCloseTemplateViewModal = () => {
    setSearchParams((_params) => {
      const params = new URLSearchParams(window.location.search);
      params.delete('view');
      return params
    });
  };

  useEffect(() => {
    setBreadcrumbs([
      { title: 'XML-конструктор' },
      { title: folderId === 'all' ? "Все шаблоны" : (folderData?.folderEntity.title || '') }
    ]);
  }, [folderData, folderId]); 

  useEffect(() => {
    if (editTemplateId || newVersionId) setShowAddDrawer(true)
  }, [searchParams]); 

  return (
    <XMLConstructorLayout>
      <StyledPage>
        <Stack rowGap={0.2}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleButtonBack} 
            color="info"
            sx={{
              height: 45,
              marginRight: 'auto',
              fontSize: 18,
              textTransform: 'none',
              color: theme.palette.info.main
            }}>
            {tCommon("buttons.back")}
          </Button>
          <ColorDivider size={1}/>
        </Stack>
        {templatesListLoading 
          ? <Progress /> 
          : templatesList && templatesList.length > 0 
            ? <>
                <Stack columnGap={2} direction="row" justifyContent="space-between" alignItems="center">
                  <Stack columnGap={1.2} direction="row" alignItems="center">
                    <StyledSwitch 
                      checked={showNotActual}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeShowActual(e.target.checked)}/>
                    <Typography variant="body1" >
                      {t('show-not-actual')}
                    </Typography>
                  </Stack>
                  <Button 
                    variant="contained"
                    color="info"
                    onClick={handleAddClick}
                    sx={{
                      height: 40,
                      boxShadow: 'none'
                    }}>
                    {t('btn-add')}
                  </Button>
                </Stack>
                <TemplatesTable data={sortedTemplatesList}/>
                <TemplateViewModal
                  open={!!viewTemplateId}
                  onClose={handleCloseTemplateViewModal}/>
              </>
            : <EmptyPage 
                fullPage
                data={{
                  text: <>{searchParam ? t('empty-page-search') : t('empty-page')}</>,
                  buttons: !searchParam ? [{
                    text: t('btn-add'),
                    onClick: handleAddClick
                  }] : undefined
                }}/>
        }
        <TemplateFormDrawer 
          open={showAddDrawer}
          onClose={handleCloseFormDrawer}
        />
      </StyledPage>
    </XMLConstructorLayout>  
  );
};