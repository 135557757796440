import {
  StyledCounterpartyStaff,
  StyledUsersBlock,
  StyledRow,
  StyledSubTitle,
  StyledRowButtonAdd,
  StyledAccessBlock,
  StyledAccessDescription,
  StyledIconAccessWrapper,
  StyledForm,
  StyledButtonsWrapper,
} from "./counterparty-staff.styles";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CounterpartyStaffProps } from "./counterparty-staff.types";
import { UsersList } from "./components/users-list";
import { counterpartyStaffContext } from "./context";
import { useNavigate, useLocation } from "react-router-dom";
import { Progress } from "../progress";
import { FormikProvider } from "formik";
import { useForm } from "../../hooks/use-form";
import { ModulesAccess } from "../modules-access";
import { AccessButtons } from "./components/access-buttons";
import { useAppContext } from "../../layouts/app-layout/context";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";

export const CounterpartyStaff: FC<CounterpartyStaffProps> = ({
  staffData,
  companyID,
  counterpartyName,
  onUserUnSync,
  onAccessConfirm,
  isLoading,
  isAccessLoading,
  scrollPaddingBottom,
}) => {
  const { t } = useTranslation("counterparty");
  const navigate = useNavigate();
  const location = useLocation();
  const { availableModules } = useAppContext();

  const [modalUnSyncID, updateModalUnSyncID] = useState<null | string | number>(
    null
  );

  const initialValues = useMemo(
    () => ({
      admin: staffData?.access?.admin || false,
      divisionHead: false,
      dashboard: staffData?.access?.dashboard || "none",
      design: staffData?.access?.design || "none",
      quality_control: staffData?.access?.quality_control || "none",
      finance: staffData?.access?.finance || "none",
      procurement: staffData?.access?.procurement || "none",
      offer: staffData?.access?.offer || "none",
      resource_managment: staffData?.access?.resource_managment || "none",
      maintenance: staffData?.access?.maintenance || "none",
      asbuilt_doc: staffData?.access?.asbuilt_doc || "none",
    }),
    [staffData?.access]
  );

  const { formik, setFieldValue } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: onAccessConfirm,
  });
  const { values, handleReset } = formik;

  const isSubmitDisabled = useMemo(() => {
    return JSON.stringify(values) === JSON.stringify(initialValues);
  }, [initialValues, values]);

  const handleButtonAddClick = useCallback(() => {
    if (!companyID) return;

    navigate(`/companies/${companyID}/users/create`, {
      state: {
        userValues: {
          companyName: counterpartyName,
          ...staffData?.access,
        },
        from: location.pathname,
      },
    });
  }, [companyID, navigate, staffData?.access]);

  if (isLoading) return <Progress />;
  if (!staffData) return null;

  return (
    <counterpartyStaffContext.Provider
      value={{
        onUserUnSync,
        companyID,
        counterpartyName,
        modalUnSyncID,
        updateModalUnSyncID,
      }}
    >
      <StyledCounterpartyStaff>
        <StyledUsersBlock onScroll={() => updateModalUnSyncID(null)}>
          <StyledRow $isSticky>
            <StyledSubTitle>{t("staff.list.title")}</StyledSubTitle>
            <StyledRowButtonAdd
              variant="contained"
              onClick={handleButtonAddClick}
            >
              {t("staff.add")}
            </StyledRowButtonAdd>
          </StyledRow>

          <UsersList users={staffData.divisions} access={staffData?.access} />
        </StyledUsersBlock>

        <StyledAccessBlock>
          <StyledRow>
            <StyledSubTitle>{t("staff.access.title")}</StyledSubTitle>
          </StyledRow>

          <StyledAccessDescription>
            {t("staff.access.mainDescription")}
            <StyledIconAccessWrapper>
              <SyncDisabledIcon className="icon-unsync" />
            </StyledIconAccessWrapper>
          </StyledAccessDescription>

          <StyledAccessDescription>
            {t("staff.access.secondaryDescription")}
          </StyledAccessDescription>

          <FormikProvider value={formik}>
            <StyledForm $paddingBottom={scrollPaddingBottom}>
              <ModulesAccess
                changeRole={(value) => setFieldValue(value.name, value.role)}
                hasDivision={false}
                modules={availableModules}
              />

              {!isSubmitDisabled && (
                <StyledButtonsWrapper>
                  <AccessButtons
                    handleReset={handleReset}
                    isDisabled={isAccessLoading}
                  />
                </StyledButtonsWrapper>
              )}
            </StyledForm>
          </FormikProvider>
        </StyledAccessBlock>
      </StyledCounterpartyStaff>
    </counterpartyStaffContext.Provider>
  );
};
