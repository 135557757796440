import { xmlApi } from "../api";
import { CreateTemplateRequest, EditTemplateRequest, FullXmlTemplate, GetTemplatesRequest, XmlTemplate, XmlTemplateListItem } from "./templates.types";

export const xmlTemplatesApi = xmlApi.injectEndpoints({
  endpoints: (build) => ({
    getTemplatesByFolderId: build.query<XmlTemplateListItem[], GetTemplatesRequest>({
      query: ({folderId, ...params}) => ({
        url: folderId === 'all' ? `/templates` : `/folders/${folderId}/templates`,
        method: "GET",
        params,
      }),
      providesTags: ["Templates"],
    }),
    getTemplateById: build.query<FullXmlTemplate, string>({
      query: (templateId) => `/templates/${templateId}`,
      providesTags: (_res, _errors, arg) => ([{type: "Template", id: arg}]),
    }),
    fetchTemplateById: build.mutation<FullXmlTemplate, string>({
      query: (templateId) => `/templates/${templateId}`,
    }),
    getTemplatesCount: build.query<number, void>({
      query: () => `/templates/count`,
      providesTags: ["Templates"],
    }),
    createTemplate: build.mutation<XmlTemplate, CreateTemplateRequest>({
      query: (body) => ({
        url: `/templates`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["Templates"],
    }),
    editTemplateById: build.mutation<XmlTemplate, EditTemplateRequest>({
      query: ({templateId, body}) => ({
        url: `/templates/${templateId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_res, _errors, arg) => (["Templates", {type: "Template", id: arg.templateId}]),
    }),
    deleteTemplateById: build.mutation<void, string>({
      query: (templateId) => ({
        url: `/templates/${templateId}`,
        method: "DELETE",
      }),
      invalidatesTags: () => ["Templates"],
    }),
  }),
  overrideExisting: false,
});

export const { 
  useGetTemplatesByFolderIdQuery, 
  useGetTemplateByIdQuery, useFetchTemplateByIdMutation,
  useCreateTemplateMutation, useEditTemplateByIdMutation, useDeleteTemplateByIdMutation, 
  useGetTemplatesCountQuery 
} = xmlTemplatesApi;