import styled from "styled-components";

interface ExternalModuleProps {
  $isFilled?: boolean;
}

export const StyledExternalModules = styled.div<ExternalModuleProps>`
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
  padding-left: 32px;
`;
