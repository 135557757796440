import styled from "styled-components";
import { Button } from "@mui/material";
import { textOneRow } from "../../../../common/styledMixins";

interface CellProps {
  $isCentered?: boolean;
}

export const StyledCell = styled.div<CellProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 0.5fr));
  grid-gap: 4px 10px;
  align-content: ${({ $isCentered }) => $isCentered && "center"};
  height: 100%;
  overflow-y: auto;
  padding: 9px 12px;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  line-height: 29px;
  font-weight: 400;
  text-transform: unset;
  padding: 9px 8px;
  height: 28px;
  ${textOneRow};
  background: ${({ theme }) => theme.primary.blueShades};
  color: ${({ theme }) => theme.primary.mainBlue};
  text-align: start;

  &:hover {
    background: ${({ theme }) => theme.primary.blueShades};
    color: ${({ theme }) => theme.primary.mainBlue};
  }
`;

export const StyledTextWrapper = styled.div`
  ${textOneRow};
  width: 100%;
`;
