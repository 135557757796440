import { ModuleTrustDocStatus } from "../../api/companies";
import { PartnerStatus } from "../../components/partner-card/partner-card.types";

export function getTrustDocModuleData(
  serverStatus: ModuleTrustDocStatus
): { status: PartnerStatus; name: string; color: string } | null {
  if (serverStatus.admin && !serverStatus.haveProfile)
    return { status: "available", name: "ДОСТУПЕН", color: "#14B8A6" };
  if (serverStatus.haveProfile)
    return { status: "connected", name: "ПОДКЛЮЧЕН", color: "#6D9ADC" };

  return null;
}
