import { xmlApi } from "../api";
import { CreateFolderRequest, XMLFolder } from "./folders.types";

export const xmlFoldersApi = xmlApi.injectEndpoints({
  endpoints: (build) => ({
    getFolders: build.query<XMLFolder[], void>({
      query: () => "/folders",
      providesTags: ["Folders"],
    }),

    getFolderById: build.query<XMLFolder, string>({
      query: (folderId) => `/folders/${folderId}`,
    }),

    createFolder: build.mutation<XMLFolder['folderEntity'],CreateFolderRequest>({
      query: (values) => ({
        url: `/folders`,
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: () => ["Folders"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetFoldersQuery, useGetFolderByIdQuery, useCreateFolderMutation } = xmlFoldersApi;
