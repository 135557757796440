import styled, { css } from "styled-components";

interface FioCellParams {
  $hasInitials: boolean;
}

export const StyledCell = styled.div<FioCellParams>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 20px;

  .MuiAvatar-root {
    width: 60px;
    height: 60px;
    background: ${({ theme, $hasInitials }) =>
      $hasInitials ? theme.primary.mainBlue : theme.background.gray};
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
  }

  .MuiAvatar-fallback {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.primary.disabled};
  }
`;

export const StyledUsers = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-row-gap: 4px;
`;

export const StyledUser = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`;

export const StyledAvatarWrapper = styled.div`
  position: relative;
`;

const iconCommonStyles = css`
  width: 26px;
  height: 26px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  position: absolute;
`;

export const StyledSuperAdminIcon = styled.div`
  ${iconCommonStyles};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.primary.mainBlue};
  top: 0;
  right: 0;
`;

export const StyledUnbindIcon = styled.div`
  border: 2px solid ${({ theme }) => theme.background.gray};
  border-radius: 24px;
  ${iconCommonStyles};

  .icon {
    width: 16px;

    path {
      fill: ${({ theme }) => theme.secondary.error};
    }
  }
`;
