import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { UserFormProps } from "./user-form.types";
import {
  StyledWrapper,
  StyledApplyToAllWrapper,
  StyledAllLabel,
  StyledButtonDelete,
  StyledCompanyName,
} from "./user-form.styles";
import { Form } from "formik";
import { Button, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Progress } from "../../../../components/progress";
import { ScrollableContainer } from "../../../../components/scrollable-container";
import {
  DEFAULT_DISPLAY_PASSWORD_VALUE,
  MAX_WIDTH_PROFILE_BLOCK,
} from "../../../../utils/constants";
import { UserAccountForm } from "../../../../components/user-account-form";
import { UserContactsForm } from "../../../user/components/user-contacts-form";
import { UserButtons } from "../../../../components/user-buttons";
import { ModulesAccess } from "../../../../components/modules-access";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserAdminContext } from "../../context";
import { generateRandomString } from "../../../../utils/generate-random-string";
import { useGetModulesByCompanyIdQuery } from "../../../../api/companies";
import { fromModulesToAvailableModules } from "../../../../utils/commonUtils";
import { CheckboxesBlock } from "../checkboxes-block";
import { CheckFieldForm } from "../../../../components/check-field-form";
import { ReactComponent as UnbindIcon } from "../../../../components/icons/imported-icons/unbind.svg";
import { DeleteModal } from "../../../../components/delete-modal";

export const UserForm: FC<UserFormProps> = ({
  companyID,
  counterpartiesList,
  divisionsList,
  setFieldValue,
  handleReset,
  isSubmitDisabled,
  isUpdateLoading,
  isAdminCheckboxDisabled,
  superAdmin,
  onDelete,
}) => {
  const { t } = useTranslation("user");
  const { t: tAdmin } = useTranslation("administration");
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { isLoading, userValues, initialValues, currentProfile, profiles } =
    useContext(UserAdminContext);

  const { data: modules } = useGetModulesByCompanyIdQuery(companyID, {
    skip: !companyID,
  });

  const availableModules = useMemo(
    () => fromModulesToAvailableModules(modules),
    [modules]
  );

  const isShowConfirmPhone =
    !!userValues.phone && userValues.phone === initialValues.phone;
  const isShowConfirmEmail =
    !!userValues.email && userValues.email === initialValues.email;

  const isVisibleButtons = useMemo(
    () =>
      !!userValues &&
      Object.keys(userValues).find(
        (key) =>
          (userValues[key as keyof typeof initialValues] || "") !==
          (initialValues[key as keyof typeof initialValues] || "")
      ),
    [userValues, initialValues]
  );

  const setLoginGeneration = useCallback(() => {
    setFieldValue("login", generateRandomString({}));
  }, [setFieldValue]);

  const setPasswordGeneration = useCallback(() => {
    setFieldValue(
      "password",
      generateRandomString({
        length: 8,
        lower: true,
        symbols: true,
        numbers: true,
        upper: true,
      })
    );
  }, [setFieldValue]);

  const resetPasswordValue = useCallback(() => {
    if (userValues?.password === DEFAULT_DISPLAY_PASSWORD_VALUE) {
      setFieldValue("password", "");
    }
  }, [setFieldValue, userValues]);

  const setPasswordInitialValue = useCallback(() => {
    if (userValues.password === "") {
      setFieldValue("password", DEFAULT_DISPLAY_PASSWORD_VALUE);
    }
  }, [setFieldValue, userValues]);

  const handleButtonBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const setFieldRole = useCallback(
    ({ name, role }) => {
      setFieldValue(name, role);
    },
    [setFieldValue]
  );

  return (
    <StyledWrapper>
      <Stack flex={1} component={Form} height="100%">
        <Stack px={5} py={3.75} direction="row" justifyContent="space-between">
          <Button
            onClick={handleButtonBackClick}
            sx={{
              "&": {
                color: "#2B3648",
              },
            }}
            startIcon={<ArrowBackIcon />}
          >
            <Typography variant="body2" className="button-back-text">
              {t("common:buttons.back")}
            </Typography>
          </Button>

          <StyledButtonDelete
            disabled={(profiles.length <= 1 && superAdmin) || isLoading}
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <UnbindIcon className="delete-icon" />
          </StyledButtonDelete>
        </Stack>
        {isLoading ? (
          <Progress />
        ) : (
          <ScrollableContainer>
            <Stack direction="row" margin="0 auto" height="min-content">
              <Stack
                minWidth={MAX_WIDTH_PROFILE_BLOCK}
                flex={1}
                px={7.5}
                pb={7.5}
                sx={{
                  ".field-form-label:first-child .title": {
                    marginTop: 0,
                  },
                }}
              >
                <Stack>
                  <UserAccountForm
                    counterparties={counterpartiesList}
                    userValues={userValues}
                    divisions={divisionsList}
                    hasMainCompany={false}
                  />
                  <UserContactsForm
                    isShowConfirmPhone={isShowConfirmPhone}
                    isShowConfirmEmail={isShowConfirmEmail}
                    isPhoneConfirmed={currentProfile?.full?.phoneConfirmed}
                    isEmailConfirmed={currentProfile?.full?.emailConfirmed}
                    handleLoginGeneration={setLoginGeneration}
                    handlePasswordGeneration={setPasswordGeneration}
                    isShowPassword={true}
                    isDisabled={false}
                    onBlurPassword={setPasswordInitialValue}
                    onFocusPassword={resetPasswordValue}
                  />

                  <StyledApplyToAllWrapper>
                    <CheckFieldForm
                      label={
                        <StyledAllLabel>{tAdmin("applyToAll")}</StyledAllLabel>
                      }
                      name="all"
                      color="primary"
                    />
                  </StyledApplyToAllWrapper>

                  {isVisibleButtons && (
                    <UserButtons
                      isSubmitDisabled={isSubmitDisabled || isUpdateLoading}
                      isDisabledContactsFields={false}
                      handleReset={handleReset}
                    />
                  )}
                </Stack>
              </Stack>
              <Stack
                minWidth={MAX_WIDTH_PROFILE_BLOCK}
                flex={1}
                px={7.5}
                pb={9}
                sx={{
                  "& .role-in-module": {
                    marginBottom: "16px",
                  },

                  "& .title": {
                    display: "none",
                  },
                }}
              >
                <ModulesAccess
                  changeRole={setFieldRole}
                  hasDivision={!!userValues.divisionName}
                  modules={availableModules}
                  customCheckboxes={
                    <CheckboxesBlock
                      isAdminCheckboxDisabled={isAdminCheckboxDisabled}
                      setFieldValue={setFieldValue}
                    />
                  }
                />
              </Stack>
            </Stack>
          </ScrollableContainer>
        )}
      </Stack>

      <DeleteModal
        open={isDeleteModalOpen}
        title={tAdmin("unbindModal.title")}
        description={
          <span>
            <span>{`${tAdmin("unbindModal.descriptionStart")} `}</span>
            <StyledCompanyName>
              {currentProfile?.companyName || "–"}
            </StyledCompanyName>
            <span>{` ${tAdmin("unbindModal.descriptionEnd")}`}</span>
          </span>
        }
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onDelete}
        rightButtonColor="primary"
      />
    </StyledWrapper>
  );
};
