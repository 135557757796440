import React, { FC, useCallback, useState } from "react";
import { Dialog, Tooltip, useTheme } from "@mui/material";
import EventNoteIcon from '@mui/icons-material/EventNote';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "react-i18next";
import { FormDrawer } from "../../../../components/form-drawer";
import { ConfirmationDialog } from "../../../../components/confirmation-dialogs";
import { FieldForm } from "../../../../components/field-form";
import { schemaNoFiles, schemaWithFiles, TemlateFormDrawerProps } from "./temlate-form-drawer.types";
import { DrawerBlockLabel, DrawerFieldLabel, FieldBlock, FieldTitleBlock } from "./template-form-drawer.styles";
import { ColorDivider } from "../../../../components/color-divider";
import { DatePicker } from "./components/date-picker";
import dayjs from "dayjs";
import { UploadFile } from "./components/file-upload";
import { useParams, useSearchParams } from "react-router-dom";
import { CreateTemplateRequest, useCreateTemplateMutation, useEditTemplateByIdMutation, useGetTemplateByIdQuery } from "../../../../api-xml-constructor/templates";
import { useSnackbar } from "notistack";
import { useCreateAttachementMutation } from "../../../../api-xml-constructor/files";

export const TemplateFormDrawer: FC<TemlateFormDrawerProps> = ({open, onClose}) => {
  const { t } = useTranslation("xml", {
    keyPrefix: 'template-form',
  });
  const { t: tCommon } = useTranslation("common");
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar();
  const { id: folderId } = useParams();
  const [searchParams] = useSearchParams();
  const editTemplateId = searchParams.get("edit");
  const newVersionId = searchParams.get("newVersion");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [formError, setFormError] = useState(false);
  const { data: templateData } = useGetTemplateByIdQuery((editTemplateId || newVersionId || ''), { skip: !editTemplateId && !newVersionId, refetchOnMountOrArgChange: true });
  const [createTemplate] = useCreateTemplateMutation();
  const [editTemplate] = useEditTemplateByIdMutation();
  const [createAttachement] = useCreateAttachementMutation();
  
  const drawerHandleOnClose = useCallback(() => {
    onClose();
  }, []);

  const drawerHandleSubmit = useCallback(async (values) => {
    const currentFolderId = folderId === 'all' ? templateData?.templateWithAuthor.template.folderId : folderId;
    const body = {
      folderId: currentFolderId,
      title: values.title,
      isActive: true,
      shortTitle: values.shortTitle,
      publicationDate: values.publicationDate,
      expirationDate: values.expirationDate,
      commentary: values.commentary
    }
    if (editTemplateId) {
      const editedBody = {
        ...body, 
        xsdFileIds: templateData?.xsdFilesIds, 
        xslFileId: templateData?.templateWithAuthor.template.xslFileId,
        typeId: templateData?.templateWithAuthor.template.typeId
      }
      const res = await editTemplate({templateId: editTemplateId, body: editedBody as CreateTemplateRequest});
      if ("error" in res) {
        enqueueSnackbar(
          tCommon('errors.request_error'),
          { variant: "error" }
        );
      } else {
        enqueueSnackbar(t("success.edit"), {
          variant: "success",
        });
        onClose();
      }
    } else {
      let xsdFileIds = [];
      let xslFileId;

      for (let file of values.files) {
        const formData = new FormData();
        formData.append("file", file.file);
        const res = await createAttachement(formData);
        if ("error" in res) {
          enqueueSnackbar(
            tCommon('errors.request_error'),
            { variant: "error" }
          );
          return;
        } else {
          if (file.title?.endsWith('.xsl')) {
            xslFileId = res.data.id;
          } else if (file.title?.endsWith('.xsd')) {
            xsdFileIds.push(res.data.id);
          }
        }
      }
      const createBody = {
        ...body,
        xsdFileIds, 
        xslFileId,
        typeId: newVersionId ? templateData?.templateWithAuthor.template.typeId : null
      }
      const createRes = await createTemplate(createBody as CreateTemplateRequest);
      if ("error" in createRes) {
        if ('status' in createRes.error && createRes.error.status === 400) {
          enqueueSnackbar(
            t('errors.upload-files-error'),
            { variant: "error" }
          );
          setFormError(true);
        } else {
          enqueueSnackbar(
            tCommon('errors.request_error'),
            { variant: "error" }
          );
        }
      } else {
        enqueueSnackbar(t("success.create"), {
          variant: "success",
        });
        onClose();
      }
    }
  }, [enqueueSnackbar, onClose, editTemplateId, folderId, templateData, editTemplate, createTemplate, t, tCommon]);

  const drawerHandleCancel = useCallback((isDirty: boolean) => {
    if (isDirty) {
      setOpenConfirmationDialog(true);
    } else {
      drawerHandleOnClose();
    }
  }, []);

  const drawerHandleClose = useCallback((isDirty: boolean) => {
    if (isDirty) {
      setOpenConfirmationDialog(true);
    } else {
      drawerHandleOnClose();
    }
  }, []);

  const handleSuccessDialog = useCallback(() => {
    drawerHandleOnClose();
    setOpenConfirmationDialog(false);
  }, []);

  const handleCancelDialog = useCallback(() => {
    setOpenConfirmationDialog(false);
  }, []);

  return (
    <>
      <FormDrawer
       isOpen={open}
       handleOnClose={drawerHandleClose}
       handleSubmit={drawerHandleSubmit}
       handleCancel={drawerHandleCancel}
       title={editTemplateId ? t('title-edit') : newVersionId ? t('title-new-version') : t('title-create')}
       initialValues={{
        title: (editTemplateId || newVersionId) ? templateData?.templateWithAuthor.template.title : '',
        shortTitle:  (editTemplateId || newVersionId) ? templateData?.templateWithAuthor.template.shortTitle : null,
        commentary:  (editTemplateId || newVersionId) ? templateData?.templateWithAuthor.template.commentary : null,
        expirationDate:  (editTemplateId || newVersionId) ? templateData?.templateWithAuthor.template.expirationDate ? dayjs(templateData.templateWithAuthor.template.expirationDate) : null: null,
        publicationDate:  (editTemplateId || newVersionId) ? templateData?.templateWithAuthor.template.publicationDate ? dayjs(templateData.templateWithAuthor.template.publicationDate) : null: null,
       }}
       validationSchema={editTemplateId ? schemaNoFiles : schemaWithFiles}
       labelCancel={tCommon('buttons.cancel')}
       labelSubmit={t('btn-submit')}
       error={formError}
       setFormError={setFormError}
      >
        <>
          <FieldBlock>
            <DrawerFieldLabel>{t('label-title')}<span>*</span></DrawerFieldLabel>
            <FieldForm
              version="xml"
              name="title"
              placeholder={t('placeholder-title')}
              multiline={true}
            />
          </FieldBlock>
          <FieldBlock>
            <DrawerFieldLabel>{t('label-short-title')}</DrawerFieldLabel>
            <FieldForm
              version="xml"
              name="shortTitle"
              placeholder={t('placeholder-short-title')}
              multiline={true}
            />
          </FieldBlock>
          {!editTemplateId && <FieldTitleBlock>
            <DrawerFieldLabel>
              {t('label-files')}<span>*</span>
              <Tooltip
                title={t('tooltip-files')}
                arrow
              >
                <InfoOutlinedIcon fontSize="medium" sx={{ color: '#D1D8FA' }} />
              </Tooltip>
            </DrawerFieldLabel>
            <ColorDivider size={1} sx={{marginTop: '-10px'}}/>
            <UploadFile
              placeholder={t('btn-files')}
            />
          </FieldTitleBlock>}
          <FieldTitleBlock>
            <DrawerBlockLabel>
              {t('label-dates')}
            </DrawerBlockLabel>
            <ColorDivider size={1} sx={{marginTop: '-10px'}}/>
            <FieldBlock>
              <DrawerFieldLabel>
              <EventNoteIcon/>
                {t('label-publication-date')}
              </DrawerFieldLabel>
              <DatePicker
                name="publicationDate"
                maxDate={dayjs(new Date('2099-12-31'))}
                minDate={dayjs(new Date('1900-01-01'))}
                placeholder={"ДД.ММ.ГГГГ"}
                readOnly={false}
                hasButtonClear
                icon={CalendarTodayIcon}
                popperSx={{
                  "& .MuiPickersDay-root.Mui-selected": {
                    backgroundColor: theme.palette.info.main,
                  }
                }}
              />
            </FieldBlock>
            <FieldBlock>
              <DrawerFieldLabel>
                <EventNoteIcon/>
                {t('label-expiration-date')}
              </DrawerFieldLabel>
              <DatePicker
                name="expirationDate"
                maxDate={dayjs(new Date('2099-12-31'))}
                minDate={dayjs(new Date('1900-01-01'))}
                placeholder={"ДД.ММ.ГГГГ"}
                hasButtonClear
                readOnly={false}
                icon={CalendarTodayIcon}
                popperSx={{
                  "& .MuiPickersDay-root.Mui-selected": {
                    backgroundColor: theme.palette.info.main,
                  }
                }}
              />
            </FieldBlock>
          </FieldTitleBlock>
          <FieldBlock>
            <DrawerFieldLabel>
              {t('label-comment')}
            </DrawerFieldLabel>
            <FieldForm
              version="xml"
              name="commentary"
              placeholder={t('placeholder-comment')}
              multiline={true}
            />
          </FieldBlock>
        </>
      </FormDrawer>
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCancelDialog}
      >
        <ConfirmationDialog
          onCancel={handleCancelDialog}
          onSuccess={handleSuccessDialog}
          title={tCommon("confirmation.exit-form-title")}
          description={tCommon("confirmation.exit-form-text")}
          cancelButtonText="common:buttons.cancellation"
          descriptionStyles={{padding: 0} }
          titleStyles={{color: theme.palette.info.main}}
        />
      </Dialog>
    </>
  );
};