import styled from "styled-components";
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.primary.mainBlue};
  text-transform: unset;
  padding: 0;
  justify-content: flex-start;
  text-decoration: underline;

  &:hover {
    background: none;
  }
`;
