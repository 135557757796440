import { LastActivityCellProps } from "./last-activity-cell.types";
import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
  StyledCell,
  StyledDate,
  StyledTime,
} from "../../administration.styles";
import { formatDate, formatTime } from "../../../../utils/commonUtils";
import { StyledCompanyName } from "./last-activity-cell.styles";

export const LastActivityCell: FC<
  ICellRendererParams<LastActivityCellProps>
> = ({ value }) => {
  const lastActivity = value?.lastActivity;
  const lastActivityDate = formatDate(lastActivity) || "–";
  const lastActivityTime = formatTime(lastActivity) || "–";
  const lastActivityAt = value?.lastActivityAt;

  return (
    <StyledCell>
      <StyledDate>
        {lastActivityDate}
        <StyledTime>{` ${lastActivityTime}`}</StyledTime>
      </StyledDate>

      <StyledCompanyName>{lastActivityAt?.companyName || ""}</StyledCompanyName>
    </StyledCell>
  );
};
