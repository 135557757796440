import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    h6: {
      fontSize: "18px",
      fontWeight: 500,
      letterSpacing: "0.15px",
    },
    h5: {
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "0.15px",
    },
    h4: {
      fontSize: "16px",
      fontWeight: 600,
      letterSpacing: "0.15px",
    },
    h3: {
      fontSize: "20px",
      fontWeight: 800,
      letterSpacing: "0.15px",
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
    },
    body2: {
      fontWeight: 500,
    },
    caption: {
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: "#00358C",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#9AA2B0",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#2B3648",
      secondary: "#5C6E8C",
    },
    success: {
      main: "#2E7D32",
    },
    warning: {
      main: "#6D9ADC",
      contrastText: "#F6F7FB",
    },
    background: {
      paper: "#FFFFFF",
      default: "#EDEFF3",
    },
    error: {
      main: "#F46B6B",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#0044B4"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 38,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 38,
          borderBottom: `2px solid #F6F7FB`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '272px',
          minWidth: 0,
          maxHeight: '300px',
          padding: '10px 8px',
          fontSize: '12px',
          lineHeight: 1.43,
          fontWeight: 400,
          color: '#FFF',
          whiteSpace: 'pre-line',
          background: theme.palette.text.secondary,
          borderRadius: '8px',
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
  },
});
