import styled from "styled-components";
import { disableAgShadows } from "../../../../common/librariesStyles/agGrid";

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${disableAgShadows};

  .ag-header-cell_centred .ag-header-cell-text {
    width: 100%;
  }

  .ag-row_interactive:hover {
    background: ${({ theme }) => theme.primary.blueShades};

    & .link-button {
      background: ${({ theme }) => `${theme.background.blue}14`};
    }
  }
`;
