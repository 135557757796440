import { TrustDocStatusProps } from "./trust-doc-status.types";
import { FC, useMemo } from "react";
import {
  StyledStatus,
  StyledTitle,
  StyledButtonDisconnect,
} from "./trust-doc-status.styles";
import { StyledDescription } from "../../integrations-tab.styles";
import { ReactComponent as CloudCheck } from "../../../../../../components/icons/imported-icons/cloud-check-outline.svg";
import { ReactComponent as CloudCancel } from "../../../../../../components/icons/imported-icons/cloud-cancel-outline.svg";
import { ReactComponent as CloudCog } from "../../../../../../components/icons/imported-icons/cloud-cog-outline.svg";
import { useTranslation } from "react-i18next";

export const TrustDocStatus: FC<TrustDocStatusProps> = ({
  status,
  account,
  onButtonReconnectClick,
}) => {
  const { t } = useTranslation("administration");

  const hasConnection = status === "ok" && !!account?.login;
  const isSetup =
    !status || status === "ok" || status === "missing_credentials";

  const Icon = useMemo(() => {
    if (hasConnection) return CloudCheck;

    return isSetup ? CloudCog : CloudCancel;
  }, [status, hasConnection, isSetup]);

  const title = useMemo(() => {
    if (hasConnection) return t("trustDoc.statusTitles.connected");

    return isSetup
      ? t("trustDoc.statusTitles.setup")
      : t("trustDoc.statusTitles.error");
  }, [status, hasConnection, isSetup]);

  const description = useMemo(() => {
    if (hasConnection) return "";
    if (isSetup) return t(`trustDoc.statusDescription.setup`);

    return t(`trustDoc.statusDescription.${status}`);
  }, [status, hasConnection, isSetup]);

  const titleColorTheme = useMemo(() => {
    if (hasConnection) return "green";

    return isSetup ? "blue" : "red";
  }, [status, hasConnection, isSetup]);

  return (
    <StyledStatus>
      <StyledTitle $color={titleColorTheme}>
        <Icon className="icon" />
        {title}

        {hasConnection && (
          <StyledButtonDisconnect
            variant="contained"
            disableElevation
            disableRipple
            onClick={onButtonReconnectClick}
          >
            {t("trustDoc.disconnect")}
          </StyledButtonDisconnect>
        )}
      </StyledTitle>

      <StyledDescription>{description}</StyledDescription>
    </StyledStatus>
  );
};
