import styled from "styled-components";
import { limitTextRows, textOneRow } from "../../common/styledMixins";

export const StyledWrapper = styled.div`
  display: flex;
  position: relative;
  max-height: 50px;
`;

export const StyledNames = styled.span`
  width: 100%;
  ${limitTextRows(2)};
  position: relative;
`;

export const StyledCount = styled.span`
  padding: 4px 6px;
  border-radius: 24px;
  background: ${({ theme }) => theme.primary.blueShades};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.primary.mainBlue};
  align-items: center;
  justify-content: space-between;
  column-gap: 2px;
  align-self: end;
  min-width: 32px;
  max-width: 50px;
  text-align: center;
  ${textOneRow};
`;
