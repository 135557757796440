import React, { FC, useCallback } from "react";
import { MenuItem } from "@mui/material";
import { ACCESS_TO_ROLES } from "../../../../utils/constants";
import { FieldForm } from "../../../field-form";
import { useTranslation } from "react-i18next";
import { UserRoles } from "../../../../api/company-admin";
import { ModulesAccessProps } from "../../modules-access.types";
import { StyledTile, StyledWrapper } from "./roles-in-modules.styles";

type RolesInModulesProps = {
  name: string;
  title: string;
  handleChangeRole: ModulesAccessProps["changeRole"];
};

export const RolesInModules: FC<RolesInModulesProps> = ({
  handleChangeRole,
  name,
  title,
}) => {
  const { t } = useTranslation("user");

  const onChangeRole = useCallback(
    (event) => {
      const role = event.target.value as UserRoles;
      handleChangeRole({ role, name });
    },
    [handleChangeRole, name]
  );

  return (
    <StyledWrapper
      mb={4}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className="role-in-module"
    >
      <StyledTile variant="h4">{title}</StyledTile>

      <FieldForm
        version="profile"
        name={name}
        select
        onChange={onChangeRole}
        variant="filled"
        sx={{
          width: 200,
          "& div": {
            borderRadius: 2,
            "& .MuiFilledInput-input": {
              padding: "10px 16px",
            },
          },
        }}
      >
        {ACCESS_TO_ROLES.map((rol) => (
          <MenuItem key={rol} value={rol}>
            {t(`roles.${rol}`)}
          </MenuItem>
        ))}
      </FieldForm>
    </StyledWrapper>
  );
};
