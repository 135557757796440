import { StyledRow, StyledTitle } from "./custom-row.styles";
import { CustomRowProps } from "./custom-row.types";
import { FC } from "react";

export const CustomRow: FC<CustomRowProps> = ({ icon, value, title }) => {
  return (
    <StyledRow className="row">
      <StyledTitle className="title">
        {icon}
        {title}
      </StyledTitle>

      {value}
    </StyledRow>
  );
};
