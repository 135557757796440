import React, { useCallback, useEffect, useState } from "react";
import { Button, Dialog, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FolderCard } from "./components/folder-card";
import { StyledList } from "./xml-folders.styles";
import { FormDrawer } from "../../../../components/form-drawer";
import { ConfirmationDialog } from "../../../../components/confirmation-dialogs";
import { FieldForm } from "../../../../components/field-form";
import { useCreateFolderMutation, useGetFoldersQuery } from "../../../../api-xml-constructor/folders";
import { Progress } from "../../../../components/progress";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { isXmlAuthSelector } from "../../../../services/slices/auth-xml-constructor";
import { XMLConstructorLayout } from "../../../../layouts/xml-constuctor-layout";
import { useGetTemplatesCountQuery } from "../../../../api-xml-constructor/templates";

export const XMLFolders = () => {
  const { t } = useTranslation("xml");
  const { t: tCommon } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme()
  const isAuth = useSelector(isXmlAuthSelector);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { data: foldersList, isLoading } = useGetFoldersQuery(undefined, { skip: !isAuth });
  const { data: allTemplatesCount, isLoading: isLoadingAllTemplatesCount } = useGetTemplatesCountQuery(undefined, { skip: !isAuth });
  const [createFolder] = useCreateFolderMutation();

  const drawerHandleOnClose = useCallback(() => {
    setOpenDrawer(false)
  }, []);

  const drawerHandleSubmit = useCallback(async (values) => {
    const res = await createFolder(values);
    if ("error" in res && "data" in res.error) {
      enqueueSnackbar(
        tCommon('errors.request_error'),
        { variant: "error" }
      );
    } else {
      enqueueSnackbar(
        t('folders.create-success'),
        { variant: "success" }
      );
      drawerHandleOnClose()
    }
  }, []);

  const drawerHandleCancel = useCallback((isDirty: boolean) => {
    if (isDirty) {
      setOpenConfirmationDialog(true);
    } else {
      drawerHandleOnClose();
    }
  }, []);

  const drawerHandleClose = useCallback((isDirty: boolean) => {
    if (isDirty) {
      setOpenConfirmationDialog(true);
    } else {
      drawerHandleOnClose();
    }
  }, []);

  const handleSuccessDialog = useCallback(() => {
    drawerHandleOnClose();
    setOpenConfirmationDialog(false);
  }, []);

  const handleCancelDialog = useCallback(() => {
    setOpenConfirmationDialog(false);
  }, []);
  
  return (
    <XMLConstructorLayout>
      <Stack 
        alignItems="flex-start" 
        spacing={1.2}
        gridRow={'2/4'}
        minHeight="100%">
        <Button
          sx={{
            height: "40px",
            padding: '0 16px',
            boxShadow: 'none'
          }}
          color="info"
          variant="contained"
          onClick={() => setOpenDrawer(true)}
        >
          {t('folders.btn-add')}
        </Button>
        {(isLoading || isLoadingAllTemplatesCount)
          ? <Stack flexGrow={1} alignContent="center" width="100%">
            <Progress/>
          </Stack>
          : <StyledList>
            <FolderCard 
              data={{
                folderEntity: {
                  createdAt: '',
                  id: 'all',
                  title: 'Все шаблоны',
                },
                templateCount: allTemplatesCount || 0,
              }} 
              key={0}/>
            {foldersList?.map(folder => <FolderCard data={folder} key={folder.folderEntity.id}/>)}
          </StyledList>
        }
        <FormDrawer
        isOpen={openDrawer}
        handleOnClose={drawerHandleClose}
        handleSubmit={drawerHandleSubmit}
        handleCancel={drawerHandleCancel}
        title={t('folders.drawer-title')}
        initialValues={{title: ''}}
        validationSchema={yup.object({
          title: yup.string().trim().required("common:errors.required"),
        })}
        labelCancel={t('folders.btn-cancel')}
        labelSubmit={t('folders.btn-submit')}
        >
          <FieldForm
            version="xml"
            name="title"
            placeholder={t('folders.drawer-input-placeholder')}
            multiline={true}
          />
        </FormDrawer>
        <Dialog
          open={openConfirmationDialog}
          onClose={handleCancelDialog}
        >
          <ConfirmationDialog
            onCancel={handleCancelDialog}
            onSuccess={handleSuccessDialog}
            title={tCommon("confirmation.exit-form-title")}
            description={tCommon("confirmation.exit-form-text")}
            cancelButtonText="common:buttons.cancellation"
            descriptionStyles={{padding: 0} }
            titleStyles={{color: theme.palette.info.main}}
          />
        </Dialog>
      </Stack>
    </XMLConstructorLayout>
  );
};