import { FC, useRef, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { ManagementMenuPopperLegend } from "./menu-cell.styles";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Button, ClickAwayListener, Fade, Popper, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MenuCellProps } from "./menu-cell.types";

export const MenuCell: FC<
ICellRendererParams<unknown, MenuCellProps>
> = ({ value }) => { 
  const theme = useTheme();
  const { t } = useTranslation('xml', {
    keyPrefix: 'template-table.menu',
  });
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [openManageMenu, setOpenManageMenu] = useState(false);

  return (
    <>
      <Button
        ref={anchorEl}
        variant="outlined"
        color="secondary"
        sx={{ 
          width: 30,
          minWidth: 30,
          border: 'none!important',
          color: theme.palette.text.secondary,
        }}
        onClick={(event) => {
          event.stopPropagation()
          setOpenManageMenu((state) => !state)
        }}
      >
        <MoreVertIcon sx={{
          fontSize: 30
        }} />
      </Button>
      <ClickAwayListener onClickAway={() => setOpenManageMenu(false)}>
        <Popper
          sx={{ zIndex: 1200 }}
          open={openManageMenu}
          anchorEl={anchorEl.current}
          placement='bottom-end'
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={150}>
              <ManagementMenuPopperLegend>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<NoteAddOutlinedIcon />}
                  onClick={() => value?.onCreateClick(value.id)}
                >
                  {t('create')}
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<InsertDriveFileOutlinedIcon />}
                  onClick={() => value?.onViewClick(value.id)}
                >
                  {t('view')}
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={() => value?.onAddVersionClick(value.id)}
                >
                  {t('add-version')}
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={() => value?.onEditClick(value.id)}
                >
                  {t('edit')}
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<DownloadIcon />}
                  onClick={() => value?.onDownloadClick(value.id)}
                >
                  {t('download')}
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={value?.isActual ? <DoDisturbIcon/> : <CheckIcon />}
                  onClick={() => value?.onChangeActualClick(value.id)}
                >
                  {value?.isActual ? t('set-not-actual') : t('set-actual')}
                </Button>
                <Button
                  variant="text"
                  color="error"
                  startIcon={<DeleteOutlineIcon />}
                  onClick={() => value?.onDeleteClick(value.id)}
                >
                  {t('delete')}
                </Button>
              </ManagementMenuPopperLegend>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  )
}
