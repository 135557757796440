import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { isXmlAuthSelector } from '../../services/slices/auth-xml-constructor';
import { useAppContext } from '../app-layout/context';
import { ModuleType, useGetUrlAuthInModuleMutation } from '../../api/companies';
import { useLoginCoreMutation } from '../../api-xml-constructor/auth';
import { useMutationHandlers } from '../../hooks/use-mutation-handlers';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { XMLConstructorLayoutProps } from './xml-constuctor-layout.types';

export const XMLConstructorLayout: FC<XMLConstructorLayoutProps> = ({children, onAuth}) => {
  const { t: tCommon } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompanyId } = useAppContext();
  const isAuth = useSelector(isXmlAuthSelector);
  const [getUrlAuthInModule, getUrlAuthInModuleResponse] = useGetUrlAuthInModuleMutation();
  const [loginCore, loginCoreResponse] = useLoginCoreMutation();

  useEffect(() => {
    if (!isAuth) {
      getUrlAuthInModule({ companyId: currentCompanyId, module: "xml_constructor" as ModuleType });
    }
  }, [isAuth]);

  useMutationHandlers(
    getUrlAuthInModuleResponse,
    (data) => {
      if (data?.url) {
        loginCore({token: data.url});
      } else {
        enqueueSnackbar(tCommon("errors.request_error"), { variant: "error" });
      }
    },
    (error) => {
      enqueueSnackbar(tCommon("errors.request_error"), { variant: "error" });
    }
  );

  useMutationHandlers(
    loginCoreResponse,
    (data) => {
      if (onAuth) onAuth()
    },
    (error) => {
      enqueueSnackbar(tCommon("errors.request_error"), { variant: "error" });
    }
  );
  return (
    <>{children}</>
  )
}
