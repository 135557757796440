export const ROW_HEIGHT = 112;

export const HEADER_HEIGHT = 50;

export const cellsWidth = {
  id: 72,
  default: 448,
  companies: 400,
  created: 200,
  lastActivity: 200,
  unBind: 56,
};

export const commonCellClasses =
  "ag-cell_flex ag-cell_align-items_center ag-cell_justify-content_center";
