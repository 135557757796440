import { Stack } from '@mui/material'
import styled from 'styled-components'

export const DrawerFieldLabel = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: ${(props) => props.theme.secondary.textAdditional};

  & span {
    margin-left: -8px;
    color: ${(props) => props.theme.secondary.error};
  }
  & .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: ${(props) => props.theme.secondary.main};
  }
`
export const DrawerBlockLabel = styled(DrawerFieldLabel)`
  color: ${(props) => props.theme.secondary.text};
`
export const FieldBlock = styled(Stack)`
  gap: 6px;

  .date-picker {
    & .MuiFormControl-root {
      width: 100%;
    }
    & .MuiInputBase-root {
      padding: 0;
      border-radius: 6px;
      background-color: ${(props) => props.theme.primary.blueShades};
      border: 1px solid ${(props) => props.theme.primary.textDark}1F;

      &.MuiInputBase-adornedEnd {
        padding-right: 14px;
      }
      &.Mui-error {
        border-color: ${(props) => props.theme.secondary.error};
      }
    }
    & .MuiInputBase-input {
      padding: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.1;
      text-overflow: ellipsis;
      border-radius: 6px;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: ${(props) => props.theme.primary.placeholder};
        opacity: 1;
      }
    }
    & .MuiInputBase-input:not(.MuiInputBase-multiline) {
      height: 48px;
      padding: 16px;
      box-sizing: border-box;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & .MuiFormHelperText-root {
      font-weight: 400;
    }
    & .MuiIconButton-root {
      width: 20px;
      height: 20px;
      margin: 0;
      
      .MuiSvgIcon-root {
        font-size: 20px;
        color: ${(props) => props.theme.secondary.textAdditional};
      }
    }
    & .MuiOutlinedInput-notchedOutline {
      display: none;
    }
    & .MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background-color: ${(props) => props.theme.primary.mainBlue};
    }
  }
`
export const FieldTitleBlock = styled(Stack)`
  gap: 16px;
  padding-top: 12px;
`