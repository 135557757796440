import { Typography } from '@mui/material'
import styled from 'styled-components'

export const BreadcrumbLink = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.primary.textDark};
  white-space: nowrap;

  &:hover {
    margin-bottom: -1px;
  }
`
