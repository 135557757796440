import { createSlice } from "@reduxjs/toolkit";
import { State } from "./types";
import { RootState } from "../../../store";

const initialState: State = {};

export const {
  actions: { setCurrentCompanyId },
  reducer,
} = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCurrentCompanyId: (state, { payload }) => {
      state.currentCompanyId = payload;
    },
  },
});

export const appSelector = (state: RootState) => state.app;
