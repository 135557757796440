import { CreatedCellProps } from "./created-cell.types";
import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
  StyledDate,
  StyledTime,
  StyledCell,
} from "../../administration.styles";
import { StyledLink } from "./created-cell.styles";
import {
  formatDate,
  formatTime,
  getPersonInitials,
} from "../../../../utils/commonUtils";

export const CreatedCell: FC<ICellRendererParams<CreatedCellProps>> = ({
  value,
}) => {
  const createdAt = value?.createdAt;
  const createdBy = value?.createdBy;

  const createdAtDate = formatDate(createdAt) || "–";
  const createdAtTime = formatTime(createdAt) || "–";

  const userName = createdBy?.mainProfile
    ? `${createdBy.mainProfile.lastName || "–"} ${getPersonInitials({
        firstName: createdBy.mainProfile.firstName,
        middleName: createdBy.mainProfile.middleName,
        divider: ".",
      })}`
    : "–";

  const userLink = `/administration/users/${createdBy?.id}`;

  return (
    <StyledCell>
      <StyledDate>
        {`${createdAtDate} `}
        <StyledTime>{createdAtTime}</StyledTime>
      </StyledDate>

      {createdBy?.mainProfile && (
        <StyledLink to={userLink}>{userName}</StyledLink>
      )}
    </StyledCell>
  );
};
