import { Box } from '@mui/material'
import styled from 'styled-components'

export const EmptyPageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 50px 0;
`
