import styled from "styled-components";
import { styledComponentsTheme } from "../../../../../../core/styledComponentsTheme";
import { Button } from "@mui/material";

export const StyledStatus = styled.div`
  display: grid;
  grid-template-rows: 30px 40px;
  grid-row-gap: 10px;
`;

type TitleColor = "red" | "green" | "blue";

interface TitleProps {
  $color: TitleColor;
}

const getTitleColor = ($color: TitleColor) => {
  switch ($color) {
    case "green":
      return styledComponentsTheme.primary.successMain;
    case "red":
      return styledComponentsTheme.secondary.error;
    case "blue":
      return styledComponentsTheme.secondary.infoMain;
  }
};

export const StyledTitle = styled.div<TitleProps>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ $color }) => getTitleColor($color)};

  .icon {
    fill: ${({ $color }) => getTitleColor($color)};
  }
`;

export const StyledButtonDisconnect = styled(Button)`
  margin-left: auto;
  background: ${({ theme }) => theme.primary.mainBlue};
  border-radius: 6px;
  padding: 3px 9px;
  font-size: 14px;
  font-weight: 400;
  text-transform: unset;

  &:hover {
    background: ${({ theme }) => theme.primary.mainBlue};
  }
`;
