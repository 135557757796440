import { xmlApi } from "../api";
import { Attachement } from "./files.types";

export const xmlTemplatesApi = xmlApi.injectEndpoints({
  endpoints: (build) => ({
    createAttachement: build.mutation<Attachement, FormData>({
      query: (body) => ({
        url: `/files`,
        method: 'POST',
        body,
        formData: true
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useCreateAttachementMutation } = xmlTemplatesApi;
