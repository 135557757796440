import { FC, useCallback, useMemo, useState } from "react";
import { TemplatesTableProps } from "./templates-table.types";
import { AgGridReact } from "ag-grid-react";
import { AgGridNoRowsOverlay } from "../../../../components/ag-grid-no-rows-overlay";
import { StyledAgGridWrapper } from "../../../../common/librariesStyles/agGrid";
import {
  ColDef,
  ColGroupDef,
  RowClassParams,
} from "ag-grid-community";
import { StyledTableWrapper } from "./templates-table.styles";
import { commonCellClasses } from "./templates-table.constants";
import { MenuCell } from "./components/menu-cell";
import { TextCell } from "./components/text-cell";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../../../components/confirmation-dialogs";
import { Dialog, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthorCell } from "./components/author-cell";
import { TitleCell } from "./components/title-cell";
import { ArrowCell } from "./components/arrow-cell";
import { useSearchParams } from "react-router-dom";
import { useDeleteTemplateByIdMutation, useFetchTemplateByIdMutation, useEditTemplateByIdMutation } from "../../../../api-xml-constructor/templates";
import { CreateTemplateRequest } from "../../../../api-xml-constructor/templates/templates.types";
import dayjs from "dayjs";

export const TemplatesTable: FC<TemplatesTableProps> = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("xml", {
    keyPrefix: 'template-table',
  });
  const { t: tForm } = useTranslation("xml", {
    keyPrefix: 'template-form',
  });
  const { t: tCommon } = useTranslation("common");
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const [deletedId, setDeletedId] = useState<string>();
  const [deleteTemplate] = useDeleteTemplateByIdMutation();
  const [hiddenRowsIds, setHiddenRowsIds] = useState<Set<string>>(new Set());
  const [currentVisibleId, setCurrentVisibleId] = useState<string|null>(null);
  const [fetchTemplateData] = useFetchTemplateByIdMutation();
  const [editTemplate] = useEditTemplateByIdMutation();
  
  const handleButtonHideClick = useCallback(
    (typeId: string, id: string) => {
      const newState = new Set(hiddenRowsIds);
      let visibleId = currentVisibleId;
      if (newState.has(typeId)) {
        newState.delete(typeId);
        visibleId = null;
      } else {
        newState.add(typeId);
        visibleId = id;
      }
      setHiddenRowsIds(newState);
      setCurrentVisibleId(visibleId);
    },
    [hiddenRowsIds, currentVisibleId]
  );
  const handleAddVersionClick = (id: string) => {
    setSearchParams((_params) => {
      const params = new URLSearchParams(window.location.search);
      params.set('newVersion', id);
      return params
    });
  };
  const handleViewClick = useCallback(
    (id: string) => {
      setSearchParams((_params) => {
        const params = new URLSearchParams(window.location.search);
        params.set('view', id);
        return params
      });
    }, 
    [setSearchParams, searchParams]
  );
  const handleEditClick = (id: string) => {
    setSearchParams((_params) => {
      const params = new URLSearchParams(window.location.search);
      params.set('edit', id);
      return params
    });
  };
  const handleCreateClick = useCallback(
    (id: string) => {
      console.log(id)
    }, 
    []
  );
  const handleChangeActualClick = useCallback(
    async (id: string) => {
      const templateDataRes = await fetchTemplateData(id);
      if ("error" in templateDataRes || !templateDataRes.data) {
        enqueueSnackbar(
          tCommon('errors.request_error'),
          { variant: "error" }
        );
      } else {
        const { data: templateData } = templateDataRes;
        const { templateWithAuthor: {template}, xsdFilesIds } = templateData;
        const body = {
          folderId: template.folderId,
          isActive: !template.isActive,
          title: template.title,
          typeId: template.typeId,
          xsdFileIds: xsdFilesIds, 
          xslFileId: template.xslFileId,
          publicationDate: template.publicationDate,
          expirationDate: template.expirationDate,
          shortTitle: template.shortTitle,
          commentary: template.commentary
        }
        const res = await editTemplate({templateId: id, body: body as CreateTemplateRequest});
        if ("error" in res) {
          enqueueSnackbar(
            tCommon('errors.request_error'),
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            tForm('success.edit'), 
            { variant: "success" }
          );
        }
      }
    }, 
    [fetchTemplateData, enqueueSnackbar, t, tCommon]
  );
  const handleDownloadClick = useCallback(
    (id: string) => {
      console.log(id)
    }, 
    []
  );
  const handleDelete = useCallback(
    async () => {
      if (!deletedId) return;
      const res = await deleteTemplate(deletedId);
      if ("error" in res) {
        enqueueSnackbar(
          tCommon('errors.request_error'),
          { variant: "error" }
        );
      } else {
        enqueueSnackbar(tForm("delete.success"), {
          variant: "success",
        });
      }
      setDeletedId(undefined);
    }, 
    [enqueueSnackbar, deletedId, deleteTemplate]
  );

  const getRowClass = useCallback((event: RowClassParams) => {
    let classes = "";
    if (event.data?.arrow.arrow === false) {
      classes = "ag-row-white";
    }
    return classes;
  }, []);

  const columnDefs: (ColGroupDef | ColDef)[] = [
    {
      headerName: "",
      minWidth: 60,
      width: 60,
      field: "arrow",
      cellRenderer: ArrowCell,
      cellClass: `${commonCellClasses}`,
      resizable: false
    },
    {
      headerName: "Полное наименование",
      minWidth: 330,
      flex: 1,
      field: "full_name",
      cellRenderer: TitleCell,
      cellClass: `${commonCellClasses}`,
      resizable: false
    },
    {
      headerName: "Краткое наименование",
      minWidth: 200,
      flex: 1,
      field: "short_name",
      cellRenderer: TextCell,
      cellClass: `${commonCellClasses}`,
      resizable: false
    },
    {
      headerName: "Сроки действия",
      cellClass: `${commonCellClasses} ag-cell_justify-content_center`,
      children: [
        {
          headerName: "Публикация",
          minWidth: 165,
          width: 165,
          field: "publish_date",
          cellRenderer: TextCell,
          cellClass: `${commonCellClasses} ag-cell_justify-content_center`,
          resizable: false
        },
        {
          headerName: "Введения в действие",
          minWidth: 165,
          width: 165,
          field: "start_date",
          cellRenderer: TextCell,
          cellClass: `${commonCellClasses} ag-cell_justify-content_center`,
          resizable: false
        },
      ]
    },
    {
      headerName: "Комментарий",
      minWidth: 200,
      flex: 1,
      field: "comment",
      cellRenderer: TextCell,
      cellClass: `${commonCellClasses}`,
      resizable: false
    },
    {
      headerName: "Версия",
      minWidth: 90,
      maxWidth: 130,
      field: "version",
      cellRenderer: TextCell,
      cellClass: `${commonCellClasses} ag-cell_justify-content_center`,
      resizable: false
    },
    {
      headerName: "Автор",
      minWidth: 159,
      width: 159,
      field: "author",
      cellRenderer: AuthorCell,
      cellClass: `${commonCellClasses} ag-cell_justify-content_center`,
      resizable: false
    },
    {
      headerName: "",
      width: 60,
      minWidth: 60,
      field: "menu",
      cellRenderer: MenuCell,
      cellClass: `${commonCellClasses}`,
      resizable: false
    },
  ];
  const rows = useMemo(() => {
    return data
      .filter(value => {
        // Показываем строку, если её typeId не скрыт, или если это исключение (currentVisibleId)
        return !hiddenRowsIds.has(value.template.typeId) || value.template.id === currentVisibleId;
      })
      .map(value => ({
        arrow: {
          arrow: value.hasGroup,
          id: value.template.id,
          typeId: value.template.typeId,
          onArrowClick: handleButtonHideClick,
          open: hiddenRowsIds.has(value.template.typeId)
        },
        full_name: {
          title: value.template.title,
          isActual: value.template.isActive
        },
        short_name: value.template.shortTitle,
        publish_date: value.template.publicationDate ? dayjs(value.template.publicationDate).format('DD.MM.YYYY') : '',
        start_date: value.template.expirationDate ? dayjs(value.template.expirationDate).format('DD.MM.YYYY') : '',
        comment: value.template.commentary || '',
        version: value.template.version || 1,
        author: {
          author: {
            firstName: value.author.firstName,
            middleName: value.author.middleName,
            lastName: value.author.lastName
          },
          createdAt: value.template.createdAt
        },
        menu: {
          id: value.template.id,
          isActual: value.template.isActive === undefined ? true : value.template.isActive,
          onCreateClick: handleCreateClick,
          onViewClick: handleViewClick,
          onAddVersionClick: handleAddVersionClick,
          onEditClick: handleEditClick,
          onDownloadClick: handleDownloadClick,
          onChangeActualClick: handleChangeActualClick,
          onDeleteClick: (id: string) => setDeletedId(id)
        }
      })
    )
  }, [data, hiddenRowsIds, currentVisibleId]);

  return (
    <StyledTableWrapper>
      <StyledAgGridWrapper>
        <div className="ag-theme-alpine">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rows}
            noRowsOverlayComponent={AgGridNoRowsOverlay}
            headerHeight={40}
            rowHeight={80}
            getRowClass={getRowClass}
            suppressMovableColumns
            defaultColDef={{
              editable: false,
              sortable: false,
              filter: false,
              resizable: true,
            }}
          />
        </div>
      </StyledAgGridWrapper>
      <Dialog
        open={!!deletedId}
        onClose={() => setDeletedId(undefined)}
      >
        <ConfirmationDialog
          onCancel={() => setDeletedId(undefined)}
          onSuccess={handleDelete}
          title={t("delete.title")}
          description={t("delete.text")}
          cancelButtonText="common:buttons.cancel"
          confirmButtonText="common:buttons.delete"
          confirmBtnColor="error"
          theme="error"
          descriptionStyles={{padding: '0 50px'} }
          titleStyles={{color: `${theme.palette.info.main}!important`}}
        />
      </Dialog>
    </StyledTableWrapper>
  );
};
