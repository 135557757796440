import { useMatch, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import React, { useCallback, useMemo, useState } from "react";
import { debounce, InputAdornment, Stack, TextField, useTheme } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { useGlobalSearch } from "../../utils/useGlobalSearch";
import { Breadcrumbs } from "../breadcrumbs";

export const PageTitle = () => {
  const { t } = useTranslation("pageTitles");
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const administrationTab = searchParams.get("tab") || "";
  const tabMatch = useMatch("/:page/:id/:tab");
  const { page, tab } = tabMatch?.params || { tab: "" };
  const userMatch = useMatch("/companies/:id/:tab/:userId");
  const xmlConstructorMatch = useMatch("/administration/xml/:id");
  const adminMatch = useMatch("/administration");
  const adminUserMatch = useMatch("/administration/users/:id");

  const userId = userMatch?.params?.userId;
  const location = useLocation();

  const [searchParam, setSearchParam] = useGlobalSearch();

  const [search, setSearch] = useState(searchParam);

  const handleChangeSearchParam = useCallback(
    debounce((value: string) => {
      setSearchParam(value);
    }, 300),
    [setSearchParam]
  );

  const handleChangeSearch = useCallback(
    (e) => {
      const value = e?.target?.value;
      setSearch(value);

      handleChangeSearchParam(value);
    },
    [setSearchParam]
  );

  const title = useMemo(() => {
    if (location.pathname.includes("counterparties/create"))
      return t("counterparties.create");
    if (/counterparties\/\d+/g.test(location.pathname))
      return t("counterparties.edit");
    if (/counterparties/g.test(location.pathname))
      return t("counterparties.index");

    if (/\/\d+\/administration$/.test(location.pathname)) {
      return t("administration.index");
    }

    if (location.pathname.includes("projects/create"))
      return t("projects.create");
    if (/projects\/\d+/g.test(location.pathname)) return t("projects.edit");
    if (/projects/g.test(location.pathname)) return t("projects.index");

    if (location.pathname.includes("assignments/create"))
      return t("assignments.create");
    if (/assignments\/\d+/g.test(location.pathname))
      return t("assignments.edit");
    if (location.pathname.includes("assignments"))
      return t("assignments.index");

    if (adminMatch || adminUserMatch) {
      if (administrationTab === 'xml') {
        return t("administration.xml");
      }
      if (xmlConstructorMatch) {
        return <Breadcrumbs/>
      }
      return adminUserMatch ? t("companies.user") : t("companies.users");
    }

    return t(
      [
        page,
        tab,
        userId
          ? Number(userId)
            ? "companies.user"
            : "companies.create"
          : undefined,
      ]
        .filter(Boolean)
        .join("."),
      {
        defaultValue: "",
      }
    );
  }, [page, tab, t, userId, userMatch, administrationTab, adminUserMatch]);

  if (!title) {
    return null;
  }

  return (
    <Stack
      flex={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {title}
      {(["users", "counterparties", "projects"].includes(tab || "") || (adminMatch && searchParams.get('tab') !== 'xml')) && (
        <TextField
          value={search}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "6px",
            overflow: "hidden",
            maxWidth: 160,
            marginRight: 2,
            
            "& .MuiFilledInput-input": {
              padding: "10px 16px",
              borderRadius: "6px",
            },
            "& fieldset": {
              border: "unset",
            },
            "& .MuiFilledInput-root": {
              paddingLeft: 0,
            },
            ...(xmlConstructorMatch ? {
              maxWidth: 'fit-content',

             "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderStyle: 'solid',
                borderColor: theme.palette.info.main,
                borderWidth: 1
              },
              "& .MuiInputBase-root": {
                maxWidth: 160,
              },
              "& .MuiInputBase-root.Mui-focused": {
                maxWidth: 263,
              },
            } : {})
          }}
          fullWidth
          size="small"
          placeholder={t("common:buttons.search")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={handleChangeSearch}
        />
      )}
    </Stack>
  );
};
