import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  CSSProperties,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useBreadcrumbsContext } from '../../layouts/layout/context' 

import { BreadcrumbLink, BreadcrumbsItem } from '.'
import { Link } from 'react-router-dom'

const OverflowTip = ({
  title,
  tStyles,
  setIsOverflow,
}: {
  title: string
  tStyles?: CSSProperties
  setIsOverflow: (val: boolean) => void
}) => {
  const textElementRef = useRef<HTMLSpanElement | null>(null)

  const checkOverflow = () => {
    if (textElementRef.current) {
      // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
      const clientWidth = textElementRef.current.getBoundingClientRect().width

      textElementRef.current.style.overflow = 'visible'
      const contentWidth = textElementRef.current.getBoundingClientRect().width
      textElementRef.current.style.overflow = 'hidden'

      setIsOverflow(contentWidth > clientWidth)
    }
  }

  useEffect(() => {
    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [])

  return (
    <BreadcrumbLink
      component="span"
      ref={textElementRef}
      style={{
        ...tStyles,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {title}
    </BreadcrumbLink>
  )
}

export const Breadcrumbs: FC = (): ReactElement | null => {
  const { breadcrumbs } = useBreadcrumbsContext();
  const [overflowStates, setOverflowStates] = useState<boolean[]>(
    new Array(breadcrumbs.length).fill(false),
  )

  const setIsOverflow = useCallback((isOverflowed: boolean, index: number) => {
    setOverflowStates((prevStates) => {
      const newStates = [...prevStates]
      newStates[index] = isOverflowed
      return newStates
    })
  }, [])


  const getItem = useCallback(
    (item: BreadcrumbsItem, isLast: boolean, index: number) => {
      const key = item.title + item.url

      let element: React.ReactNode
      switch (item.type) {
        case 'project': {
          element = (
            <Stack
              key={key}
              direction="row"
              alignItems="center"
              onClick={item?.onClick}
              style={{
                maxWidth: isLast ? 600 : 450,
                cursor: 'pointer',
              }}
            >
              <OverflowTip
                title={item.title}
                setIsOverflow={(isOverflowed) =>
                  setIsOverflow(isOverflowed, index)
                }
              />
              {item?.MenuOpen ? <ExpandLess /> : <ExpandMore />}
            </Stack>
          )
          break
        }

        default: {
          if (isLast || item.url == null) {
            element = (
              <Stack
                key={key}
                direction="row"
                alignItems="center"
                style={{ maxWidth: 600 }}
              >
                <OverflowTip
                title={item.title}
                setIsOverflow={(isOverflowed) =>
                  setIsOverflow(isOverflowed, index)
                }
              />
              </Stack>
            )
            break
          }

          element = (
            <Link
              to={[item.url, item.query].filter(Boolean).join('?')}
              key={key}
              style={{
                maxWidth: 200,
              }}
            >
              <Stack direction="row" alignItems="center">
                <OverflowTip
                  title={item.title}
                  setIsOverflow={(isOverflowed) =>
                    setIsOverflow(isOverflowed, index)
                  }
                />
              </Stack>
            </Link>
          )
          break
        }
      }

      return element
    },
    [],
  )

  return (
    <Box sx={{
      minWidth: 250, 
      maxWidth: '100%',

      '& .MuiBreadcrumbs-ol': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexWrap: 'nowrap',
      },
      '& li.MuiBreadcrumbs-li': {
        minWidth: '20px',
        textAlign: 'center',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&:first-of-type': {
          flexShrink: 0
        },
        '& .MuiStack-root': {
          justifyContent: 'center',
        },
      },
    }}>
      <Tooltip
        arrow
        title={breadcrumbs.map((item) => item.title).join(` / `)}
        disableHoverListener={!overflowStates.some((state) => state)}
      >
        <MuiBreadcrumbs>
        {breadcrumbs.map((item, i) => {
          const isLast = breadcrumbs.length === i + 1
          return getItem(item, isLast, i)
        })}
      </MuiBreadcrumbs>
      </Tooltip>
      
    </Box>
  )
}
