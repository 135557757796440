import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
import { Form, FormikProvider } from "formik";
import { Button, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import { useAppContext } from "../../layouts/app-layout/context";
import { useForm } from "../../hooks/use-form";
import {
  DEFAULT_DISPLAY_PASSWORD_VALUE,
  MAX_WIDTH_PROFILE_BLOCK,
} from "../../utils/constants";
import { generateRandomString } from "../../utils/generate-random-string";
import {
  useUpdateAccessProfileByCompanyMutation,
  useUpdateContactsProfileByCompanyMutation,
  useUpdateAvatarProfileByCompanyMutation,
  useUpdateAccountProfileByCompanyMutation,
  useUpdateAccessAdminProfileByCompanyMutation,
  useGetProfileByCompanyAdminQuery,
  useCreateFullProfileByCompanyMutation,
  useDeleteProfileByCompanyMutation,
  CreateCompanyUserResponse,
  useInviteFullUserMutation,
  useGetUserFiltersQuery,
  Candidate,
} from "../../api/company-admin";
import { useGetCompanyAdminInfoQuery } from "../../api/companies";
import { useGetCompaniesAccessQuery } from "../../api/counterparty";
import { disabledContactsFieldsValidation, validationUser } from "./validation";
import { UserAccountForm } from "../../components/user-account-form";
import { UserContactsForm } from "./components/user-contacts-form";
import { UserButtons } from "../../components/user-buttons";
import { ScrollableContainer } from "../../components/scrollable-container";
import { Access, ProfileByCompany } from "../../api/profile";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import { Progress } from "../../components/progress";
import { FoundUserDialog } from "../../components/found-user-dialog";
import { mapFieldErrorByError } from "../../utils/mapFieldErrorByError";
import { useSnackbar } from "notistack";
import { DeleteConfirmDialog } from "./components/delete-confirm-dialog";
import { initContextFiltersData, UserContext } from "./context";
import { RouterState } from "./user.types";
import { ModulesAccess } from "../../components/modules-access";
import {
  compareFormValuesAndAccess,
  getCounterpartyIDByPath,
  mergeFormValuesAndAccess,
} from "./user.service";
import { FormikValues } from "formik/dist/types";

export const User = () => {
  const { t } = useTranslation("user");
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const userMatch = useMatch("/companies/:id/:tab/:userId");
  const userIdMatch = userMatch?.params?.userId;
  const { currentCompanyId, isAdmin, mainCompany, availableModules } =
    useAppContext();
  const isPageCreate = userIdMatch === "create";

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const { data: profileByCompany, isLoading } =
    useGetProfileByCompanyAdminQuery(
      {
        companyId: currentCompanyId,
        userId: Number(userIdMatch),
      },
      { skip: !Number(userIdMatch) || !Number(currentCompanyId) }
    );

  const counterparties = useGetCompaniesAccessQuery(
    {
      companyID: currentCompanyId,
    },
    { skip: !currentCompanyId }
  );
  const counterpartiesList = counterparties.data?.data || [];

  const [candidates, setCandidates] =
    useState<CreateCompanyUserResponse["candidates"]>();

  const mainCompanyInfo = useGetCompanyAdminInfoQuery(
    { companyID: currentCompanyId },
    { skip: !currentCompanyId }
  );

  const {
    coreID,
    lastName,
    firstName,
    middleName,
    avatar,
    companyName,
    company,
    division,
    divisionHead,
    position,
    phone,
    email,
    emailConfirmed,
    phoneConfirmed,
    login,
    access,
    main,
  } = profileByCompany || ({} as ProfileByCompany);

  const {
    design,
    quality_control,
    dashboard,
    finance,
    procurement,
    analytics,
    crm,
    equip,
    offer,
    supply,
    tender,
    tracker,
    workforce,
    resource_managment,
    admin,
    maintenance,
    asbuilt_doc,
    trustDoc,
  } = access || ({} as Access);

  const [updateAccountProfileByCompany] =
    useUpdateAccountProfileByCompanyMutation();
  const [
    updateContactsProfileByCompany,
    updateContactsProfileByCompanyResponse,
  ] = useUpdateContactsProfileByCompanyMutation();
  const [updateAccessProfileByCompany] =
    useUpdateAccessProfileByCompanyMutation();
  const [updateAccessAdminProfileByCompany] =
    useUpdateAccessAdminProfileByCompanyMutation();
  const [updateAvatarProfileByCompany] =
    useUpdateAvatarProfileByCompanyMutation();
  const [createFullProfileByCompany, createFullProfileByCompanyResponse] =
    useCreateFullProfileByCompanyMutation();
  const [deleteProfileByCompany, deleteProfileByCompanyResponse] =
    useDeleteProfileByCompanyMutation();
  const [inviteUser, inviteUserResponse] = useInviteFullUserMutation();

  const handleDeleteProfileByCompany = useCallback(
    async (isConfirmed?: boolean) => {
      if (isConfirmed) {
        setIsDeleteDialogOpen(false);
        deleteProfileByCompany({
          companyId: currentCompanyId,
          userId: coreID,
        });
      }

      setIsDeleteDialogOpen(false);
    },
    [deleteProfileByCompany, coreID, currentCompanyId]
  );
  const fromCounterpartyStaff =
    (location.state as RouterState)?.from.includes("counterparties") ||
    (location.state as RouterState)?.from.includes("administration");

  const routerValues = (location.state as RouterState)?.userValues;
  const fromCounterpartyID = useMemo(
    () => getCounterpartyIDByPath((location.state as RouterState)?.from),
    [location.state]
  );
  const initialValues: FormikValues = useMemo(() => {
    return {
      lastName: routerValues?.lastName || lastName || "",
      firstName: routerValues?.firstName || firstName || "",
      middleName: routerValues?.middleName || middleName || "",
      companyName: routerValues?.companyName || company?.name || "",
      divisionName: routerValues?.divisionName || division || "",
      divisionHead: divisionHead || routerValues?.divisionHead || false,
      position: routerValues?.position || position || "",
      phone: routerValues?.phone || phone || "",
      email: routerValues?.email || email || "",
      login: routerValues?.login || login || "",
      password: isPageCreate ? "" : DEFAULT_DISPLAY_PASSWORD_VALUE,
      avatar: avatar || "",
      admin: routerValues?.admin || admin || false,
      trustDoc,
      dashboard: routerValues?.dashboard || dashboard || "none",
      design: routerValues?.design || design || "none",
      quality_control:
        routerValues?.quality_control || quality_control || "none",
      finance: routerValues?.finance || finance || "none",
      procurement: routerValues?.procurement || procurement || "none",
      offer: routerValues?.offer || offer || "none",
      resource_managment:
        routerValues?.resource_managment || resource_managment || "none",
      maintenance: routerValues?.maintenance || maintenance || "none",
      asbuilt_doc: routerValues?.asbuilt_doc || asbuilt_doc || "none",
    };
  }, [
    lastName,
    firstName,
    middleName,
    companyName,
    position,
    avatar,
    phone,
    email,
    login,
    design,
    quality_control,
    dashboard,
    finance,
    procurement,
    analytics,
    crm,
    equip,
    offer,
    supply,
    tender,
    tracker,
    workforce,
    resource_managment,
    admin,
    trustDoc,
    isPageCreate,
    maintenance,
    division,
    divisionHead,
    company?.name,
    asbuilt_doc,
  ]);

  const handleSubmit = useCallback(
    async (values) => {
      const {
        lastName,
        firstName,
        middleName,
        companyName,
        divisionName,
        divisionHead,
        avatar,
        position,
        phone,
        email,
        login,
        design,
        quality_control,
        dashboard,
        finance,
        procurement,
        analytics,
        crm,
        equip,
        offer,
        supply,
        tender,
        tracker,
        workforce,
        resource_managment,
        admin,
        trustDoc,
        owner,
        password,
        maintenance,
        asbuilt_doc,
      } = values;
      const counterparty = (
        counterparties?.data
          ? [counterparties.data.mainCompany, ...counterparties.data.data]
          : []
      ).find((counterparty) => counterparty.name === companyName);
      const hasSyncWithGlobal = compareFormValuesAndAccess(
        values,
        counterparty?.access
      );

      if (isPageCreate) {

        createFullProfileByCompany({
          employment: {
            companyID: currentCompanyId,
            ...(counterparty?.id && {
              company: { counterpartyID: counterparty.id },
            }),
            ...(companyName === mainCompany?.name && {
              company: { counterpartyID: null },
            }),
            division: divisionName,
            divisionHead,
            firstName,
            lastName,
            middleName,
            position,
          },
          profile: {
            email,
            login,
            password,
            phone,
          },
          access: {
            design,
            quality_control,
            dashboard,
            finance,
            procurement,
            analytics,
            crm,
            equip,
            offer,
            supply,
            tender,
            tracker,
            workforce,
            resource_managment,
            admin,
            trustDoc,
            owner,
            maintenance,
            asbuilt_doc,
          },
          avatar: {
            companyId: currentCompanyId,
            file: values.avatar,
          },
          ...(hasSyncWithGlobal && { syncWithGlobal: true }),
        });
      } else {
        setIsUpdateLoading(true);
        try {
          await Promise.all([
            ...(avatar instanceof Blob
              ? [
                  updateAvatarProfileByCompany({
                    companyId: currentCompanyId,
                    userId: coreID,
                    file: values.avatar,
                  }),
                ]
              : []),

            updateAccountProfileByCompany({
              companyId: currentCompanyId,
              company: { counterpartyID: counterparty?.id || company?.id || 0 },
              ...(companyName === mainCompany?.name && {
                company: { counterpartyID: null },
              }),
              division: divisionName,
              divisionHead,
              userId: coreID,
              companyName,
              firstName,
              lastName,
              middleName,
              position,
            }),

            updateContactsProfileByCompany({
              companyId: currentCompanyId,
              userId: coreID,
              email,
              phone,
              login,
            }),

            updateAccessProfileByCompany({
              admin,
              trustDoc,
              companyId: currentCompanyId,
              userId: coreID,
              design,
              quality_control,
              dashboard,
              finance,
              procurement,
              analytics,
              crm,
              equip,
              offer,
              supply,
              tender,
              tracker,
              workforce,
              resource_managment,
              maintenance,
              asbuilt_doc,
              ...(hasSyncWithGlobal && { syncWithGlobal: true }),
            }),
          ]);

          enqueueSnackbar(t("userUpdated"), { variant: "success" });
          setIsUpdateLoading(false);
        } catch {
          setIsUpdateLoading(false);
        }
      }
    },
    [
      currentCompanyId,
      coreID,
      updateAccountProfileByCompany,
      updateContactsProfileByCompany,
      updateAccessProfileByCompany,
      updateAccessAdminProfileByCompany,
      updateAvatarProfileByCompany,
      createFullProfileByCompany,
      isPageCreate,
      counterpartiesList,
      initialValues,
      mainCompany,
      mainCompanyInfo.data,
      counterparties,
      trustDoc,
    ]
  );

  const isDisabledContactsFields = !main && !isPageCreate && isAdmin;

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: isDisabledContactsFields
      ? disabledContactsFieldsValidation
      : validationUser,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      handleSubmit({ ...values });
    },
  });

  const { values, handleReset, setFieldValue, setFieldError, setValues } =
    formik;

  const currentCounterparty = useMemo(
    () =>
      [mainCompanyInfo.data, ...counterpartiesList].find(
        (counterparty) => counterparty?.name === values.companyName
      ),
    [counterpartiesList, values.companyName, mainCompanyInfo.data]
  );

  const { data: userFilters } = useGetUserFiltersQuery(
    {
      companyId: currentCompanyId,
      ...(currentCounterparty?.id !== currentCompanyId &&
        currentCounterparty?.id && {
          counterpartyIDs: [currentCounterparty.id],
        }),
      ...(currentCounterparty?.id === currentCompanyId && { ownCompany: true }),
    },
    { skip: !currentCompanyId }
  );

  useMutationHandlers(
    createFullProfileByCompanyResponse,
    (data) => {
      if (!!data) {
        const { newUser, candidates } = data as CreateCompanyUserResponse;
        if (!!newUser) {
          fromCounterpartyStaff ? navigate(-1) : navigate("../../users");
        }
        if (!!candidates?.length) {
          const notInvitedCandidates: Candidate[] = [];
          candidates.forEach((candidate) => {
            const { alreadyInvited, bindFields } = candidate;
            if (alreadyInvited) {
              bindFields?.forEach((field: "email" | "login" | "phone") =>
                setFieldError(field, t("status.coincidence"))
              );
            } else {
              notInvitedCandidates.push(candidate);
            }
          });

          setCandidates(notInvitedCandidates);
        }
      }
    },
    (error) => {
      const errorData = mapFieldErrorByError(error);
      if (errorData) {
        const { field, text, type } = errorData;
        if (type === "phone") {
          setFieldError(field, t(text));
        }
      } else {
        enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
      }
    }
  );

  useMutationHandlers(
    updateContactsProfileByCompanyResponse,
    (data) => {
      navigate(-1);
    },
    (error) => {
      const errorData = mapFieldErrorByError(error);
      if (errorData) {
        const { field, text } = errorData;
        setFieldError(field, t(text));
      }
    }
  );
  useMutationHandlers(
    deleteProfileByCompanyResponse,
    () => {
      navigate("../../users");
      enqueueSnackbar(t("success.profileDelete"), { variant: "success" });
    },
    (error) => {
      enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
    }
  );

  useMutationHandlers(
    inviteUserResponse,
    () => {
      navigate("../../users");
      enqueueSnackbar(t("success.acceptInvitation"), { variant: "success" });
    },
    (error) => {
      enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
    }
  );

  useEffect(() => {
    if (!isPageCreate) return;

    if (
      currentCounterparty?.access &&
      initialValues.companyName !== values.companyName
    ) {
      const mergedValues = mergeFormValuesAndAccess(
        values,
        currentCounterparty.access
      );

      setValues(mergedValues);
    }
  }, [currentCounterparty]);

  const isVisibleButtons = useMemo(
    () =>
      !!values &&
      Object.keys(values).find(
        (key) =>
          (values[key as keyof typeof initialValues] || "") !==
          (initialValues[key as keyof typeof initialValues] || "")
      ),
    [values, initialValues]
  );

  const setLoginGeneration = useCallback(() => {
    setFieldValue("login", generateRandomString({}));
  }, [setFieldValue]);

  const setPasswordGeneration = useCallback(() => {
    setFieldValue(
      "password",
      generateRandomString({
        length: 8,
        lower: true,
        symbols: true,
        numbers: true,
        upper: true,
      })
    );
  }, [setFieldValue]);

  const setFieldRole = useCallback(
    ({ name, role }) => {
      setFieldValue(name, role);
    },
    [setFieldValue]
  );

  const handleCloseFoundUserDialog = useCallback(() => {
    setCandidates(undefined);
  }, []);

  const handleInviteUser = useCallback(
    (candidateId) => {
      const selectedCandidate = candidates?.find(
        (candidate) => candidate?.profile?.coreID === Number(candidateId)
      );
      const getFieldBySelectedCandidate = (
        bindField: "login" | "email" | "phone"
      ) => {
        if (selectedCandidate?.bindFields?.includes(bindField)) {
          return values[bindField] || "";
        }
        return "";
      };

      inviteUser({
        companyId: currentCompanyId,
        userId: candidateId,
        employment: {
          companyID: currentCompanyId,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          position: values.position,
        },
        profile: {
          email: getFieldBySelectedCandidate("email"),
          login: getFieldBySelectedCandidate("login"),
          phone: getFieldBySelectedCandidate("phone"),
          password: values.password,
        },
        access: {
          dashboard: values.dashboard,
          procurement: values.procurement,
          design: values.design,
          quality_control: values.quality_control,
          finance: values.finance,
          analytics: values.analytics,
          crm: values.crm,
          equip: values.equip,
          offer: values.offer,
          supply: values.supply,
          tender: values.tender,
          tracker: values.tracker,
          workforce: values.workforce,
          resource_managment: values.resource_managment,
          admin: values.admin,
          trustDoc: values.trustDoc,
        },
        avatar:
          (values.avatar as string | Blob) instanceof Blob
            ? {
                companyId: currentCompanyId,
                file: values.avatar as unknown as Blob,
              }
            : undefined,
      });
    },
    [inviteUser, values, candidates, currentCompanyId, userFilters]
  );

  const handleButtonBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const isShowConfirmPhone =
    !!values.phone && values.phone === initialValues.phone;
  const isShowConfirmEmail =
    !!values.email && values.email === initialValues.email;
  const filtersData = userFilters || initContextFiltersData;

  return (
    <UserContext.Provider
      value={{
        userValues: values,
        filtersData,
        counterparties: counterpartiesList,
        fromCounterpartyID,
      }}
    >
      <FormikProvider value={formik}>
        <Stack flex={1} component={Form}>
          <Stack
            px={5}
            py={3.75}
            direction="row"
            justifyContent="space-between"
          >
            <Button
              onClick={handleButtonBackClick}
              sx={{
                "&": {
                  color: "#2B3648",
                },
              }}
              startIcon={<ArrowBackIcon />}
            >
              <Typography variant="body2">
                {t("common:buttons.back")}
              </Typography>
            </Button>
            {!isPageCreate && (
              <Button
                color="error"
                sx={{
                  minWidth: "40px",
                  minHeight: "40px",
                  padding: 0,
                  boxShadow: "none",
                  "&:hover": {
                    background: "#f46b6b",
                    boxShadow: "none",
                  },
                }}
                variant="contained"
                onClick={() => setIsDeleteDialogOpen(true)}
              >
                <DeleteSharpIcon />
              </Button>
            )}
          </Stack>
          {isLoading || deleteProfileByCompanyResponse?.isLoading ? (
            <Progress />
          ) : (
            <ScrollableContainer>
              <Stack direction="row" margin="0 auto" height="min-content">
                <Stack
                  minWidth={MAX_WIDTH_PROFILE_BLOCK}
                  flex={1}
                  px={7.5}
                  pb={7.5}
                >
                  <Stack>
                    <UserAccountForm
                      counterparties={counterpartiesList}
                      userValues={values}
                      divisions={filtersData.divisions}
                    />
                    <UserContactsForm
                      isShowConfirmPhone={isShowConfirmPhone}
                      isShowConfirmEmail={isShowConfirmEmail}
                      isPhoneConfirmed={phoneConfirmed}
                      isEmailConfirmed={emailConfirmed}
                      handleLoginGeneration={setLoginGeneration}
                      handlePasswordGeneration={setPasswordGeneration}
                      isShowPassword={isPageCreate}
                      isDisabled={isDisabledContactsFields}
                    />
                  </Stack>
                  {isVisibleButtons && (
                    <UserButtons
                      isSubmitDisabled={isSubmitDisabled || isUpdateLoading}
                      isDisabledContactsFields={isDisabledContactsFields}
                      handleReset={handleReset}
                    />
                  )}
                </Stack>
                <Stack
                  minWidth={MAX_WIDTH_PROFILE_BLOCK}
                  flex={1}
                  px={7.5}
                  pb={9}
                >
                  <ModulesAccess
                    changeRole={setFieldRole}
                    hasDivision={!!values.divisionName}
                    hasTrustDoc
                    modules={availableModules}
                  />
                </Stack>
              </Stack>
            </ScrollableContainer>
          )}
        </Stack>
      </FormikProvider>
      <FoundUserDialog
        isOpen={!!candidates?.length}
        onCancel={handleCloseFoundUserDialog}
        candidates={candidates}
        onSuccess={handleInviteUser}
      />

      <DeleteConfirmDialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteProfileByCompany}
        title={t("deleteModal.title")}
        description={t("deleteModal.description")}
      />
    </UserContext.Provider>
  );
};
