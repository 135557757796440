import { Button, Box } from '@mui/material'
import styled from 'styled-components'

export const ModalCloseBtn = styled(Button)`
  position: fixed;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  min-width: 0;
  padding: 0;
  color: #fff;
  
  svg {
    font-size: 32px;
  }
`
export const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 913px;
  max-height: 95%;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
`