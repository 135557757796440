import styled from "styled-components";
import { IconButton } from "@mui/material";

export const StyledWrapper = styled.div`
  border-radius: 8px;
  background: #fff;
`;

export const StyledApplyToAllWrapper = styled.div`
  margin: 16px 0 0 10px;

  .MuiFormControlLabel-root {
    display: flex;
    align-items: center;
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.secondary.textAdditional};
  }
`;

export const StyledAllLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.textAdditional};
  text-transform: uppercase;
`;

export const StyledButtonDelete = styled(IconButton)`
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.secondary.error};
  border-radius: 6px;

  .delete-icon path {
    fill: #fff;
  }

  &:hover {
    background: ${({ theme }) => theme.secondary.error};
  }

  &:disabled {
    background: ${({ theme }) => `${theme.primary.textDark}1F`};

    .delete-icon path {
      fill: ${({ theme }) => theme.primary.actionDisabled};
    }
  }
`;

export const StyledCompanyName = styled.span`
  font-weight: 500;
`;
