import * as yup from 'yup'
import { i18n } from '../../../../i18n'
import { formatDateInInput } from '../templates-table/templates-table.service'
import dayjs from 'dayjs'

export interface TemlateFormDrawerProps {
  open: boolean,
  onClose: () => void
}

const minDate = new Date('1900-01-01')
const maxDate = new Date('2099-12-31')

const fileSchema = yup.object().shape({
  file: yup.mixed().required(),
  title: yup.string().required(),
  id: yup.string().required(),
});

export const schemaWithFiles = yup.object().shape({
  title: yup.string().trim().required(i18n.t('common:errors.required')),
  shortTitle: yup.string().nullable(),
  commentary: yup.string().nullable(),
  publicationDate: yup
    .date()
    .typeError(i18n.t('common:errors.dateError'))
    .min(
      minDate,
      i18n.t('common:errors.dateError')
    )
    .max(
      maxDate,
      i18n.t('common:errors.dateError'),
    )
    .test(
      'complete-date',
      i18n.t('common:errors.dateError'),
      (value) => {
        console.log('complete-date', value)
        if (!value) return true; // Если значение null, проверка пройдена (поле может быть пустым)
        const dateString = dayjs(value).format('DD.MM.YYYY');
        // Проверяем, что дата полностью заполнена (день, месяц, год)
        return /(?:\d{2})\.(?:\d{2})\.(?:\d{4})/.test(dateString);
      }
    )
    .nullable(),
  expirationDate: yup
    .date()
    .typeError(i18n.t('common:errors.dateError'))
    .min(
      minDate,
      i18n.t('common:errors.dateError'),
    )
    .max(
      maxDate,
      i18n.t('common:errors.dateError'),
    )
    .test(
      'complete-date',
      i18n.t('common:errors.dateError'),
      (value) => {
        if (!value) return true;
        const dateString = dayjs(value).format('DD.MM.YYYY');
        return /(?:\d{2})\.(?:\d{2})\.(?:\d{4})/.test(dateString);
      }
    )
    .nullable(),
  files: yup.array().of(fileSchema)
    .test(
      'has-files',
      i18n.t('xml:template-form.errors.no-files'),
      (files) => files ? files.length > 0 : false
    )
    .test(
      'has-xsd',
      i18n.t('xml:template-form.errors.no-xsd'),
      (files) => files ? files.some(file => file.title?.endsWith('.xsd')) : false
    )
    .test(
      'has-xsl',
      i18n.t('xml:template-form.errors.no-xsl'),
      (files) => files ? files.some(file => file.title?.endsWith('.xsl')) : false
    )
    .test(
      'one-xsl',
      i18n.t('xml:template-form.errors.xsl-error'),
      (files) => files ? files.filter(file => file.title?.endsWith('.xsl')).length === 1 : false
    )
    .required(i18n.t('xml:template-form.errors.no-files'))
})

export const schemaNoFiles = yup.object().shape({
  title: yup.string().trim().required(i18n.t('common:errors.required')),
  shortTitle: yup.string().nullable(),
  commentary: yup.string().nullable(),
  publicationDate: yup
    .date()
    .typeError(i18n.t('common:errors.dateError'))
    .min(
      minDate,
      i18n.t('common:errors.dateError')
    )
    .max(
      maxDate,
      i18n.t('common:errors.dateError'),
    )
    .test(
      'complete-date',
      i18n.t('common:errors.dateError'),
      (value) => {
        if (!value) return true;
        const dateString = dayjs(value).format('DD.MM.YYYY');
        return /(?:\d{2})\.(?:\d{2})\.(?:\d{4})/.test(dateString);
      }
    )
    .nullable(),
  expirationDate: yup
    .date()
    .typeError(i18n.t('common:errors.dateError'))
    .min(
      minDate,
      i18n.t('common:errors.dateError'),
    )
    .max(
      maxDate,
      i18n.t('common:errors.dateError'),
    )
    .test(
      'complete-date',
      i18n.t('common:errors.dateError'),
      (value) => {
        if (!value) return true;
        const dateString = dayjs(value).format('DD.MM.YYYY');
        return /(?:\d{2})\.(?:\d{2})\.(?:\d{4})/.test(dateString);
      }
    )
    .nullable()
})