import { StyledLabel, StyledCheckboxWrapper } from "./checkboxes-block.styles";
import { useTranslation } from "react-i18next";
import { CheckFieldForm } from "../../../../components/check-field-form";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { CheckboxesBlockProps } from "./checkboxes-block.types";
import { DeleteModal } from "../../../../components/delete-modal";

export const CheckboxesBlock: FC<CheckboxesBlockProps> = ({
  isAdminCheckboxDisabled,
  setFieldValue,
}) => {
  const { t } = useTranslation("administration");

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleSuperAdminCheckboxChange = useCallback(
    (event: ChangeEvent) => {
      const target = event.target as HTMLInputElement;

      if (target.checked) {
        setIsConfirmModalOpen(true);
        return;
      }
      setFieldValue("superAdmin", false);
    },
    [setFieldValue]
  );

  const handleSuperAdminConfirm = () => {
    setFieldValue("superAdmin", true);
    setIsConfirmModalOpen(false);
  }

  return (
    <>
      <StyledCheckboxWrapper $margin="0 0 16px">
        <StyledLabel>{t("index")}</StyledLabel>
        <CheckFieldForm
          label={<StyledLabel className="label">{t("pragmacore")}</StyledLabel>}
          name="superAdmin"
          color="primary"
          disabled={isAdminCheckboxDisabled}
          onChange={handleSuperAdminCheckboxChange}
        />
      </StyledCheckboxWrapper>

      <StyledCheckboxWrapper $margin="0 0 16px auto">
        <CheckFieldForm
          label={<StyledLabel className="label">{t("company")}</StyledLabel>}
          name="admin"
          color="primary"
        />
      </StyledCheckboxWrapper>

      <StyledCheckboxWrapper $margin="0 0 48px auto">
        <CheckFieldForm
          label={<StyledLabel className="label">TrustDoc</StyledLabel>}
          name="trustDoc"
          color="primary"
        />
      </StyledCheckboxWrapper>

      <StyledCheckboxWrapper $margin="0 0 16px">
        <StyledLabel>{t("headDivision")}</StyledLabel>
        <CheckFieldForm
          label={<StyledLabel>{t("appointed")}</StyledLabel>}
          name="divisionHead"
          color="primary"
        />
      </StyledCheckboxWrapper>

      <DeleteModal
        open={isConfirmModalOpen}
        title={t("superAdminConfirm.title")}
        description={t("superAdminConfirm.description")}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleSuperAdminConfirm}
        leftButtonColor="success"
        rightButtonColor="primary"
        titleColor="#0044B4"
      />
    </>
  );
};
