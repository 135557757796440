import { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { TemplateViewModalProps } from './template-view-modal.types';
import { ModalCloseBtn, ModalContent } from './template-view-modal.styles';

export const TemplateViewModal: FC<TemplateViewModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: '#2B364833'
          }
        }
      }}    
    >
      <>
        <ModalCloseBtn
          onClick={() => onClose()}>
          <CloseIcon/>
        </ModalCloseBtn>
        <ModalContent>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </ModalContent>
      </>
    </Modal>
  );
}