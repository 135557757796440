import { AgGridReact } from "ag-grid-react";
import { AgGridNoRowsOverlay } from "../../../../components/ag-grid-no-rows-overlay";
import { StyledAgGridWrapper } from "../../../../common/librariesStyles/agGrid";
import {
  ROW_HEIGHT,
  HEADER_HEIGHT,
  cellsWidth,
  commonCellClasses,
} from "./users-table.constants";
import {
  ColDef,
  ColGroupDef,
  GridColumnsChangedEvent,
  RowClassParams,
  RowClickedEvent,
} from "ag-grid-community";
import { StyledWrapper } from "./users-table.styles";
import { useCallback, useMemo } from "react";
import { UsersTableProps } from "./users-table.types";
import { FC } from "react";
import { FioCell } from "../fio-cell";
import { DetailsCell } from "../details-cell";
import { CompaniesCell } from "../companies-cell";
import { CreatedCell } from "../created-cell";
import { LastActivityCell } from "../last-activity-cell";
import { useNavigate } from "react-router-dom";

export const UsersTable: FC<UsersTableProps> = ({ users }) => {
  const navigate = useNavigate();

  const columnDefs: (ColGroupDef | ColDef)[] = [
    {
      headerName: "ID",
      field: "id",
      width: cellsWidth.id,
      minWidth: cellsWidth.id,
      suppressSizeToFit: true,
      headerClass: "ag-header-cell_centred",
      cellClass: commonCellClasses,
    },
    {
      headerName: "ФИО",
      field: "fio",
      width: cellsWidth.default,
      minWidth: cellsWidth.default,
      flex: 1,
      cellRenderer: FioCell,
    },
    {
      headerName: "Реквизиты для входа",
      field: "details",
      width: cellsWidth.default,
      minWidth: cellsWidth.default,
      cellRenderer: DetailsCell,
    },
    {
      headerName: "Список компаний",
      field: "companies",
      width: cellsWidth.companies,
      minWidth: cellsWidth.companies,
      flex: 1,
      cellRenderer: CompaniesCell,
      cellClass: " ag-cell_padding_0",
    },
    {
      headerName: "Создан",
      field: "created",
      width: cellsWidth.created,
      minWidth: cellsWidth.created,
      cellRenderer: CreatedCell,
    },
    {
      headerName: "Последняя активность",
      field: "lastActivity",
      width: cellsWidth.lastActivity,
      minWidth: cellsWidth.lastActivity,
      cellRenderer: LastActivityCell,
    },
  ];

  const rows = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        fio: {
          mainProfile: user.mainProfile,
          profiles: user.profiles,
          superAdmin: user.superAdmin,
          userID: user.id,
        },
        details: {
          login: user.login,
          phone: user.phone,
          email: user.email,
        },
        companies: {
          profiles: user.profiles,
          userId: user.id,
          mainProfile: user.mainProfile,
        },
        created: {
          createdAt: user.createdAt,
          createdBy: user.createdBy,
        },
        lastActivity: {
          lastActivity: user.lastActivity,
          lastActivityAt: user.lastActivityAt,
        },
        hasProfiles: !!user.profiles?.length,
      })),
    [users]
  );

  const fitColumns = useCallback((event: GridColumnsChangedEvent) => {
    event.api.sizeColumnsToFit();
  }, []);

  const handleRowClicked = useCallback((event: RowClickedEvent) => {
    if (event.data && event.data.hasProfiles) {
      navigate(`/administration/users/${event.data.id}`);
    }
  }, []);

  const getRowClass = useCallback((event: RowClassParams) => {
    if (event.data?.hasProfiles) return "ag-row_interactive";
  }, []);

  return (
    <StyledWrapper>
      <StyledAgGridWrapper>
        <div className="ag-theme-alpine">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rows}
            onRowClicked={handleRowClicked}
            noRowsOverlayComponent={AgGridNoRowsOverlay}
            getRowClass={getRowClass}
            headerHeight={HEADER_HEIGHT}
            rowHeight={ROW_HEIGHT}
            defaultColDef={{
              editable: false,
              sortable: false,
              filter: false,
              resizable: true,
            }}
            onGridColumnsChanged={fitColumns}
          />
        </div>
      </StyledAgGridWrapper>
    </StyledWrapper>
  );
};
