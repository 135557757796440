import styled from "styled-components";
import {
  limitTextRows,
} from "../../../../../../common/styledMixins";
import { Typography } from "@mui/material";

export const StyledCell = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StyledCellText = styled(Typography)`
  max-height: 64px;
  line-height: 20px;
  font-size: 14px;
  ${limitTextRows(3)};
`;