import { FormikValues } from "formik/dist/types";
import { DEFAULT_DISPLAY_PASSWORD_VALUE } from "../../utils/constants";

export function fromValuesToReqBody(
  values: FormikValues,
  counterpartyID: number | null
) {
  const formData = new FormData();

  ["all", "superAdmin"].forEach((key) => {
    formData.append(key, values[key]);
  });

  formData.append(
    "admin",
    JSON.stringify({
      admin: values.admin,
    })
  );

  formData.append(
    "login",
    JSON.stringify({
      email: values.email,
      login: values.login,
      phone: values.phone,
      ...(values.password !== DEFAULT_DISPLAY_PASSWORD_VALUE && {
        password: values.password,
      }),
    })
  );

  formData.append(
    "profile",
    JSON.stringify({
      company: {
        counterpartyID,
      },
      division: values.divisionName,
      divisionHead: values.divisionHead,
      position: values.position,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
    })
  );

  formData.append(
    "access",
    JSON.stringify({
      dashboard: values.dashboard,
      design: values.design,
      quality_control: values.quality_control,
      finance: values.finance,
      procurement: values.procurement,
      offer: values.offer,
      resource_managment: values.resource_managment,
      maintenance: values.maintenance,
      asbuilt_doc: values.asbuilt_doc,
      admin: values.admin,
      trustDoc: values.trustDoc,
    })
  );

  if (values.avatar instanceof Blob) {
    formData.append("file", values.avatar);
  }

  return formData;
}
