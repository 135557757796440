import { AutoHiddenTextItemsProps } from "./auto-hidden-text-items.types";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  StyledWrapper,
  StyledCount,
  StyledNames,
} from "./auto-hidden-text-items.styles";
import { useViewport } from "../../hooks/useViewport";
import { Tooltip } from "@mui/material";

const DOTS_WIDTH = 20;

export const AutoHiddenTextItems: FC<AutoHiddenTextItemsProps> = ({
  items,
  tooltipItems,
}) => {
  const namesRef = useRef<HTMLDivElement>(null);
  const [namesWidth, setNamesWidth] = useState(0);

  const { width } = useViewport();

  const visibleCount = useMemo(() => {
    if (!namesRef.current) return 0;

    const namesElements = namesRef.current.querySelectorAll(".name");
    const cellRect = namesRef.current.getBoundingClientRect();
    const cellRight = cellRect.right || 0;
    const cellBottom = cellRect.bottom || 0;
    return Array.from(namesElements).reduce((accumulator, current) => {
      const itemRect = current.getBoundingClientRect();
      const itemLeft = itemRect.left || 0;
      const itemBottom = itemRect.bottom || 0;
      if (itemLeft < cellRight - DOTS_WIDTH && itemBottom < cellBottom) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
  }, [items, namesRef.current, width, namesWidth]);

  const hiddenCount = items.length ? items.length - visibleCount : 0;
  const tooltipItemsNames = tooltipItems.join(`\n`);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const rect = entries[0].contentRect;
      setNamesWidth(rect.width);
    });
    namesRef.current && observer.observe(namesRef.current);

    return () => {
      namesRef.current && observer.unobserve(namesRef.current);
    };
  }, [namesRef.current]);

  const mainContent = (
    <StyledWrapper>
      <StyledNames ref={namesRef}>
        {items.length
          ? items.map((item, index) => (
              <span className="name" key={index}>{`${item}${
                index < items.length - 1 ? ", " : ""
              }`}</span>
            ))
          : "–"}
      </StyledNames>

      {hiddenCount > 0 && <StyledCount>{`+${hiddenCount}`}</StyledCount>}
    </StyledWrapper>
  );

  return tooltipItemsNames.length ? (
    <Tooltip
      title={tooltipItemsNames}
      arrow
      placement="bottom"
      componentsProps={{
        tooltip: {
          sx: {
            margin: "2px 0!important",
            background: "#5C6E8C",
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: 400,
            whiteSpace: "pre-line",
          },
        },
      }}
    >
      {mainContent}
    </Tooltip>
  ) : (
    mainContent
  );
};
