import React, { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { List, Stack, useTheme } from "@mui/material";
import {
  useGetUsersQuery,
  UserRoles,
  Users as UserType,
} from "../../api/company-admin";
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from "../../utils/constants";
import { useAppContext } from "../../layouts/app-layout/context";
import { UserCardRow } from "./components/user-card-row";
import { HeaderUsers } from "./components/header-users";
import { ColorDivider } from "../../components/color-divider";
import { ScrollableContainer } from "../../components/scrollable-container";
import { useTranslation } from "react-i18next";
import { Progress } from "../../components/progress";
import { useGlobalSearch } from "../../utils/useGlobalSearch";
import { encode } from "js-base64";
import { Filters } from "./users.types";

export type Limit = {
  value: number;
  title: string;
};

export const Users = () => {
  const { t } = useTranslation("user");
  const theme = useTheme();
  const { currentCompanyId, availableModules } = useAppContext();

  const [searchParam] = useGlobalSearch();
  const [searchParams, setSearchParams] = useSearchParams();
  const limitParam = searchParams.get("limit");
  const pageParam = searchParams.get("page");

  const [filtersState, setFiltersState] = useState<Filters>(
    new Filters(searchParams)
  );

  const limitValue = useMemo(
    () => Number(limitParam) || NUMBER_OF_ROWS_PER_USERS_PAGE[0].value,
    [limitParam]
  );
  const page = useMemo(() => Number(pageParam) || 1, [pageParam]);

  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page]);

  const counterpartyIDs = useMemo(() => {
    if (!filtersState.counterparties) return [];
    return filtersState.counterparties.map(
      (counterparty) => Number(counterparty) || 0
    );
  }, [filtersState.counterparties]);
  const { data, isLoading, isFetching } = useGetUsersQuery({
    companyId: currentCompanyId,
    limit: limitValue,
    search: searchParam || "",
    offset: offset,
    roles: filtersState.roles?.length ? filtersState.roles : [],
    modules: filtersState.modules?.length ? filtersState.modules : [],
    ...(counterpartyIDs.length && { counterpartyIDs }),
    ...(filtersState.divisions.length && { divisions: filtersState.divisions }),
  });

  const { data: users, total } = data || ({} as UserType);

  const countPagination = useMemo(
    () => Math.ceil(total / Number(limitValue)) || 1,
    [total, limitValue]
  );

  const handleFiltersChange = useCallback(
    (values: string[], key: keyof Filters) => {
      searchParams.set(key, encode(values.join(",")));
      setSearchParams(searchParams);
      setFiltersState((prev) => ({ ...prev, [key]: values }));
    },
    [searchParams, searchParams]
  );

  const handleChangeLimit = useCallback(
    (limit) => {
      searchParams.set("limit", String(limit));
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );
  const handleChangePage = useCallback(
    (page) => {
      searchParams.set("page", page);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const allRoles = useMemo(
    () =>
      [UserRoles.user, UserRoles.admin, UserRoles.companyAdmin].map((role) => {
        return { value: role, title: t(`roles.${role}`) };
      }),
    [t]
  );

  const allModules = useMemo(
    () =>
      availableModules.map((modul) => {
        return { value: modul, title: t(`allModules.${modul}`) };
      }),
    [t, availableModules]
  );

  return (
    <Stack flex={1}>
      <Stack px={2.5}>
        <HeaderUsers
          onChangeRole={(values) => handleFiltersChange(values, "roles")}
          onChangeCounterparty={(values) =>
            handleFiltersChange(values, "counterparties")
          }
          onChangeDivisions={(values) =>
            handleFiltersChange(values, "divisions")
          }
          onChangeModule={(values) => handleFiltersChange(values, "modules")}
          filtersState={filtersState}
          countPagination={countPagination}
          limit={limitValue}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
          page={page}
          numberRows={NUMBER_OF_ROWS_PER_USERS_PAGE}
          allModules={allModules as Record<"value" | "title", string>[]}
          allRoles={allRoles as Record<"value" | "title", string>[]}
          selectedCounterpartyIDs={counterpartyIDs}
        />
        <ColorDivider />
      </Stack>
      {isLoading || isFetching ? (
        <Progress />
      ) : (
        <ScrollableContainer>
          <Stack flex={1} px={2.5} overflow="hidden overlay">
            <List sx={{ padding: 0, paddingBottom: 3 }}>
              {users?.map(
                ({
                  coreID,
                  firstName,
                  lastName,
                  middleName,
                  position,
                  companyName,
                  division,
                  avatarPreview,
                  phone,
                  email,
                  access,
                  haveTrustDocAccount,
                }) => (
                  <Stack
                    sx={{
                      textDecoration: "none",
                      color: theme.palette.text.primary,
                    }}
                    component={Link}
                    key={coreID}
                    to={String(coreID)}
                  >
                    <UserCardRow
                      phone={phone}
                      email={email}
                      firstName={firstName}
                      lastName={lastName}
                      middleName={middleName}
                      position={position}
                      companyName={companyName}
                      avatarPreview={avatarPreview}
                      access={access}
                      availableModules={availableModules}
                      division={division}
                      haveTrustDocAccount={haveTrustDocAccount}
                    />
                    <ColorDivider />
                  </Stack>
                )
              )}
            </List>
          </Stack>
        </ScrollableContainer>
      )}
    </Stack>
  );
};
