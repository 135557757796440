import { isUndefined } from 'lodash'

import { ButtonData, EmptyPageData } from '.'

export function getEmptyPageMessage(
  query: string | undefined,
  noDataMessage?: string,
  noResultMessage?: string,
) {
  return isUndefined(query)
    ? (noDataMessage ?? 'Отсутствуют данные для отображения.')
    : (noResultMessage ??
        'Отсутствуют позиции, соответствующие результатам запроса.')
}

export const getEmptyPageData = (
  text: React.ReactNode,
  buttons?: ButtonData[],
): EmptyPageData => ({
  text,
  buttons:
    buttons?.map((button) => ({
      text: button.text,
      icon: button.icon,
      onClick: button.onClick,
      disabled: button.disabled,
    })) || [],
})
