import React, { FC, useEffect, useRef, useState } from "react";
import { PartnerCardProps } from "../partner-card.types";
import { StatusIndicator } from "../../status-indicator";
import { Stack, Tooltip } from "@mui/material";
import { StyledTooltipBody } from "./partner-card-status.styles";
import { useTranslation } from "react-i18next";

const ARROW_WIDTH = 15;

export const PartnerCardStatus: FC<
  Pick<PartnerCardProps, "statusColor" | "statusName" | "status">
> = ({ status, statusColor, statusName }) => {
  const { t } = useTranslation("modules");
  const indicatorRef = useRef<HTMLDivElement>(null);

  const [indicatorWidth, setIndicatorWidth] = useState(0);

  const hasStatus = status && statusColor;
  const hasStatusTooltip = status === "connected";

  useEffect(() => {
    if (indicatorRef?.current) {
      setIndicatorWidth(indicatorRef.current.getBoundingClientRect().width || 0);
    }
  }, [indicatorRef?.current]);

  if (!hasStatus) return null;

  const statusContent = (
    <Stack position="absolute" left={0} right={0} top={-9} alignItems="center">
      <div ref={indicatorRef}>
        <StatusIndicator
          backgroundColor={statusColor}
          statusName={statusName}
        />
      </div>
    </Stack>
  );

  return hasStatusTooltip ? (
    <Tooltip
      placement="top-start"
      arrow
      componentsProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              marginBottom: "8px!important",
              background: "#5C6E8C",
              borderRadius: "6px",
              boxShadow:
                "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
            },

            "& .MuiTooltip-arrow": {
              color: "#5C6E8C",
            },
          },
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [indicatorWidth ? indicatorWidth / 2 + ARROW_WIDTH : 0, 0],
            },
          },
        ],
        placement: "top-start",
      }}
      title={
        <StyledTooltipBody>{t(`tooltipByStatus.${status}`)}</StyledTooltipBody>
      }
    >
      {statusContent}
    </Tooltip>
  ) : (
    statusContent
  );
};
