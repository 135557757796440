import { FieldForm } from "../../../../../../components/field-form";
import {
  StyledForm,
  StyledButtonHide,
  StyledButtonConnect,
} from "./trust-doc-form.styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FC } from "react";
import { TrustDocFormProps } from "./trust-doc-form.types";

export const TrustDocForm: FC<TrustDocFormProps> = ({ isReady, isLoading }) => {
  const { t } = useTranslation("administration");
  const [isPasswordVisible, setInputVisibility] = useState(false);

  return (
    <StyledForm>
      <FieldForm
        version="profile"
        name="login"
        title={t("trustDoc.form.login")}
        placeholder={t("trustDoc.form.loginPlaceholder")}
        InputProps={{
          disableUnderline: true,
        }}
      />

      <FieldForm
        version="profile"
        name="password"
        title={t("trustDoc.form.password")}
        placeholder={t("trustDoc.form.passwordPlaceholder")}
        InputProps={{
          disableUnderline: true,
          type: isPasswordVisible ? "text" : "password",
          endAdornment: (
            <StyledButtonHide
              disableRipple
              onClick={() => setInputVisibility((prev) => !prev)}
              $fill={isPasswordVisible ? "blue" : "gray"}
            >
              {<VisibilityIcon />}
            </StyledButtonHide>
          ),
        }}
      />

      {isReady && (
        <StyledButtonConnect
          variant="contained"
          color="success"
          type="submit"
          disabled={isLoading}
        >
          {t("trustDoc.connect")}
        </StyledButtonConnect>
      )}
    </StyledForm>
  );
};
