import styled from "styled-components";
import {Button} from "@mui/material";

export const StyledTab = styled.div`
  padding: 20px;
  display: grid;
  row-gap: 20px;
  align-content: start;
  overflow-y: auto;
  height: calc(100% - 40px);
`;

export const StyledHeader = styled.div`
  display: flex;
  column-gap: 20px;
  padding-bottom: 20px;
`;

export const StyledIcon = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${({ theme }) => theme.primary.blueShades};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin: auto 0;
`;

interface RowProps {
  $minHeight: number;
}

export const StyledRow = styled.div<RowProps>`
  display: grid;
  grid-template-columns: 272px 360px;
  column-gap: 20px;
  min-height: ${({ $minHeight }) => `${$minHeight}px`};
`;

export const StyledSubTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  display: grid;
  grid-template-rows: 24px 1fr;
  row-gap: 4px;
`;

export const StyledDescription = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.secondary.textAdditional};
  white-space: pre-line;
`;
