import { NavLink } from "react-router-dom";
import { styled } from "@mui/material";

interface CompaniesNavLinkProps {
  isCurrent?: boolean;
}

export const CompaniesNavLink = styled(NavLink)(({ theme }) => ({
  display: "flex",
  textDecoration: "none",
  color: theme.palette.text.primary,
  borderLeft: `4px solid ${theme.palette.primary.contrastText}`,

  "&.current": {
    background: theme.palette.warning.contrastText,
    color: theme.palette.primary.main,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    "& .MuiAvatar-root": {
      background: theme.palette.primary.main,
      opacity: 1,
    },
    "& .MuiIconButton-root": {
      color: theme.palette.primary.main,
    },
  },
}));
