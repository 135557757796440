import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Drawer, Stack } from '@mui/material'
import { useFormik, FormikProvider } from 'formik'
import { DrawerFormWrapper, FormDrawerProps, LayoutDrawerTopBar, LayoutDrawerWrapper, LayoutDrawerTitle } from '.'
import { ColorDivider } from '../color-divider'
import { useEffect } from 'react'

export const FormDrawer = <T extends object>(props: FormDrawerProps<T>) => {
  const onSubmit = (values: T) => {
    props.handleSubmit(values);
  };

  const onCancel = (isDirty: boolean) => {
    props.handleCancel(isDirty)
  }

  const onClose = (isDirty: boolean) => {
    props.handleOnClose(isDirty)
  }

  const onDelete = (values: T) => {
    if (props.handleDelete) {
      props.handleDelete(values)
    }
  }

  const formik = useFormik({
    initialValues: props.initialValues,
    validationSchema: props.validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true
  });

  useEffect(() => {
    if (props.isOpen) formik.resetForm()
  }, [props.isOpen]);

  useEffect(() => {
    if (props.error && props.setFormError) props.setFormError(false);
  }, [formik.values]);

  return (
    <FormikProvider value={formik}>
      <Drawer anchor="right" open={props.isOpen} onClose={() => onClose(formik.dirty)}>
        <LayoutDrawerWrapper>
          <LayoutDrawerTopBar>
            <LayoutDrawerTitle>
              {props.title}
            </LayoutDrawerTitle>
          </LayoutDrawerTopBar>
          <ColorDivider size={1} />
          <DrawerFormWrapper sx={props.sx}>
            <Stack spacing={2.5} component="form" sx={{ flexGrow: '1' }}>
                {props.children}
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              sx={{ margin: 'auto 0!important', paddingTop: 2.5 }}
            >
              {props.labelDelete && (
                <Button
                  onClick={() => onDelete(formik.values)}
                  color="error"
                  variant="text"
                  startIcon={<DeleteIcon />}
                  sx={{height: '40px'}}
                  fullWidth
                  disabled={props.disableDelete || false}
                >
                  {props.labelDelete}
                </Button>
              )}
              <Stack direction="row" spacing={2}>
                {props.labelSubmit && (
                  <Button
                    onClick={() => onSubmit(formik.values)}
                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || props.error}
                    variant="contained"
                    color="success"
                    sx={{height: '40px'}}
                    fullWidth
                  >
                    {props.labelSubmit}
                  </Button>
                )}
                {props.labelCancel && (
                  <Button
                    onClick={() => onCancel(formik.dirty)}
                    variant="contained"
                    color="info"
                    sx={{height: '40px'}}
                    fullWidth
                  >
                    {props.labelCancel}
                  </Button>
                )}
              </Stack>
            </Stack>
          </DrawerFormWrapper>
        </LayoutDrawerWrapper>
      </Drawer>
    </FormikProvider>
  )
}
