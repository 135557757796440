import styled from "styled-components";

export const StyledPage = styled.div`
  width: 100%;
  height: calc(100vh - 66px);
  padding: 20px;
  display: grid;
  grid-template-columns: 380px 1fr;
  grid-column-gap: 20px;

  .MuiInputBase-input {
    font-size: 14px;
  }

  .field-form-label {
    margin-top: 0;

    .MuiSelect-select {
      font-size: 14px;
      font-weight: 400;
      padding: 8.5px 16px;
    }

    .MuiInputBase-root {
      background: ${({ theme }) => theme.primary.blueShades};
    }

    .MuiSelect-icon,
    .MuiAutocomplete-endAdornment .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.secondary.text};
    }

    .MuiAutocomplete-inputRoot {
      padding: 2.5px 39px 2.5px 16px;
    }
  }

  .MuiInputBase-root {
    background: ${({ theme }) => theme.primary.blueShades};
  }

  .field-form-label .title,
  .field-form-title .MuiTypography-root {
    font-size: 12px;
    line-height: 13px;
    margin-top: 16px;
    padding-bottom: 6px;
  }

  .MuiAvatar-root {
    background: ${({ theme }) => theme.primary.blueShades};

    .MuiSvgIcon-root {
      fill: #6d9adc33;
    }
  }
`;
