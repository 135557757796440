import { FC, useEffect, useMemo, useState } from "react";
import { useMatch } from "react-router-dom";
import { AppContextType, AppMode, AppProvider } from "./context";
import { Stack } from "@mui/material";
import { useGetProfileQuery } from "../../api/profile";
import {
  Company,
  ModulesResponse,
  useGetCompaniesQuery,
  useGetModulesByCompanyIdQuery,
  useGetProfileByCompanyQuery,
  useGetUrlAuthInModuleMutation,
} from "../../api/companies";
import { Progress } from "../../components/progress";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import { useDispatch, useSelector } from "react-redux";
import { sourceModuleSelector } from "../../services/slices/auth";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { appSelector, setCurrentCompanyId } from "../../services/slices/app";
import { fromModulesToAvailableModules } from "../../utils/commonUtils";

export const AppLayout: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("modules");
  const match = useMatch("/companies/:id/*");
  const adminMatch = useMatch("/administration/*");
  const appState = /*Number(match?.params?.id)*/ useSelector(appSelector);
  const dispatch = useDispatch();

  const [appMode, setAppMode] = useState<AppMode>(
    adminMatch ? "admin" : "view"
  );

  const {
    data: companies,
    isLoading: companiesIsLoading,
    error: companyError,
  } = useGetCompaniesQuery();

  const {
    data: profile,
    error: profileError,
    isLoading: profileIsLoading,
  } = useGetProfileQuery();

  const {
    data: profileByCompany,
    error: profileByCompanyError,
    isLoading: profileByCompanyIsLoading,
  } = useGetProfileByCompanyQuery(appState.currentCompanyId || 0, {
    skip: !appState.currentCompanyId,
  });
  const {
    data: modules,
    error: modulesError,
    isLoading: modulesIsLoading,
  } = useGetModulesByCompanyIdQuery(appState.currentCompanyId || 0, {
    skip: !appState.currentCompanyId,
  });

  const availableModules = useMemo(
    () => fromModulesToAvailableModules(modules),
    [modules]
  );

  const value = useMemo<AppContextType>(() => {
    const sortedCompanies = [...(companies?.data || [])].sort((a) => {
      if (a.main) {
        return -1;
      } else {
        return 1;
      }
    });

    return {
      profile: profileError
        ? ({} as AppContextType["profile"])
        : profile || ({} as AppContextType["profile"]),
      profileByCompany: profileByCompanyError
        ? ({} as AppContextType["profileByCompany"])
        : profileByCompany || ({} as AppContextType["profileByCompany"]),
      companies: sortedCompanies,
      mainCompany:
        sortedCompanies?.find(
          (company: Company) => company.id === appState.currentCompanyId
        ) || null,
      modules: modulesError
        ? ({} as ModulesResponse)
        : modules || ({} as ModulesResponse),
      availableModules: availableModules,
      currentCompanyId:
        appState.currentCompanyId || 0 /*currentCompanyId || 0*/,
      isAdmin: !!profileByCompany?.access?.admin,
      appMode,
      setAppMode,
    };
  }, [
    profile,
    profileError,
    companies,
    appState.currentCompanyId,
    profileByCompany,
    profileByCompanyError,
    modulesError,
    modules,
    appMode,
    availableModules,
  ]);

  const isLoading =
    profileIsLoading ||
    companiesIsLoading ||
    profileByCompanyIsLoading ||
    modulesIsLoading;

  const isError =
    profileError || profileByCompanyError || companyError || modulesError;

  useEffect(() => {
    if (isError) {
      console.log("Ошибка");
    }
  }, [isError]);

  useEffect(() => {
    const id = Number(match?.params?.id);
    if (id) {
      dispatch(setCurrentCompanyId(id));
      return;
    }
    if (!value.currentCompanyId && value.companies?.length) {
      dispatch(setCurrentCompanyId(value.companies[0].id));
    }
  }, [match?.params?.id, value.companies]);

  useEffect(() => {
    if (!adminMatch) setAppMode("view");
  }, [adminMatch]);

  const sourceModule = useSelector(sourceModuleSelector);
  const [getUrlAuthInModule, getUrlAuthInModuleResponse] =
    useGetUrlAuthInModuleMutation();

  useEffect(() => {
    if (sourceModule && appState.currentCompanyId) {
      getUrlAuthInModule({
        companyId: appState.currentCompanyId,
        module: sourceModule,
      });
    }
  }, [sourceModule, appState.currentCompanyId]);

  useMutationHandlers(
    getUrlAuthInModuleResponse,
    (data) => {
      window.location.replace(`${data?.url}`);
    },
    (error) => {
      enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
    }
  );

  return (
    <AppProvider value={value}>
      <Stack flex={1}>{isLoading ? <Progress /> : children}</Stack>
    </AppProvider>
  );
};
