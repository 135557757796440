import { decode } from "js-base64";

export type Tab = "users" | "xml";

export class FiltersState {
  companies: (number | string)[] = [];

  constructor(searchParams: URLSearchParams) {
    const companiesParams = searchParams.get("companies");

    if (companiesParams)
      this.companies = decode(companiesParams).split(",").map(Number);
  }
}
