import { createContext } from "react";
import { FormikValues } from "formik/dist/types";
import { SuperAdminUserCompanyProfile } from "../../api/superAdmin/types";

interface UserAdminContextProps {
  userValues: FormikValues;
  initialValues: FormikValues;
  isLoading: boolean;
  currentProfile?: SuperAdminUserCompanyProfile | null;
  profiles: SuperAdminUserCompanyProfile[];
}

export const UserAdminContext = createContext<UserAdminContextProps>({
  userValues: {},
  initialValues: {},
  profiles: [],
  isLoading: false,
});
