import styled, { css } from "styled-components";

interface WrapperProps {
  $isEmpty: boolean;
}

export const StyledListWrapper = styled.div<WrapperProps>`
  width: 100%;
  height: calc(100vh - 106px);
  background: #fff;
  border-radius: 8px;
  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      display: grid;
      grid-template-rows: 64px 1fr;
      align-content: start;
    `}

  .row {
    color: ${({ theme }) => theme.secondary.text};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .icon {
      width: 20px;
    }
  }
`;

export const StyledEmptyPlaceholder = styled.div`
  display: grid;
  justify-items: center;
  column-gap: 8px;
  align-content: center;
`;

export const StyledPlaceholderText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.secondary.text};
  max-width: 340px;
  text-align: center;
  align-self: center;
`;

export const StyledHeader = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
`;

export const StyledList = styled.ul`
  width: 100%;
  height: calc(100% - 64px);
  margin: 0;
  padding: 0;
  overflow-y: auto;
`;

export const StyledCompanyName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

const activeItemStyles = css`
  border-left: 4px solid ${({ theme }) => theme.primary.mainBlue};
  background: ${({ theme }) => theme.primary.blueShades};

  ${StyledCompanyName} {
    color: ${({ theme }) => theme.primary.mainBlue};
  }

  .row {
    color: ${({ theme }) => theme.primary.light};
  }
`;

interface ListItemProps {
  $isActive: boolean;
}

export const StyledListItem = styled.li<ListItemProps>`
  height: 127px;
  padding: 20px;
  list-style: none;
  display: grid;
  grid-row-gap: 20px;
  align-content: center;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive &&
    css`
      ${activeItemStyles}
    `};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
  }
`;

export const StyledRows = styled.div`
  display: grid;
  grid-row-gap: 8px;
`;
