import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { xmlApi } from "../../../api-xml-constructor/api";
import { AuthEndpoints, LoginResponse, xmlAuthApi } from "../../../api-xml-constructor/auth";

let initialState: Partial<LoginResponse>;

try {
  const loginData: Partial<LoginResponse> = JSON.parse(localStorage.getItem("xmlAuth") || "{}")
  initialState = {
    ...loginData
  }
} catch (error) {
  initialState = {};
}

const updateState = (
  prevState: Partial<LoginResponse>,
  payload: Partial<LoginResponse>
) => {
  const { refreshToken, accessToken, refreshTokenDuration, accessTokenDuration } = payload;
  prevState.accessToken = accessToken;
  prevState.accessTokenDuration = accessTokenDuration;
  prevState.refreshToken = refreshToken;
  prevState.refreshTokenDuration = refreshTokenDuration;
  localStorage.setItem("xmlAuth", JSON.stringify(payload));
};

const slice = createSlice({
  name: "xmlAuth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      updateState(state, payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (props) =>
          !!xmlAuthApi.endpoints.loginCore.matchFulfilled(
            props
          ),
        (state, { payload }) => {
          if (payload?.accessToken) {
            updateState(state, payload);
          }
        }
      );
  },
});

export const xmlAuthReducer = slice.reducer;
export const { setCredentials } = slice.actions;
export const isXmlAuthSelector = (state: RootState) => !!state.xmlAuth.accessToken;
