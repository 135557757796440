import React, { useContext } from "react";
import { Profile, ProfileByCompany } from "../../api/profile";
import { Company, ModulesResponse, ModuleType } from "../../api/companies";

export type AppContextType = {
  profile: Profile;
  profileByCompany: ProfileByCompany;
  companies?: Company[];
  mainCompany?: Company | null;
  currentCompanyId: Company["id"];
  isAdmin: boolean;
  modules: ModulesResponse;
  availableModules: ModuleType[];
  appMode: AppMode;
  setAppMode: (mode: AppMode) => void;
};

export type AppMode = 'admin' | 'view';

const AppContext = React.createContext<AppContextType>({} as AppContextType);

const AppProvider = AppContext.Provider;

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
