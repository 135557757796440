import React, {
  FC,
  useState,
  useCallback,
  useMemo,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import {
  StyledButtonWrapper,
  StyledSearchWrapper,
  StyledSearchInput,
  StyledButtonRedirect,
  StyledSelect,
  StyledPlaceholder,
  StyledMenuItem,
  StyledAvatar,
} from "./company-select.styles";
import { useAppContext } from "../../layouts/app-layout/context";
import { useField } from "formik";
import { CompanySelectProps } from "./company-select.types";

export const CompanySelect: FC<CompanySelectProps> = ({
  fromCounterpartyID,
  counterparties,
  mainCounterparty,
  userValues,
  hasButtonAdd = true,
}) => {
  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  const { currentCompanyId } = useAppContext();
  const [, , { setValue }] = useField("companyName");

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const sortedCounterparties = useMemo(() => {
    if (!fromCounterpartyID && !userValues.companyName) return counterparties;

    return [...counterparties].sort((a, b) => {
      if (fromCounterpartyID) return a.id === fromCounterpartyID ? -1 : 0;
      return a.name === userValues.companyName ? -1 : 0;
    });
  }, [counterparties, userValues.companyName, fromCounterpartyID]);

  const handleButtonRedirectClick = useCallback(() => {
    setIsOpen(false);
    navigate(
      { pathname: `/companies/${currentCompanyId}/counterparties/create` },
      { state: { userValues, from: location.pathname } }
    );
  }, [userValues, currentCompanyId]);

  const handleSearchChange = useCallback((event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    setSearch(value);
  }, []);

  const filteredItems = [
    mainCounterparty ? mainCounterparty : {},
    ...sortedCounterparties,
  ].filter((counterparty) =>
    counterparty.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  return (
    <label className="field-form-label">
      <Typography pb={0.5} color="secondary" pl={2} className="title">
        {t("companyName")}
      </Typography>

      <StyledSelect
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        displayEmpty
        name="companyName"
        MenuProps={{
          sx: {
            maxHeight: "480px",
            maxWidth: "362px",
            paddingTop: 0,
            "& .MuiList-root": { paddingTop: 0 },
          },
        }}
        value={userValues.companyName}
        onChange={(e) => setValue(e.target.value)}
        renderValue={(value: string | null) =>
          value || (
            <StyledPlaceholder>
              {t("placeholders.companyName")}
            </StyledPlaceholder>
          )
        }
      >
        <StyledSearchWrapper>
          <StyledSearchInput
            value={search}
            placeholder={tCommon("search")}
            onChange={handleSearchChange}
            onKeyDown={(event: KeyboardEvent) => event.stopPropagation()}
          />
        </StyledSearchWrapper>

        <StyledButtonWrapper>
          {hasButtonAdd && (
            <StyledButtonRedirect onClick={handleButtonRedirectClick}>
              {t("newCounterparty")}
            </StyledButtonRedirect>
          )}
        </StyledButtonWrapper>

        {filteredItems.length ? (
          filteredItems.map((counterparty) => (
            <StyledMenuItem
              key={counterparty?.id}
              value={counterparty.name}
              onKeyDown={(event: KeyboardEvent) => event.stopPropagation()}
              aria-selected="false"
            >
              <StyledAvatar
                src={counterparty.avatarPreview || ""}
                alt="логотип компании"
              />
              {counterparty.name}
            </StyledMenuItem>
          ))
        ) : (
          <StyledMenuItem
            disabled
            onKeyDown={(event: KeyboardEvent) => event.stopPropagation()}
          >
            {tCommon("noOptions")}
          </StyledMenuItem>
        )}
      </StyledSelect>
    </label>
  );
};
