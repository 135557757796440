import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
  StyledCell,
  StyledCellText
} from "./text-cell.styles";
import {Box, Tooltip} from "@mui/material";

export const TextCell: FC<ICellRendererParams<unknown, string>> = ({
  value,
}) => {
  return (
    <StyledCell>
      <Tooltip title={value && value.length > 60 ? <Box sx={{overflowY: 'auto', height: '100%'}}>{value}</Box> : undefined} arrow>
        <StyledCellText>{value || ''}</StyledCellText>
      </Tooltip>
    </StyledCell>
  );
};
