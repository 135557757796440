import styled from "styled-components";

export const StyledList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(352px, 0.33fr));
  align-content: start;
  grid-gap: 20px;
  overflow-y: auto;
  width: 100%;
  padding: 0 0 20px;

  @media screen and (max-width: 1180px) {
    grid-template-columns: repeat(auto-fill, minmax(352px, 1fr));
  }
`;
