import { styled } from "styled-components";

export const StyledPage = styled.div`
  padding: 10px 20px 20px;
  border-radius: 8px;
  background: #fff;
  height: 100%;
  margin: 20px;
  display: grid;
  grid-row-gap: 10px;
  align-items: start;
  grid-template-rows: 48px 48px 1fr;

  .MuiTabs-indicator {
    background: ${({ theme }) => theme.primary.mainBlue};
  }

  .MuiTabs-flexContainer {
    min-height: 48px;
  }

  .pagination {
    min-height: 48px;
  }
`;

export const StyledDate = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledTime = styled.span`
  color: ${({ theme }) => theme.secondary.text};
`;

export const StyledCell = styled.div`
  display: grid;
  grid-row-gap: 4px;
  align-items: center;
  align-content: center;
  height: 100%;
`;
