import React, { createContext, useContext, useState, useEffect, DependencyList, FC, PropsWithChildren, ReactElement } from 'react';
import { BreadcrumbsItem } from '../../components/breadcrumbs';

export interface BreadcrumbsContextProps {
  breadcrumbs: BreadcrumbsItem[]
  setBreadcrumbs: (breadcrumbs: BreadcrumbsItem[]) => void
}

export const BreadcrumbsContext = createContext<BreadcrumbsContextProps | undefined>(undefined);

export const BreadcrumbsContextProvider: FC<PropsWithChildren<{}>> = ({ children }): ReactElement | null => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsItem[]>([]);

  function onSetBreadcrumbs(items: BreadcrumbsItem[]) {
    setBreadcrumbs(items);
  }

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs: onSetBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = (items: BreadcrumbsItem[], loading = false) => {
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    if (!items || loading) return;

    setBreadcrumbs(items.filter(Boolean));

    return () => setBreadcrumbs([]);
    
  }, [setBreadcrumbs, items, loading]);
};

export const useBreadcrumbsContext = () => {
  const context = useContext(BreadcrumbsContext);
  
  if (!context) {
    throw new Error('useBreadcrumbsContext must be used within a BreadcrumbsContextProvider');
  }

  return context;
};
