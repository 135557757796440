import { createSlice } from "@reduxjs/toolkit";
import { companyApi } from "../../../api/companies";
import { State } from "./types";
import {RootState} from "../../../store";

const initialState: State = {
  info: null,
};

export const {
  actions: { setProject },
  reducer,
} = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (state, { payload }) => {
      state.info = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      companyApi.endpoints.getProject.matchFulfilled,
      (state, { payload }) => {
        if (payload) state.info = payload;
      }
    );
  },
});

export const projectSelector = (state: RootState) => state.project;
