import styled from "styled-components";

interface AdminWrapperProps {
  $marginBottom?: number;
}

export const StyledAdminWrapper = styled.div<AdminWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiFormControlLabel-root {
    margin: 0 0 0 auto;
    border: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
    border-radius: 8px;
    min-width: 220px;
  }

  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? `${$marginBottom}px` : "32px"};
`;

export const StyledAdminTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

interface AdminLabelProps {
  $isLowercased?: boolean;
}

export const StyledAdminLabel = styled.div<AdminLabelProps>`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.primary.mainBlue};
  text-transform: ${({ $isLowercased = true }) =>
    $isLowercased ? "lowercase" : "unset"};

  &:first-letter {
    text-transform: uppercase;
  }
`;
