import styled from "styled-components";
import {
  limitTextRows,
} from "../../../../../../common/styledMixins";
import { Stack, Typography } from "@mui/material";

export const StyledCell = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StyledCellText = styled(Typography)`
  max-height: 64px;
  line-height: 20px;
  font-size: 14px;
  ${limitTextRows(3)};
`;

export const StyledErrorMsg = styled(Stack)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 2.5px;
  background: ${props => props.theme.charts.red}26;

`;