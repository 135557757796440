import styled, { css } from "styled-components";
import { IconButton, Typography } from "@mui/material";
import { Button } from "@mui/material";

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  // border: 1px solid ${({ theme }) => theme.secondary.text};
  padding: 1px;

  .MuiSvgIcon-root {
    width: 20px;
  }
`;

export const StyledTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary.errorDark};
  margin-bottom: 12px;
`;

interface ButtonProps {
  $theme: "default" | "error";
}

export const StyledButton = styled(Button)<ButtonProps>`
  ${({ $theme }) =>
    $theme === "error" &&
    css`
      height: 40px;
        font-size: 14px;
        font-weight: 500;
    `};
`;

export const StyledDescription = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 143%;
    color: ${({ theme }) => theme.primary.textDark};
    text-align: center;
`;