import React, { ChangeEvent, FC } from "react";
import { StyledPaginationWrapper } from "./pagination.styles";
import { FilterSelect } from "../filter-select";
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from "../../utils/constants";
import { StyledPagination } from "../../common/commonStyles";
import { useTranslation } from "react-i18next";
import { PaginationProps } from "./pagination.types";

export const Pagination: FC<PaginationProps> = ({
  limit,
  count,
  page,
  onChangeLimit,
  onChangePage,
}) => {
  const { t } = useTranslation("common");

  const handlePaginationChange = (_: ChangeEvent<unknown>, value: number) => {
    onChangePage(value);
  };

  return (
    <StyledPaginationWrapper className="pagination">
      <FilterSelect
        items={NUMBER_OF_ROWS_PER_USERS_PAGE}
        value={limit}
        onChange={onChangeLimit}
        startIcon={t("rows")}
      />
      <StyledPagination
        onChange={handlePaginationChange}
        count={count}
        shape="rounded"
        page={page}
      />
    </StyledPaginationWrapper>
  );
};
