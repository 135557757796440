export const styledComponentsTheme = {
  primary: {
    mainBlue: "#0044B4",
    blueShades: "#F6F7FB",
    blue20: "#6D9ADC33",
    textDark: "#2B3648",
    errorDark: "#D95959",
    warningMain: "#ED6C02",
    formBackground: "#0000000F",
    warningLight: "#FF9800",
    successMain: "#2E7D32",
    errorMain: "#D32F2F",
    outlinedBorder: "#8CBBE8",
    placeholder: "#2b36486b",
    black54: "#0000008a",
    disabled: "#9aa2b0",
    light: "#668FD2",
    actionDisabled: "#2B364861",
  },
  secondary: {
    text: "#7890B2",
    textCaption: "#2b3648",
    blue: "#00358C",
    textAdditional: "#5C6E8C",
    backgroundDivider: "#D1D8FA",
    infoMain: "#0288d1",
    error: "#F46B6B",
    grayLight: "#C6C6C8",
    gainsboro: "#DADADA",
  },
  background: {
    focused: "#00358c14",
    blue: "#1976d2",
    gray: "#EDEFF3",
  },
  legends: {
    orange: "#ED6C021A",
    lightGreen: "#8DD4C8",
    green: "#14B8A6",
    violet: "#C4B5FD",
    red: "#FCA5A5",
    blue: "#6D9ADC",
    gray: "#d2dcff",
  },
  charts: {
    red: "#FFB1B1",
    red200: "#FECACA",
    blue: "#93C5FD",
    pink: "#F9A8D4",
    orange: "#FDBA74",
  },
};
