import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledCard, StyledTitle } from "./folder-card.styles";
import { FC } from "react";
import { FolderCardProps } from "./folder-card.types";

export const FolderCard: FC<FolderCardProps> = ({data}) => {
  const { t } = useTranslation("xml");
  const theme = useTheme()

  return (
    <StyledCard to={`/administration/xml/${data.folderEntity.id}`}>
      <Tooltip 
        title={data.folderEntity.title.length > 55 ? <Box sx={{overflowY: 'auto', height: '100%'}}>{data.folderEntity.title}</Box> : undefined}
        arrow
        componentsProps={{
          tooltip: {
            style: {
              backgroundColor: theme.palette.text.secondary
            },
          },
          arrow: {
            style: {
              color: theme.palette.text.secondary
            },
          },
        }}>
        <StyledTitle 
          variant="body2" 
          mb={1.75}>
          {data.folderEntity.title}
        </StyledTitle>
      </Tooltip>
      <Stack 
        direction="row"
        justifyContent="space-between"
        rowGap={1}
       >
        <Typography sx={{fontSize: 12, color: theme.palette.text.secondary}}>{t('folders.quantity')}</Typography>
        <Typography sx={{fontSize: 12, fontWeight: 500, color: theme.palette.text.secondary}}>{data.templateCount}</Typography>
      </Stack>
    </StyledCard>
  );
};