import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
  StyledCellText
} from "./author-cell.styles";
import {Box, Tooltip, Typography} from "@mui/material";
import dayjs from "dayjs";

type AuthorCellProps = {
  author: {
    firstName: string,
    middleName: string,
    lastName: string
  },
  createdAt: string;
}

export const AuthorCell: FC<ICellRendererParams<unknown, AuthorCellProps>> = ({
  value,
}) => {
  const dayjsDate = dayjs(value?.createdAt);
  const name = `${value?.author.lastName || ''} ${value?.author.firstName ? `${value?.author.firstName[0]}.` : ''} ${value?.author.middleName ? `${value?.author.middleName[0]}.` : ''}`
  return (
    <Box sx={{maxWidth: '100%',textAlign: 'center'}}>
      <Tooltip title={name && name.length > 22 ? name : undefined} arrow>
        <StyledCellText>{name}</StyledCellText>
      </Tooltip>
      <Typography>{dayjsDate.format('DD.MM.YYYY')} в {dayjsDate.format('HH:mm')}</Typography>
    </Box>
  );
};
