import {
  StyledCell,
  StyledUsers,
  StyledUser,
  StyledAvatarWrapper,
  StyledSuperAdminIcon,
  StyledUnbindIcon,
} from "./fio-cell.styles";
import { FioCellProps } from "./fio-cell.types";
import { FC, useMemo } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Avatar, Tooltip } from "@mui/material";
import { getPersonInitials } from "../../../../utils/commonUtils";
import { AutoHiddenTextItems } from "../../../../components/auto-hidden-text-items";
import { ReactComponent as CrownIcon } from "../../../../components/icons/imported-icons/crown.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as UnbindIcon } from "../../../../components/icons/imported-icons/unbind.svg";

export const FioCell: FC<ICellRendererParams<unknown, FioCellProps>> = ({
  value,
}) => {
  const { t } = useTranslation("administration");

  const mainProfile = value?.mainProfile;
  const profiles = value?.profiles || [];
  const mainProfileInitials = getPersonInitials({
    firstName: mainProfile?.firstName,
    middleName: mainProfile?.middleName || "",
    divider: "",
  });
  const mainProfileFullName =
    profiles.length > 0
      ? `${mainProfile?.lastName || ""} ${mainProfile?.firstName || ""} ${
          mainProfile?.middleName || ""
        }`
      : `${t("user")} ${value?.userID ?? "–"}`;

  const { profilesNames, profilesTooltipNames } = useMemo(() => {
    const filteredProfiles = profiles.filter((profile) => {
      const hasAnyName =
        profile.firstName || profile.middleName || profile.lastName;
      const notEqualMainProfileName =
        mainProfileFullName !==
        `${profile?.lastName || ""} ${profile?.firstName || ""} ${
          profile?.middleName || ""
        }`;

      return hasAnyName && notEqualMainProfileName;
    });

    const filteredProfilesNames = new Set([
      ...filteredProfiles.map(
        (profile) =>
          `${profile?.lastName || ""} ${profile?.firstName || ""} ${
            profile?.middleName || ""
          }`
      ),
    ]);

    const filteredProfilesTooltipNames = new Set([
      ...filteredProfiles.map(
        (profile) =>
          `${profile?.companyName || "–"} - ${profile?.lastName || ""} ${
            profile?.firstName || ""
          } ${profile?.middleName || ""}`
      ),
    ]);

    return {
      profilesNames: Array.from(filteredProfilesNames),

      profilesTooltipNames: Array.from(filteredProfilesTooltipNames),
    };
  }, [profiles, mainProfileFullName]);

  return (
    <StyledCell $hasInitials={!!mainProfileInitials}>
      <StyledAvatarWrapper>
        <Avatar src={mainProfile?.avatarPreview || ""}>
          {mainProfileInitials}
        </Avatar>

        {value?.superAdmin && (
          <Tooltip
            title={t("adminPragmacore")}
            arrow
            placement="top-start"
            componentsProps={{
              tooltip: {
                sx: {
                  marginBottom: "6px!important",
                  background: "#5C6E8C",
                  borderRadius: "6px",
                  fontSize: "12px",
                  fontWeight: 400,
                },
              },
            }}
          >
            <StyledSuperAdminIcon>
              <CrownIcon />
            </StyledSuperAdminIcon>
          </Tooltip>
        )}

        {!profiles.length && (
          <Tooltip
            title={t("notBinded")}
            arrow
            placement="top-start"
            componentsProps={{
              tooltip: {
                sx: {
                  marginBottom: "6px!important",
                  background: "#5C6E8C",
                  borderRadius: "6px",
                  fontSize: "12px",
                  fontWeight: 400,
                },
              },
            }}
          >
            <StyledUnbindIcon>
              <UnbindIcon className="icon" />
            </StyledUnbindIcon>
          </Tooltip>
        )}
      </StyledAvatarWrapper>

      <StyledUsers>
        <StyledUser>{mainProfileFullName}</StyledUser>
        {!!profilesNames.length && (
          <AutoHiddenTextItems
            items={profilesNames}
            tooltipItems={profilesTooltipNames}
          />
        )}
      </StyledUsers>
    </StyledCell>
  );
};
